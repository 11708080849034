import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import HomeIcon from "@material-ui/icons/Home";
import SaveIcon from "@material-ui/icons/Save";
import HelpIcon from "@material-ui/icons/Help";
import SettingsIcon from "@material-ui/icons/Settings";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import React from "react";
import { RaterContext } from "../RaterV3";
import { FirebaseContext } from 'react-ugp-firebase'
import { makeStyles } from "@material-ui/core/styles";
import { latestVersion } from "../../utils/Tools";

const useStyles = makeStyles(theme => ({
    title: {
        flexGrow: 1,
        '&:hover': {
            cursor: 'pointer',
        }
    },
    groupName: {
        flexGrow: 3,
        paddingLeft: 120,
        '&:hover': {
            cursor: 'pointer',
        }
    },
}));


export default function TopBar() {
    const { hasOpportunity, groupName, saveAll, setHelpOpen, setSettingsOpen, setGroupInfoOpen, setReleaseOpen } = React.useContext(RaterContext)
    const { user } = React.useContext(FirebaseContext)
    const classes = useStyles()


    return(
        <AppBar position="fixed" style={{backgroundColor: '#0C2659'}}>

            <Toolbar>
                <Tooltip title={'Go back to portal'}>
                    <IconButton color='inherit' style={{ color: 'white' }} onClick={() => window.location.href = '/portal'}>
                        <HomeIcon />
                    </IconButton>
                </Tooltip>
                {hasOpportunity &&
                    <Tooltip title={'Save Progress'}>
                        <IconButton color={'inherit'} style={{ color: 'white' }} onClick={saveAll}>
                            <SaveIcon />
                        </IconButton>
                    </Tooltip>}
                <Tooltip title={'Help'}>
                    <IconButton color={'inherit'} style={{ color: 'white' }} onClick={() => setHelpOpen(true)}>
                        <HelpIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Settings'}>
                    <IconButton color={'inherit'} style={{ color: 'white' }} onClick={() => setSettingsOpen(true)}>
                        <SettingsIcon />
                    </IconButton>
                </Tooltip>
                <Hidden smDown>
                    <Typography align={'left'} >
                        {user && user.firstName && `${user.firstName} ${user.lastName}`}
                    </Typography>
                </Hidden>

                <Hidden smDown>
                    <Typography align={'center'} className={classes.groupName} onClick={() => setGroupInfoOpen(true)} variant={'h6'}>
                        {groupName !== '' && `Group: ${groupName}`} &nbsp;
                    </Typography>
                </Hidden>
                <Typography align={'right'} className={classes.title} onClick={() => setReleaseOpen(true)}>
                    {latestVersion}
                </Typography>
            </Toolbar>
        </AppBar>)
}

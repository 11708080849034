import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Select from "react-select";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from "@material-ui/core/Divider";
import dateformat from "dateformat";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles(theme => ({
    dialog: {
        padding: theme.spacing(4)
    }
}))


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} timeout={400}/>;
});

const employeeOptions = [
    {label: 'Employee Only (EE)', value: 'EE'},
    {label: 'Employee plus Spouse (ES)', value: 'ES'},
    {label: 'Employee plus Children (EC)', value: 'EC'},
    {label: 'Employee plus Family (EF)', value: 'EF'}
]

const genderOptions = [
    {label: 'Male', value: 'M'},
    {label: 'Female', value: 'F'}
]

const selectStyles = { menu: styles => ({ ...styles, zIndex: "1 !important"}), input: styles => ({...styles }), control: styles => ({...styles, color: 'white'})};


export default function EditEmployee(props) {
    const [name, setName] = useState('')
    const [gender, setGender] = useState({})
    const [coverage, setCoverage] = useState({})
    const [dob, setDob] = useState('')
    const [disabled, setDisabled] = useState(false)
    const classes = useStyles()

    React.useEffect(() => {
        setName(props.employee.name)
        setDob(dateformat(props.employee.dob, 'yyyy-mm-dd'))
        if (props.employee.gender === "Male") {
            setGender({label: "Male", value: "M"})
        }
        else {
            setGender({label: "Female", value: "F"})
        }
        switch (props.employee.coverage) {
            case 'EE':
                setCoverage({label: "Employee Only (EE)", value: "EE"})
                break
            case 'EF':
                setCoverage({label: "Employee Plus Family", value: "EF"})
                break
            case 'EC':
                setCoverage({label: "Employee Plus Children", value: "EC"})
                break
            case 'ES':
                setCoverage({label: "Employee Plus Spouse", value: "ES"})
                break
            default:
                break
        }
    }, [props.employee])

    React.useEffect(() => {
        if (name === '' || dob === '' || gender === '' || coverage === '') {
            setDisabled(true)
        }
        else {
            setDisabled(false)
        }
    }, [name, dob, gender, coverage])


    const saveEmployee = () => {
        let date = dateformat(dob, 'mm/dd/yyyy', true)
        let employee = {name, dob: date, gender: gender.value, coverage: coverage.value}
        props.save(props.employee.tableData.id, employee)
        props.handleClose()
    }

    return (
        <Dialog onClose={props.handleClose} aria-labelledby="simple-dialog-title" open={props.open} TransitionComponent={Transition}>
            <DialogContent>
                <Grid container item xs={12} spacing={2} justify={'center'} alignItems={'flex-start'}>
                    <Grid item xs={12}>
                        <Typography className={classes.title} variant={'h5'}>Update Employee</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField autoFocus value={name} fullWidth variant='outlined' onChange={(e) => setName(e.target.value)} label={'Name'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Select label='Gender' placeholder='Gender' styles={selectStyles} options={genderOptions} onChange={(x) => setGender(x)} value={gender}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Select label='Coverage' placeholder='Coverage' styles={selectStyles} options={employeeOptions} onChange={(x) => setCoverage(x)} value={coverage}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField value={dob} fullWidth type='date' variant='outlined' onChange={(e) => setDob(e.target.value)} helperText={'DOB'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button fullWidth size='large' variant={'outlined'} onClick={saveEmployee} disabled={disabled} style={{backgroundColor: '#0C2659'}} color="primary" aria-label="add">
                            Save changes
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}


import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/InfoRounded";
import CloseIcon from "@material-ui/icons/Close";
import DropdownInput from "../Inputs/DropdownInput";
import { Button } from "@material-ui/core";
import { RaterContext } from "../RaterV3";
import PayloadsModal from "./PayloadsModal";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  link: {
    color: "blue",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));

export default function IHPQuickQuoteModal(props) {
  const { setIHPBrokerFee } = React.useContext(RaterContext);

  const [payloadsOpen, setPayloadsOpen] = React.useState(false);

  const brokerFeeOptions = [
    { label: "Select", deductibleValue: null },
    { label: "0", deductibleValue: 0 },
    { label: "5", deductibleValue: 5 },
    { label: "10", deductibleValue: 10 },
    { label: "15", deductibleValue: 15 },
    { label: "20", deductibleValue: 20 },
    { label: "25", deductibleValue: 25 },
    { label: "30", deductibleValue: 30 },
    { label: "35", deductibleValue: 35 },
    { label: "45", deductibleValue: 45 },
    { label: "50", deductibleValue: 50 },
    { label: "55", deductibleValue: 55 },
    { label: "60", deductibleValue: 60 },
    { label: "65", deductibleValue: 65 },
    { label: "70", deductibleValue: 70 },
    { label: "75", deductibleValue: 75 },
    { label: "80", deductibleValue: 80 },
    { label: "85", deductibleValue: 250 },
    { label: "90", deductibleValue: 90 },
    { label: "95", deductibleValue: 95 },
    { label: "100", deductibleValue: 100 },
  ];

  let brokerFeeValue;

  const handleBrokerFeeChange = (event) => {
    console.log("Setting broker fee to " + event.target.value);
    setIHPBrokerFee(event.target.value);
    props.setBrokerFee(event.target.value);
  };

  //function that registers submit in ConfigureProduct needs to be passed in as a prop and used in the function below
  const submitIHPQuickQuote = () => {
    props.quickQuoteIHP();
  };

  const openInfo = () => {
    setPayloadsOpen(true);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.close}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Tooltip title={"Plan Information"} placement={"top"}>
          <IconButton
            onClick={openInfo}
            color={"#0C2659"}
            style={{ position: "absolute", right: 50, top: 10 }}
          >
            <InfoIcon style={{ color: "#0C2659" }} />
          </IconButton>
        </Tooltip>
        <IconButton
          onClick={props.close}
          color={"#0C2659"}
          style={{ position: "absolute", right: 10, top: 10 }}
        >
          <CloseIcon style={{ backgroundColor: "#0C2659", color: "white" }} />
        </IconButton>
        <DialogTitle style={{ paddingBottom: 0 }}>
          <div style={{ display: "flex", alignContent: "flex-start" }}>
            <div style={{ marginLeft: 5 }}>
              <Typography
                variant={"h5"}
                color={"primary"}
                style={{ fontWeight: "bold" }}
              >
                IHP Quick Quote
              </Typography>
            </div>
          </div>
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          <Typography variant={"body2"}>
            Add description for IHP Quick Quote if wanted.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Divider style={{ marginBottom: 10 }} />
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12}>
              <DropdownInput
                label="Select your broker fee: "
                options={brokerFeeOptions}
                value={brokerFeeValue}
                onChange={handleBrokerFeeChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant={"outlined"}
                style={{ backgroundColor: "#0C2659", color: "#FFFFFF" }}
                color={"primary"}
                onClick={submitIHPQuickQuote}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <PayloadsModal open={payloadsOpen} close={() => setPayloadsOpen(false)} />
    </div>
  );
}

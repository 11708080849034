import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Slide from "react-reveal/Slide";
import Container from "@material-ui/core/Container";
import PropTypes from "prop-types";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
    content: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(2),
    },
}));

export default function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const classes = useStyles()
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3} className={classes.content}><Slide right duration={750}><Container maxWidth={'xl'}>{children}</Container></Slide></Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

/*
* Deductible options are created for the "No Preference" selection within GAP plans.
* This creates an option for every 50 under 2000 and then every 100 between 2000 and 5000.
*
* */
let deOptions = []
for (let i=0;i<5001;i++) {
    if (i%50 === 0 && i<2001) {
        deOptions.push(i)
    }
    else if (i%100 === 0) {
        deOptions.push(i)
    }
}
export default deOptions

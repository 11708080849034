import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import { RaterContext } from "../RaterV3";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SettingsModal() {
  const {
    settingsOpen,
    setSettingsOpen,
    mecBroker,
    setMecBroker,
    ihpBroker,
    setIhpBroker,
    majorBroker,
    setMajorBroker,
    advantageBroker,
    setAdvantageBroker,
    dentalBroker,
    setDentalBroker,
  } = React.useContext(RaterContext);

  return (
    <div>
      <Dialog
        open={settingsOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSettingsOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <IconButton
          onClick={() => setSettingsOpen(false)}
          color={"#0C2659"}
          style={{
            backgroundColor: "#0C2659",
            position: "absolute",
            right: 10,
            top: 10,
          }}
        >
          <CloseIcon style={{ backgroundColor: "#0C2659", color: "white" }} />
        </IconButton>
        <DialogTitle id="alert-dialog-slide-title">My Settings</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12}>
              <Typography variant={"h6"}>
                Broker Commission Defaults ($)
              </Typography>
              <Typography>
                Certain products allow for broker fees to be customized. If you
                would like to enter a customized broker fee for the products
                below, please enter and save the settings. These broker fees
                will be applied during the quoting process.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant={"outlined"}
                fullWidth
                value={mecBroker}
                onChange={(e) => setMecBroker(e.target.value)}
                type={"number"}
                label={"MEC Broker PEPM Commission"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant={"outlined"}
                fullWidth
                value={ihpBroker}
                onChange={(e) => setIhpBroker(e.target.value)}
                type={"number"}
                label={"IHP Broker PEPM Commission"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant={"outlined"}
                fullWidth
                value={majorBroker}
                onChange={(e) => setMajorBroker(e.target.value)}
                type={"number"}
                label={"Level Funded Health Plans Broker Commission"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant={"outlined"}
                fullWidth
                value={advantageBroker}
                onChange={(e) => setAdvantageBroker(e.target.value)}
                type={"number"}
                label={"OptiMed Advantage Broker Commission"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant={"outlined"}
                fullWidth
                value={dentalBroker}
                onChange={(e) => setDentalBroker(e.target.value)}
                type={"number"}
                label={"Level Funded Dental PEPM Commission"}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant={"outlined"}
                style={{ backgroundColor: "#0C2659", color: "#FFFFFF" }}
                color={"primary"}
                onClick={() => setSettingsOpen(false)}
              >
                Save settings
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

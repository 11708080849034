import React from 'react'
import Button from "@material-ui/core/Button";
import { Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';


const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});


const TidFailure = props => {
	return(
		<div>
			<Dialog
				open={props.modal}
				TransitionComponent={Transition}
				keepMounted
				onClose={props.closeModal}
			>
				<DialogTitle>
					Sorry! <CloseIcon style={{color: 'red'}}/>
				</DialogTitle>
				<DialogContent>
					<Typography variant={'h5'}>
						Unable to process your request.
					</Typography>
					<Divider/>
					<Typography variant={'caption'}>
						We are unable to process your request to generate a quote for this company. There appears to be another company with similar information as the company you are trying to get a quote for.
						To process a quote for this company, please call us at 800-482-8770, or email us at onlineratingsupport@optimedhealth.com.
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button className="close-info" variant="outlined" onClick={props.closeModal}>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>

	)
}
export default TidFailure

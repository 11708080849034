/*NOTE:
This is called on almost every number input to not allow for anything to be typed other than numeric digits.
This also makes it so the user cannot hit the down arrow and go into negative numbers due to that causing errors
on the census component.
 */
export const numberTest = evt => {
    let charCode = !evt.charCode ? evt.which : evt.charCode
    if (charCode===107 || charCode===109 ||charCode===69 || charCode===189 ||charCode ==187|| charCode === 190 || charCode === 40) {
        evt.preventDefault()
        return false
    }
}

import React, { useEffect, useState } from "react";
import { makeStyles, MuiThemeProvider, createMuiTheme, useTheme } from "@material-ui/core/styles";
import Census from "./Steps/StepFour/Census";
import _ from "lodash";
import Products from "./Steps/StepFive/Products";
import Results from "./Steps/StepSix/Results";
import { RatingEngineProvider, RatingEngineContext } from "react-rating-engine";
import { FirebaseContext, FirebaseDB, FirebaseAnalytics } from "react-ugp-firebase";
import SuccessModal from "./Modals/SuccessModal";
import ErrorModal from "./Modals/ErrorModal";
import LoadingModal from "./Modals/LoadingModal";
import { getProducts } from "../utils/engine";
import { convertFullCensus } from "../utils/convertCensus";
import HeaderLogo from "./Assets/HeaderLogo";
import SettingsModal from "./Modals/SettingsModal";
import HelpModal from "./Modals/HelpModal";
import ReleaseNotesModal from "./Modals/ReleaseNotesModal";
import Start from "./Steps/StepOne/Start";
import SelectProducts from "./Steps/StepTwo/SelectProducts";
import RFP from "./Steps/StepThree/RFP";
import GroupModal from "./Modals/GroupModal";
import Copyright from "./Assets/Copyright";
import TopBar from "./Assets/TopBar";
import StepperComponent from "./Assets/Stepper";
import PanelContainer from "./Assets/PanelContainer";
import ChangeBrokerModal from "./Modals/ChangeBrokerModal";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
  },
}));

//This context contains any variable that needs to be used in 2 or more components. All context variables are declared as a state below.
export const RaterContext = React.createContext({});

//Primary container component that holds context values that need to be globally accessible.
export default function RaterV3() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0); //This controls the step that the user is on. It goes from 0-5 to get through all the steps.
  const [hasOpportunity, setHasOpportunity] = useState(false);
  const [opportunityId, setOpportunityId] = useState("");
  const [census, setCensus] = useState([]);

  //Gap formatted census that is either controlled though eligbility count useEffect, gapCensus template or the /products API response.
  const [fourTierCensus, setFourTierCensus] = useState({});
  const [gapCensus, setGapCensus] = useState({});
  const [tier, setTier] = useState(4);
  //Eligibility Count. Used for total when real gapCensus is used as well
  const [eligibilityCount, setEligibilityCount] = useState(0);

  const [restrictedProducts, setRestricted] = useState([]);

  const [groupName, setGroupName] = useState("");

  //This is the main state for building a user's products. An object that is either initialized at the start when a user selects an old opportunity or through /products API call.
  const [products, setProducts] = useState({});

  const [zipCode, setZipCode] = useState("30000");

  const [shelterPayloadTracker, setSheltPayload] = useState({});
  const [nationPayloadTracker, setNationPayload] = useState({});
  const [zurichPayloadTracker, setZurPayload] = useState({});
  const [markelPayloadTracker, setMarkelPayload] = useState({});
  const [chubbPayloadTracker, setChubbPayload] = useState({});
  const [IHPPayloadTracker, setIHPPayload] = useState({});

  const [isQQ, setQQ] = useState(false);

  //This is the value that controls the level of census a user must enter.
  const [requiredLevel, setRequiredLevel] = useState(0);

  //This is referring to the State ("AL", "AR", etc.) of the group the user is building quotes for.
  const [state, setState] = useState("");

  //These control modals that show a success or error message accordingly.
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  //Easy global loading component. Uses a modal.
  const [loading, setLoading] = useState(false);

  const [sicCode, setSicCode] = useState(0);
  const [validCensus, setValidCensus] = useState(false);

  const [hasSubmit, setHasSubmit] = useState(false);

  const [groupEffectiveDate, setGroupEffectiveDate] = useState("01/01/2020");
  const [adminFees, setAdminFees] = useState({});
  const [brokerFees, setBrokerFees] = useState({});

  const [allowed, setAllowed] = useState([]);

  const [settingsOpen, setSettingsOpen] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);
  const [groupInfoOpen, setGroupInfoOpen] = useState(false);
  const [releaseOpen, setReleaseOpen] = useState(false);

  const [sendHSAQuote, setSendHSAQuote] = React.useState(true);

  const [sendOptienhance, setSendOptienhance] = React.useState(true);

  const [canabisdialogue, setCanabisDialogue] = React.useState(false);

  const [canabisChecker, setCanabisChecker] = React.useState(false);

  const [brokerChange, setBrokerChange] = useState({});
  const [brokerModal, setBrokerModal] = useState(false);

  // We use the active product to set the Quick Quote button only when GAP or IHP is selected.

  const [activeProduct, setActiveProduct] = useState("");
  const [quickQuoteDeductible, setQuickQuoteDeductible] = useState(0);
  const [quickQuoteCombinedBenefit, setQuickQuoteCombinedBenefit] = useState(500);

  const [ihpBrokerFee, setIHPBrokerFee] = useState(0);

  const [userCRMid, setUserCRMid] = useState("");

  /* 
        Hacky way to protect against resending CFL alert ... oh well ... driving from UI is not correct anyway. It only
        only works for 1 product but could be made to work with many. Hopefully, thats not needed in this version of the UI.
     */
  const [sendAlertFlag, setSendAlertFlag] = React.useState(true);

  React.useEffect(() => {
    setSendHSAQuote(true);
    setSendOptienhance(true);

    /* 
         If opportunity changes, then allow CFL Alert to be sent again. This is not perfect since the user could 
         change and come back but back-end should protect against that.
        */

    setSendAlertFlag(true);
  }, [opportunityId]);

  function handleSendHSA(sendHSAQuote) {
    setSendHSAQuote(sendHSAQuote);
  }

  function handleSendOptienhance(sendOptienhance) {
    setSendOptienhance(sendOptienhance);
  }

  /*NOTE:
    The following 4 states are all controlling the default broker commission in the settings tab.
    These will show up and be overrideable when quoting the related products
     */
  const [mecBroker, setMecBroker] = useState(5);
  const [ihpBroker, setIhpBroker] = useState(10);
  const [majorBroker, setMajorBroker] = useState(35);
  const [advantageBroker, setAdvantageBroker] = useState(6);
  const [dentalBroker, setDentalBroker] = useState(6);

  // broker that is selected in step 3, this is used for broker discounts. If discount is -1 there will be no discount added to rates.
  const [selectedBroker, setSelectedBroker] = useState(undefined);
  const [discounts, setDiscounts] = useState({
    markel: -1,
    zurich: -1,
    chubb: -1,
    shelter: -1,
    nationwide: -1,
  });

  /*NOTE:
    submittedPlans will keep a list of all the quotes that have been sent by the user in order to ensure
    that no duplicate plans are submitted
     */
  const [submittedPlans, setSubmittedPlans] = useState([]);

  const [ref, setRef] = useState(null);

  const [isCensusParsed, setIsCensusParsed] = useState(false);

  //This is the control for step 2, where the user selects the products they want out of all our products. Whenever their selection changes, we adjust the requiredLevel according to what they chose.
  const [allProducts, setAllProducts] = useState(
    _.sortBy(
      [
        { name: "Gap", required: 2, selected: false, type: "gap" },
        {
          name: "Minimum Essential Coverage",
          required: 1,
          selected: false,
          type: "mec",
        },
        {
          name: "Enhanced Minimum Essential Coverage",
          required: 1,
          selected: false,
          type: "emec",
        },
        {
          name: "IHP - Medical Plans",
          required: 1,
          selected: false,
          type: "ihp",
        },
        {
          name: "IHP Agent - Medical Plans",
          required: 1,
          selected: false,
          type: "ihp_agent",
        },
        {
          name: "Limited Medical",
          required: 1,
          selected: false,
          type: "ltdmed",
        },
        {
          name: "Level Funded Health",
          required: 3,
          selected: false,
          type: "major_medical",
        },
        {
          name: "Fixed Indemnity",
          required: 3,
          selected: false,
          type: "everest",
        },
        { name: "Accident", required: 2, selected: false, type: "accident" },
        { name: "Hospital", required: 3, selected: false, type: "hospital" },
        { name: "Vision", required: 2, selected: false, type: "vision" },
        { name: "Dental", required: 1, selected: false, type: "dental" },
        { name: "RX", required: 2, selected: false, type: "rx" },
        { name: "Travelers Insurance", required: 2, selected: false, type: "travelers" },
        { name: "Life", required: 3, selected: false, type: "life" },
        // {name: "Optimed Advantage", required: 2, selected: false, type: 'extras'},
        {
          name: "Long Term Disability",
          required: 3,
          selected: false,
          type: "ltd",
        },
        {
          name: "Short Term Disability",
          required: 3,
          selected: false,
          type: "std",
        },
        {
          name: "Caregiver Family Leave",
          required: 1,
          selected: false,
          type: "cfl",
        },
      ],
      (x) => x.name
    )
  );

  const { restConfig, isUserLoading, storeWebError, user } = React.useContext(FirebaseContext);
  const { fields, walking } = React.useContext(RatingEngineContext);
  const [total, setTotal] = useState(0);

  // new feature to add rx fees, needed for gap plan card data
  const [rxValues, setRxValues] = useState({
    general_teleHealth: true,
    dermatology_teleHealth: true,
    medical_pricing_transparency: true,
    medical_bill_finance_guidance: true,
    lifestyle_rx: false,
    mental_health_teleHealth: false,
    teleHealth_wellness_coaching: false,
    mri_imaging: false,
    medical_bill_negotiation: false,
    electronic_medical_records: false,
    virtual_id_wallet: false,
    insurance_plan_verification: false,
  });
  const [totalSelectionFee, setTotalSelectionFee] = useState(0);

  /*NOTE:
    This function will handle the analytics throughout the app. Used to simplify the amount of imports and syntax needed throughout.
     */
  const log = (name, details) => {
    if (FirebaseAnalytics) FirebaseAnalytics.logEvent(name, { ...details });
  };

  React.useEffect(() => {
    if (error) log("error_notification", { message });
  }, [error]);

  const webError = (description) => {
    storeWebError("RATING", {
      description,
      groupEffectiveDate,
      census,
      gapCensus,
      groupName,
      opportunityId,
      state,
      zipCode,
      sicCode,
      email: user.email || "",
      fields: _.isEmpty(fields) ? "None" : fields,
    });
  };

  const sendAlert = (product) => {
    if (sendAlertFlag) {
      storeWebError(`${product.toUpperCase()}_UNAVAILABLE`, {
        opportunityId,
        groupName,
        groupEffectiveDate,
        sicCode,
        state,
        eligibilityCount,
      }).then(() => setSendAlertFlag(false));
    }
  };

  const productDescriptionMapping = {
    accident: {
      info: "Provides payment for medical expenses due to a nonoccupational accidental injury. Payment is made directly to the employee regardless of other health insurance coverage.",
      underwriting: false,
      moreInfo: false,
    },
    hospital: {
      info: "Provides a per-day benefit for inpatient confinement in a hospital due to a nonoccupational accident or illness. Payment is made directly to the employee regardless of other health insurance coverage.",
      underwriting: false,
      moreInfo: false,
    },
    "short term disability": {
      info: "Provides a temporary source of weekly income when an employee has an accident or illness that prevents them from working.",
      underwriting: false,
      moreInfo: false,
    },
    "long term disability": {
      info: "Provides a temporary source of monthly income when an employee has an accident or illness that prevents them from working.",
      underwriting: false,
      moreInfo: false,
    },
    life: {
      info: "A covered employee receives a lump-sum benefit should he/she sustain a covered accidental loss, or their beneficiary receives benefit at employee's death. If diagnosed with a terminal illness, a portion of the death benefit may be payable during employee's lifetime.",
      underwriting: false,
      moreInfo: false,
    },
    vision: {
      info: "The plan provides coverage for annual exams and helps pay for vision correction lenses: contacts or glasses with their accompanying frames. Access to the VSP® Vision Choice Network is included with all our plans.",
      underwriting: false,
      moreInfo: false,
    },
    dental: {
      info: "Per-person deductible of $50 (waived for preventive services) and $150 family deductible, plan covers 80 percent after deductible for basic services, and 100% for diagnostic and preventive (each in or out of network) up to a yearly maximum of $1,500 per covered person.",
      underwriting: false,
      moreInfo: false,
    },
    gap: {
      info: "Secondary insurance supplemental to major medical that reimburses many employee out-of-pocket expenses. Numerous configurations available with guaranteed issue and options for voluntary contribution, inpatient-only benefits, a prescription drug rider, HSA compatibility, MNSA benefits, and numerous customizable elements to achieve an attractive cost:benefit ratio.",
      underwriting: "https://docs.optimedhealth.com/GAP_Underwriting_Guidelines.pdf",
      moreInfo: false,
    },
    "minimum essential coverage": {
      info: "A self-funded plan covering preventive services with optional reinsurance protection against claim liability. It can be upgraded to add coverage for common medical services (MEC Edge) or expanded with more services plus prescription drug coverage (E-MEC).",
      underwriting: "https://docs.optimedhealth.com/MEC_and_MVP_Underwriting_Guidelines.pdf",
      moreInfo: false,
    },
    "limited medical": {
      info: "A guaranteed issue indemnity health plan available to employees who work a minimum of 15 hours per week.",
      underwriting: "https://docs.optimedhealth.com/Limited_Medical_Plans_Underwriting_Guidelines.pdf",
      moreInfo: false,
    },
    "level funded health": {
      info: "A level-funded plan based upon a partially self-funded platform with stop-loss protection that eliminates excessive, uncertain claim liability. Funds remaining in claims reserve at year's end refunded 100%.",
      underwriting: false,
      moreInfo: false,
    },
    rx: {
      info: "Brand, generic and preferred brand plans that give members access to 100% of chain and 90% of independent pharmacies nationwide. Guaranteed Issue to groups including all active employees and their dependents.",
      underwriting: false,
      moreInfo: false,
    },
    "caregiver family leave": {
      info: "Covers leaves for family caregiving using the FMLA paid family leave rules and definitions for groups with 50 or more employees. For more information, click on the link below.",
      // info:"A voluntary and/or employer paid workplace benefit that provides financial and social protection for employees serving as family caregivers. 2 week EP, 10 week BP. Benefits are based on percentage of salary with different options available.",
      underwriting: false,
      moreInfo: "https://docs.optimedhealth.com/cfl-fact-sheet.pdf",
    },
    // "optimed advantage": {
    //     info: "Non-insurance benefits that provide discounted prices on various health-related services such as MRI and CT scans, dental, vision, chiropractic and more. These discounts are accessed through a mobile app.",
    //     underwriting: false
    //     moreInfo: false
    // }
  };

  const reset = () => {
    window.location.href = "/rating";
  };

  useEffect(() => {
    setRef(React.createRef());
  }, []);

  //Passed into setConfiguration for react-rating-engine so that things fail gracefully
  const UIResponseInterpreter = () => {
    const getHeaders = () => restConfig.headers;

    const onNoRecoveryError = () => {
      setMessage(
        "An error has occurred on your end. Please try again later. If the problem persists, contact support "
      );
      setError(true);
      webError("onNoRecoveryError inside of the UIResponseInterpreter");
    };

    const onTimeout = () => {
      setMessage(
        "The request to the server has timed out. Please try again later. If the problem persists, contact support"
      );
      if (_.isUndefined(fields)) {
        setError(true);
      }
      setError(true);

      webError("onTimeout inside of the UIResponseInterpreter");
    };

    const onBusy = () => {
      setMessage("The server is currently busy. Please try again. If the problem persists, contact support");
      setError(true);
      webError("onBusy inside of the UIResponseInterpreter");
    };

    const onSheetValidationError = (msg) => {
      setMessage(
        "Underwriting guidelines require you to have additional assistance to quote this product. Please contact support to get a manual quote!"
      );
      setError(true);
      webError({
        text: "onSheetValidation inside of the UIResponseInterpreter",
        msg,
      });
    };

    const onUnrecoverableServerFailure = () => {
      setMessage("Something went wrong. Please try again later. If the problem persists, contact support");
      setError(true);
      webError("onUnrecoverableServerFailure inside of the UIResponseInterpreter");
    };

    const onUnexpectedErrorResponse = (status, data) => {
      console.log(status, data);
      if (data.error) {
        setMessage(data.error);
      } else {
        setMessage(`Unexpected error occurred. Please try again later. If the problem persists, contact support`);
      }
      setError(true);
      webError("onUnexpectedErrorResponse inside of the UIResponseInterpreter");
    };

    return {
      getHeaders,
      onNoRecoveryError,
      onTimeout,
      onBusy,
      onSheetValidationError,
      onUnrecoverableServerFailure,
      onUnexpectedErrorResponse,
    };
  };

  /*NOTE:
    This useEffect keeps track of what products from the list of allProducts the user is allowed to quote based on the level of census that they have provided versus the required level of each product in the list.
     */
  React.useEffect(() => {
    let arr = _.filter(allProducts, (x) => {
      return x.required <= requiredLevel;
    }).map((x) => x.type.toLowerCase());
    setAllowed(arr);
  }, [requiredLevel]);

  /*NOTE:
    We are creating a custom theme to inject into the Material-UI theme provider so that we can override the default tooltip styles throughout the entire app.
     */
  const tooltipTheme = createMuiTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "1em",
          color: "white",
        },
      },
    },
    palette: {
      primary: {
        main: "#0C2659",
      },
      error: {
        main: "#FF0000",
      },
    },
  });

  //All tabs inside of header.
  const tabList = [
    {
      name: "Get Started",
      component: <Start />,
      subtext: "Select an option below to get started quoting OptiMed Products",
    },
    {
      name: "Select Products",
      component: <SelectProducts />,
      subtext: "Select all of the products that you want to quote.",
    },
    {
      name: "Create a new group",
      component: <RFP />,
      subtext:
        "In order to quote a new group, we need some information. Please fill out all required fields in order to continue.",
    },
    {
      name: "Add your census",
      component: <Census />,
      subtext: "Fill out your group census information in order to get an accurate quote for your group.",
    },
    {
      name: "Build and compare your Products",
      component: <Products />,
      subtext:
        "Thank you for submitting the group information and Census! You can now quote the product (s) you initially selected, as well as the other available products listed below. Click on your selected Product to start quoting.",
    },
    {
      name: "Finalize your quotes",
      component: <Results />,
      subtext: "Review your plans and submit them to receive an email with a proposal for each quote.",
    },
  ];

  //useEffect that ensures census is valid anytime that census changes in order to maintain the proper format.
  React.useEffect(() => {
    if (requiredLevel === 3) {
      validateCensus(census.data, census.columns)
        .then((res) => {
          setValidCensus(true);
        })
        .catch((err) => {
          console.log(err);
          if (validCensus === true) {
            setError(true);
            setMessage("Census in improper format, this must be resolved to get custom products");
          }
          setValidCensus(false);
        });
    }
  }, [census]);

  //Anytime someone changes pages they are scrolled back to the top of the screen to avoid confusion.
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [value]);

  //Function that is passed to the context that scrolls the page to the bottom in a smooth manner. This is used when a user saves a brand new plan.
  const scrollToBottom = () => {
    window.scrollTo({ top: 1000, left: 0, behavior: "smooth" });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  //Saves all 3 types of census to Firebase.
  const saveCensus = () => {
    if (opportunityId !== "") {
      setLoading(true);
      FirebaseDB.collection("opportunities")
        .doc(opportunityId)
        .set(
          {
            census,
            gapCensus,
            fourTierCensus,
          },
          { merge: true }
        )
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setError(true);
          setMessage(
            "Something went wrong when trying to save your census. Please try again. If the problem persists, please contact support"
          );
          setLoading(false);
          webError({
            err: err && err.response && err.response.data ? err.response.data : "",
            text: 'An error occurred when trying to run the saveCensus function"',
          });
        });
    }
  };

  //Saves all important info about the users current opportunity. This is what is called when a user hits the 'save' button in the upper left corner.
  const saveAll = () => {
    if (!loading && !walking && !isUserLoading && hasOpportunity && opportunityId !== "") {
      setLoading(true);
      FirebaseDB.collection("opportunities")
        .doc(opportunityId)
        .update({
          products: products ? products : {},
          census,
          gapCensus,
          fourTierCensus,
          allProducts,
          requiredLevel,
          adminFees: adminFees ? adminFees : {},
        })
        .then((res) => {
          setSuccess(true);
          setMessage(`${groupName} progress has saved successfully!`);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setError(true);
          setMessage(
            "Something went wrong when trying to save. Please wait a few minutes and then try again. If the problem persists, contact support."
          );
          setLoading(false);
          webError({
            err: err.response ? err.response.data : "",
            text: 'An error occurred when trying to run the saveAll function"',
          });
        });
    } else {
      setError(true);
      setMessage(
        "Unable to save products. You may not have an opportunity. Make sure you have an opportunity to save and then try again."
      );
    }
  };

  /*NOTE:
    Anytime that the full census (lined census with all details) changes then we run that census through a function that parses it into 4 tier formatted census to be used when quoting certain products.
     */
  useEffect(() => {
    convertFullCensus(census.data)
      .then((res) => {
        setGapCensus(res);
        setFourTierCensus(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [census]);

  //Saves the products object to firebase and
  const saveProducts = () => {
    if (!loading && !walking && !isUserLoading) {
      FirebaseDB.collection("opportunities")
        .doc(opportunityId)
        .update({
          products,
        })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
          setError(true);
          setMessage(
            "Unable to save your products to the database. Please try again later, if the problem persists, contact support."
          );
        });
    }
  };

  //Gets all available products given the current configuration of the opportunity
  const getMyProducts = () => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      let data = {
        censusData: requiredLevel === 3 ? census.data : gapCensus,
        groupEffectiveDate,
        zipCode,
        sicCode,
        state,
      };
      if (restConfig) {
        getProducts(data, restConfig.headers)
          .then((res) => {
            if (res.data && res.data.products) {
              if (Object.keys(products).length > 0) {
                Object.keys(products).length === Object.keys(res.data.products).length
                  ? console.log("Same Product Availability")
                  : setProducts(res.data.products);
              } else setProducts(res.data.products);

              //Creating an object with product names as keys and empty nested objects.
              let obj = {};
              Object.keys(res.data.products).forEach((key) => {
                obj[key] = {};
              });
              if (res.data.products.gap) {
                setFourTierCensus(res.data.products.gap.censusData);
                if (gapCensus === {}) setGapCensus(res.data.products.gap.censusData);
              }
              resolve(res);
            }
            setLoading(false);
            saveCensus();
          })

          .catch((err) => {
            console.log(err);
            console.log(err.response);
            setError(true);
            setMessage(
              err.response
                ? err.response.data
                : "Cannot currently get products. If the problem persists, contact support"
            );
            setLoading(false);
            reject(err);
            webError({
              err: err.response ? err.response.data : "",
              text: 'An error occurred when trying to get all products from the /products endpoint."',
            });
          });
      }
    });
  };

  //Used to handle errors that come back from the Rating Engine
  const uiDelegate = UIResponseInterpreter();

  //Validation to ensure all fields passed are in the proper format required by the /products API.
  const validateCensus = (census, columns) => {
    return new Promise((resolve, reject) => {
      console.log("Validating census");
      console.log(census);
      console.log(columns);

      if (!Array.isArray(census) || census.length === 0) {
        reject(
          "It looks like the file provided is either in the wrong format or empty. Please follow the format given in the census template provided. If the problem persists, please contact support."
        );
        return;
      }

      if (!Array.isArray(columns) || columns.length === 0) {
        // If columns are not provided, try to extract them from the first row of the census
        columns = Object.keys(census[0]).map((key) => key.toLowerCase());
      }

      const requiredFields = ["name", "dob", "gender", "coverage"];
      const missingFields = requiredFields.filter((field) => !columns.includes(field.toLowerCase()));
      const extraFields = columns.filter((field) => !requiredFields.includes(field.toLowerCase()));

      if (missingFields.length > 0) {
        reject(`The following required fields are missing from your census: ${missingFields.join(", ")}`);
      } else if (extraFields.length > 0) {
        reject(`The following extra fields are not allowed in your census: ${extraFields.join(", ")}`);
      } else {
        const errors = [];
        census.forEach((row, index) => {
          // Sanitize inputs
          const name = (row.name || "").trim();
          const dob = (row.dob || "").trim();
          const gender = (row.gender || "").trim().toLowerCase();
          const coverage = (row.coverage || "").trim().toLowerCase();

          // Validate name
          if (name === "" || !/^[a-zA-Z\s-']+$/.test(name)) {
            errors.push(`Row ${index + 1}: Invalid name. Only letters, spaces, hyphens, and apostrophes are allowed.`);
          }

          // Validate DOB
          const dobDate = new Date(dob);
          const today = new Date();
          const age = Math.floor((today - dobDate) / (365.25 * 24 * 60 * 60 * 1000));
          if (isNaN(dobDate.getTime()) || age < 0 || age > 100) {
            errors.push(`Row ${index + 1}: Invalid date of birth. Age must be between 0 and 100.`);
          }

          // Validate gender
          if (gender !== "m" && gender !== "f") {
            errors.push(`Row ${index + 1}: Invalid gender. Must be 'M' or 'F'.`);
          }

          // Validate coverage
          const validCoverages = ["ee", "ef", "ec", "es"];
          if (!validCoverages.includes(coverage)) {
            errors.push(`Row ${index + 1}: Invalid coverage. Must be EE, EC, EF, or ES.`);
          }
        });

        if (errors.length > 0) {
          reject(`The following errors were found in your census:\n${errors.join("\n")}`);
        } else {
          setIsCensusParsed(true);
          resolve("Valid");
        }
      }
    });
  };

  //Steps array that determines the title and order of all 6 steps.
  const steps = [
    "Get Started",
    "Pick Your Products",
    "Enter Group Info",
    "Add Your Census",
    "Customize Your Products",
    "Review And Submit Quotes",
  ];

  /*NOTE:
    The following 4 useEffects utilize local storage in order to update broker fees locally for a user.
    If a user remains on the same browser but if they change machines or clear their data their broker fees
    will all be reset to the original defaults.
     */

  useEffect(() => {
    let adv = JSON.parse(localStorage.getItem("advantageBroker"));
    let mec = JSON.parse(localStorage.getItem("mecBroker"));
    // let ihp = JSON.parse(localStorage.getItem("ihpBroker"));
    let major = JSON.parse(localStorage.getItem("majorBroker"));
    if (adv) setAdvantageBroker(+adv);
    if (mec) setMecBroker(+mec);
    // // if (ihp) setIhpBroker(+ihp);
    if (major) setMajorBroker(+major);
  }, []);

  useEffect(() => {
    localStorage.setItem("advantageBroker", JSON.stringify(advantageBroker));
  }, [advantageBroker]);

  useEffect(() => {
    localStorage.setItem("mecBroker", JSON.stringify(mecBroker));
  }, [mecBroker]);

  useEffect(() => {
    localStorage.setItem("ihpBroker", JSON.stringify(ihpBroker));
  }, [ihpBroker]);

  useEffect(() => {
    localStorage.setItem("majorBroker", JSON.stringify(majorBroker));
  }, [majorBroker]);

  //All the values that are passed in the context and as a result become globally available through useContext hook.
  let values = {
    value,
    setValue,
    hasOpportunity,
    setHasOpportunity,
    opportunityId,
    setOpportunityId,
    census,
    setCensus,
    groupName,
    setGroupName,
    products,
    setProducts,
    zipCode,
    setZipCode,
    message,
    setMessage,
    success,
    setSuccess,
    error,
    setError,
    loading,
    setLoading,
    uiDelegate,
    gapCensus,
    setGapCensus,
    sicCode,
    setSicCode,
    groupEffectiveDate,
    setGroupEffectiveDate,
    state,
    setState,
    getMyProducts,
    validateCensus,
    saveProducts,
    settingsOpen,
    setSettingsOpen,
    helpOpen,
    setHelpOpen,
    total,
    setTotal,
    allProducts,
    setAllProducts,
    requiredLevel,
    setRequiredLevel,
    adminFees,
    setAdminFees,
    brokerFees,
    setBrokerFees,
    allowed,
    mecBroker,
    setMecBroker,
    ihpBroker,
    setIhpBroker,
    advantageBroker,
    setAdvantageBroker,
    majorBroker,
    setMajorBroker,
    eligibilityCount,
    setEligibilityCount,
    productDescriptionMapping,
    reset,
    fourTierCensus,
    setFourTierCensus,
    tier,
    setTier,
    ref,
    scrollToBottom,
    saveCensus,
    setGroupInfoOpen,
    saveAll,
    scrollToTop,
    hasSubmit,
    setHasSubmit,
    webError,
    isCensusParsed,
    dentalBroker,
    setDentalBroker,
    steps,
    groupInfoOpen,
    tabList,
    restrictedProducts,
    setRestricted,
    log,
    submittedPlans,
    setSubmittedPlans,
    sendHSAQuote,
    handleSendHSA,
    releaseOpen,
    setReleaseOpen,
    brokerChange,
    setBrokerChange,
    brokerModal,
    setBrokerModal,
    sendAlert,
    quickQuoteDeductible,
    setQuickQuoteDeductible,
    quickQuoteCombinedBenefit,
    setQuickQuoteCombinedBenefit,
    rxValues,
    setRxValues,
    totalSelectionFee,
    setTotalSelectionFee,
    selectedBroker,
    setSelectedBroker,
    discounts,
    setDiscounts,
    activeProduct,
    setActiveProduct,
    ihpBrokerFee,
    setIHPBrokerFee,
    shelterPayloadTracker,
    setSheltPayload,
    nationPayloadTracker,
    setNationPayload,
    zurichPayloadTracker,
    setZurPayload,
    markelPayloadTracker,
    setMarkelPayload,
    chubbPayloadTracker,
    setChubbPayload,
    IHPPayloadTracker,
    setIHPPayload,
    isQQ,
    setQQ,
    userCRMid,
    setUserCRMid,
    setSendOptienhance,
    sendOptienhance,
    handleSendOptienhance,
    canabisdialogue,
    setCanabisDialogue,
    canabisChecker,
    setCanabisChecker,
  };

  return (
    <RatingEngineProvider>
      <MuiThemeProvider theme={tooltipTheme}>
        <RaterContext.Provider value={values}>
          <div className={classes.root} ref={ref}>
            <TopBar />
            <PanelContainer />
            <StepperComponent />
            <SuccessModal />
            <ErrorModal />
            <ChangeBrokerModal />
            <LoadingModal />
            <HeaderLogo />
            <SettingsModal />
            <GroupModal />
            <HelpModal />
            <ReleaseNotesModal />
            <Copyright />
          </div>
        </RaterContext.Provider>
      </MuiThemeProvider>
    </RatingEngineProvider>
  );
}

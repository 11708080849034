import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Divider from "@material-ui/core/Divider";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
    link: {
        color: 'blue',
        '&:hover' : {
            textDecoration: 'underline',
            cursor: 'pointer',

        }
    }
}))

export default function WarningModal(props) {
    const classes = useStyles()
    return (
        <div>
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={props.close}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <IconButton onClick={props.close} color={'#0C2659'} style={{position: 'absolute', right: 10, top: 10}}>
                    <CloseIcon style={{backgroundColor: '#0C2659', color: 'white'}}/>
                </IconButton>
                <DialogTitle id="alert-dialog-slide-title">Warning</DialogTitle>
                <DialogContent>
                    <Divider style={{marginBottom: 10}}/>
                    <DialogContentText id="alert-dialog-slide-description">
                    Entering in 2/3 Tier census data will only affect GAP rates. All other products will return 4 tier rates regardless of the tier selected.
                    </DialogContentText>
                    <Divider style={{marginTop: 10}}/>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </div>
    );
}

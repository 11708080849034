import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {RaterContext} from "../RaterV3";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles(theme => ({
    link: {
        color: 'blue',
        '&:hover' : {
            textDecoration: 'underline',
            cursor: 'pointer',

        }
    }
}))

export default function ErrorModal() {
    const classes = useStyles()
    const {error, setError, message, setHelpOpen} = React.useContext(RaterContext)
    return (
        <div>
            <Dialog
                open={error}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setError(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <IconButton onClick={() => setError(false)} color={'#0c2659'} style={{position: 'absolute', right: 10, top: 10}}>
                    <CloseIcon style={{backgroundColor: '#0C2659', color: 'white'}}/>
                </IconButton>
                <DialogTitle id="alert-dialog-slide-title">
                        Error
                </DialogTitle>
                <DialogContent>
                    <Divider style={{marginBottom: 10}}/>
                    <DialogContentText id="alert-dialog-slide-description">
                        {message}
                    </DialogContentText>
                    <Divider style={{marginTop: 10}}/>
                    <Typography className={classes.link} align={'center'} onClick={() => setHelpOpen(true)}>Support</Typography>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </div>
    );
}

import React, { useState } from "react";
import _ from "lodash";
import Select, { createFilter } from "react-select";
import { REConstants } from "react-rating-engine";

const selectStyles = {
  control: (styles) => ({
    ...styles,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  option: (styles) => {
    return {
      textAlign: "left",
      ...styles,
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles) => ({ ...styles }),
};

const SelectInput = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [internalValue, setInternalValue] = useState(undefined);
  const [styles, setStyles] = useState(selectStyles);
  const [styled, setStyled] = useState(false);

  const mecRxOptions = [
    "None",
    "OptiEnhance Rx",
    "Broadreach Generic",
    "Broadreach Generic & Brand",
  ];

  const ltdmedRxOptions = [
    "OptiEnhance Rx",
    "Broadreach Generic",
    "Broadreach Generic & Brand",
  ];

  const createOptions = () => {
    const { type, options } = props.options;
    switch (type) {
      case REConstants.OptionTypes.IndexedArray:
        return _.map(options, (obj) => {
          return { value: obj.index, label: obj.desc };
        });

      case REConstants.OptionTypes.Array:
        if (!_.isUndefined(props.type) && props.type === "Percent") {
          return _.map(options, (singleValue) => {
            return {
              value: singleValue,
              label: `${(singleValue * 100).toFixed(0)}%`,
            };
          });
        }
        if (props.name === "valueAddedProducts") {
          return _.map(mecRxOptions, (singleValue) => {
            return {
              value: singleValue,
              label: _.upperFirst(singleValue.toString()),
            };
          });
        }
        if (props.name === "rx" && props.provider === "zurich_ltdmed") {
          return _.map(ltdmedRxOptions, (singleValue) => {
            return {
              value: singleValue,
              label: _.upperFirst(singleValue.toString()),
            };
          });
        }
        return _.map(options, (singleValue) => {
          return {
            value: singleValue,
            label: _.upperFirst(singleValue.toString()),
          };
        });

      default:
        throw new Error(`Unsupported option type: [${props.name}] - [${type}]`);
    }
  };

  const preppedOptions = createOptions();

  const onChangeHandler = (obj) => {
    setInternalValue(obj.value);
    props.onChange(obj.value);
  };

  const testValue = _.isUndefined(props.value) ? internalValue : props.value;

  let preppedProps = props;

  if (!_.isUndefined(props.type) && props.type === "Percent") {
  }
  if (!_.isUndefined(preppedOptions) && preppedOptions.length > 1000) {
    if (!styled) {
      setStyles({
        control: (styles) => ({
          ...styles,
        }),
        option: (styles) => {
          return {
            textAlign: "left",
            ...styles,
          };
        },
        menu: (styles) => ({ zIndex: "10", ...styles }),
        input: (styles) => ({ ...styles }),
        placeholder: (styles) => ({ ...styles }),
        singleValue: (styles) => ({ ...styles }),
        dropdownIndicator: (styles) => {
          return {
            display: "none",
          };
        },
        indicatorSeparator: (styles) => {
          return {
            display: "none",
          };
        },
      });
      setStyled(true);
    }
    preppedProps = {
      ...props,
      openMenuOnClick: true,
      onInputChange: (value, action) => setMenuOpen(value.trim().length >= 1),
      menuIsOpen: menuOpen,
    };
  }
  const filterConfig = {
    matchFrom: "start",
  };
  return (
    <Select
      styles={styles}
      menuPortalTarget={document.body}
      menuPosition={"fixed"}
      maxMenuHeight={150}
      options={preppedOptions}
      onChange={onChangeHandler}
      filterOption={createFilter(filterConfig)}
      isDisabled={props.disabled}
      placeholder={
        props.options.length > 1000 ? "Search SIC code..." : "Select..."
      }
      {...{
        ..._.omit(preppedProps, [
          "options",
          "onChange",
          "disable",
          "isLast",
          "isWalking",
        ]),
        ...{
          value: _.find(preppedOptions, (obj) => obj.value === testValue) || "",
        },
      }}
    />
  );
};
export default SelectInput;

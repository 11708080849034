import React from 'react'
import Container from "@material-ui/core/Container";
import Logo from '../../images/OptiRater.png'



export default function HeaderLogo(props) {

    const logoStyle = { position: 'fixed', bottom: 0, left: 0, width: 200, opacity: .55, zIndex: -1 }

    return (
        <Container maxWidth="md">
            <Container component="main" maxWidth="xs">
                <img src={Logo} alt="OptiMed" style={logoStyle}></img>
            </Container>
        </Container>
    )
}

import {Grid, Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import React from "react";





export default function Hospital(props) {
    return (

        <>
            <Grid item xs={6}>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'right'}>
                    18-54
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'right'}>
                    55-69
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={6}>
                <Typography>Insured Only</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'right'} style={{color:'#0C2659'}}variant={'body2'}>
                    ${(props.data['18-54'].insuredOnly + props.fees).toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'right'} style={{color:'#0C2659'}}variant={'body2'}>
                    ${(props.data['55-69'].insuredOnly + props.fees).toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={6}>
                <Typography>Insured Plus Children</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'right'} style={{color:'#0C2659'}} variant={'body2'}>
                    ${(props.data['18-54'].insuredPlusChildren + props.fees).toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'right'} style={{color:'#0C2659'}} variant={'body2'}>
                    ${(props.data['55-69'].insuredPlusChildren + props.fees).toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={6}>
                <Typography>Insured Plus Spouse</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'right'} style={{color:'#0C2659'}} variant={'body2'}>
                    ${(props.data['18-54'].insuredPlusSpouse + props.fees).toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'right'} style={{color:'#0C2659'}} variant={'body2'}>
                    ${(props.data['55-69'].insuredPlusSpouse + props.fees).toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={6}>
                <Typography>Insured Plus Family</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'right'} style={{color:'#0C2659'}} variant={'body2'}>
                    ${(props.data['18-54'].insuredPlusFamily + props.fees).toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'right'} style={{color:'#0C2659'}} variant={'body2'}>
                    ${(props.data['55-69'].insuredPlusFamily + props.fees).toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>

        </>


    )

}

import React from 'react'
import Button from "@material-ui/core/Button";
import { Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';


const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const ValidateTaxId = props => {

	//Called during the onKeyDown event for number fields to disallow the characters 'e', '-' and '+'
	const numberTest = (evt) => {
		var charCode = !evt.charCode ? evt.which : evt.charCode
		if (charCode===107 || charCode===109 ||charCode===69) {
			evt.preventDefault()
			return false
		}
	}
	return(
		<div>
			<Dialog
				open={props.modal}
				TransitionComponent={Transition}
				keepMounted
				onClose={props.closeModal}
			>
				<DialogContent>
					<Typography variant={'h5'}>
						We need more information
					</Typography>
					<Divider/>
					<Typography variant={'caption'}>
						We found an existing company with a similar name and address. In order to proceed we need additional verification that the company you are requesting a quote for is not in our system.
						Please enter the tax ID number of the company you are trying to get a quote for.
					</Typography>

					<TextField
						label='Employer Identification Number (EIN/TIN)'
						required
						fullWidth
						type={'number'}
						value={props.tid}
						onKeyDown={numberTest}
						onChange={(e) => props.setTid(e.target.value)}
					/>
				</DialogContent>
				<DialogActions style={{justifyContent: 'space-between'}}>
					<Button className="close-info" variant="outlined" onClick={props.closeModal}>
						Close
					</Button>
					{props.loading && <CircularProgress/>}
					<Button disabled={props.tid.length !== 9} className="close-info" variant="contained" color={'primary'} style={{backgroundColor: '#0C2659'}} onClick={props.handleSubmit}>
						Submit
					</Button>
				</DialogActions>
			</Dialog>
		</div>

	)
}
export default ValidateTaxId

import {Grid, Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import React from "react";

export default function SmallMajorMedical(props) {

    return (
        <>
            <Grid item xs={3}>
                <Typography align={'left'}  variant={'body2'}>
                    Claims Funding
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'center'}  variant={'body2'}>
                    Agg Premium
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'center'} variant={'body2'}>
                    Admin Fee
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'center'} variant={'body2'}>
                    Total Cost
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            {props.data.aggPremium.map((person,index) => {
                return(
                    <React.Fragment key={index}>
                        <Grid item xs={12}>
                            <Typography>
                                {person.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography color={'primary'} variant={'body2'} align={'left'}>
                                ${props.data.claimsFunding[index].rate.toFixed(2)}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography color={'primary'} variant={'body2'} align={'center'}>
                                ${person.rate.toFixed(2)}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography color={'primary'} variant={'body2'} align={'center'}>
                            ${(props.fees + props.data.miscFees[index].rate).toFixed(2)}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography color={'primary'} variant={'body2'} align={'center'}>
                                ${(props.data.claimsFunding[index].rate + person.rate + props.fees +  props.data.miscFees[index].rate).toFixed(2)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                    </React.Fragment>)
            })}
        </>
    )
}

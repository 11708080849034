import React from "react";
import _ from "lodash";
import PercentFreeInput from "./PercentFreeInput";
import LongFreeInput from "./LongFreeInput";
import SelectInput from "./SelectInput";
import { REConstants } from "react-rating-engine";
import { PinDropSharp } from "@material-ui/icons";

const makeControl = (field, walking, provider) => {
  const basicProps = {
    name: field.name,
    help: field.help,
    value: _.isUndefined(field.value) ? "" : field.value,
    onChange: field.onValueChange,
    onInvalid: field.onInvalid,
    disabled: walking || !field.isEnabled,
    isLast: field.isLast,
    type: field.type,
    isWalking: walking,
    isError: field.isError,
  };

  if (field.isEnabled) {
    if (_.isUndefined(field.options)) {
      switch (field.type) {
        case REConstants.APIKeys.Percent:
          return <PercentFreeInput {...basicProps} />;

        case REConstants.APIKeys.Long:
          return <LongFreeInput {...basicProps} />;
        default:
          if (field.isEnabled) {
            throw new Error(`Unsupported free text entry type: [${field.name}] - [${field.type}]`);
          } else {
            /* Just a place holder since its already disabled */
            return <LongFreeInput {...basicProps} />;
          }
      }
    } else {
      return <SelectInput {...basicProps} options={field.options} provider={provider} />;
    }
  } else {
    basicProps.value = undefined;
    return <LongFreeInput {...basicProps} />;
  }
};
export default makeControl;

import { Grid, Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import React from "react";

export default function IHPAgent(props) {
  return (
    <>
      <Grid item xs={3}>
        <Typography variant={"body2"}>Rates</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {props.data.rates.insuredOnly && (
        <>
          <Grid item xs={12}>
            <Typography>Insured Only</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              style={{ color: "#0C2659" }}
              align={"right"}
              variant={"body2"}
            >
              $
              {(
               props.data.admin.insuredOnly +
               props.data.admin2.insuredOnly +
               props.data.brokerCommission2.insuredOnly +
               props.data.compliance.insuredOnly +
               props.data.hba.insuredOnly +
               props.data.medWatch.insuredOnly +
               props.data.pmIHP.insuredOnly +
               props.data.providencePremium.insuredOnly +
               props.data.rates.insuredOnly +
               props.data.tresHealth.insuredOnly +
               props.data.internalSalesCommission.insuredOnly +
               props.fees +
               props.totalSelectionFee
              ).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}
      {props.data.rates.insuredPlusSpouse && (
        <>
          <Grid item xs={12}>
            <Typography>Insured Plus Spouse</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              style={{ color: "#0C2659" }}
              align={"right"}
              variant={"body2"}
            >
              $
              {(
                  props.data.admin.insuredPlusSpouse +
                  props.data.admin2.insuredPlusSpouse +
                  props.data.brokerCommission2.insuredPlusSpouse +
                  props.data.compliance.insuredPlusSpouse +
                  props.data.hba.insuredPlusSpouse +
                  props.data.medWatch.insuredPlusSpouse +
                  props.data.pmIHP.insuredPlusSpouse +
                  props.data.providencePremium.insuredPlusSpouse +
                  props.data.rates.insuredPlusSpouse +
                  props.data.tresHealth.insuredPlusSpouse +
                  props.data.internalSalesCommission.insuredPlusSpouse +
                  props.fees +
                  props.totalSelectionFee
              ).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}
      {props.data.rates.insuredPlusChildren && (
        <>
          <Grid item xs={12}>
            <Typography>Insured Plus Children</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              style={{ color: "#0C2659" }}
              align={"right"}
              variant={"body2"}
            >
              $
              {(
                 props.data.admin.insuredPlusChildren +
                 props.data.admin2.insuredPlusChildren +
                 props.data.brokerCommission2.insuredPlusChildren +
                 props.data.compliance.insuredPlusChildren +
                 props.data.hba.insuredPlusChildren +
                 props.data.medWatch.insuredPlusChildren +
                 props.data.pmIHP.insuredPlusChildren +
                 props.data.providencePremium.insuredPlusChildren +
                 props.data.rates.insuredPlusChildren +
                 props.data.tresHealth.insuredPlusChildren +
                 props.data.internalSalesCommission.insuredPlusChildren +
                 props.fees +
                 props.totalSelectionFee
              ).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}
      {props.data.rates.insuredPlusFamily && (
        <>
          <Grid item xs={12}>
            <Typography>Insured Plus Family</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              style={{ color: "#0C2659" }}
              align={"right"}
              variant={"body2"}
            >
              $
              {(
                props.data.admin.insuredPlusFamily +
                props.data.admin2.insuredPlusFamily +
                props.data.brokerCommission2.insuredPlusFamily +
                props.data.compliance.insuredPlusFamily +
                props.data.hba.insuredPlusFamily +
                props.data.medWatch.insuredPlusFamily +
                props.data.pmIHP.insuredPlusFamily +
                props.data.providencePremium.insuredPlusFamily +
                props.data.rates.insuredPlusFamily +
                props.data.tresHealth.insuredPlusFamily +
                props.data.internalSalesCommission.insuredPlusFamily +
                props.fees +
                props.totalSelectionFee
              ).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}
    </>
  );
}

export const SICs = [
  {
    code: 111,
    desc: "Wheat",
  },
  {
    code: 112,
    desc: "Rice",
  },
  {
    code: 115,
    desc: "Corn",
  },
  {
    code: 116,
    desc: "Soybeans",
  },
  {
    code: 119,
    desc: "Cash Grains, NEC",
  },
  {
    code: 131,
    desc: "Cotton",
  },
  {
    code: 132,
    desc: "Tobacco",
  },
  {
    code: 133,
    desc: "Sugarcane and Sugar Beets",
  },
  {
    code: 134,
    desc: "Irish Potatoes",
  },
  {
    code: 139,
    desc: "Field Crops, Except Cash Grains, NEC",
  },
  {
    code: 161,
    desc: "Vegetables and Melons",
  },
  {
    code: 171,
    desc: "Berry Crops",
  },
  {
    code: 172,
    desc: "Grapes",
  },
  {
    code: 173,
    desc: "Tree Nuts",
  },
  {
    code: 174,
    desc: "Citrus Fruits",
  },
  {
    code: 175,
    desc: "Deciduous Tree Fruits",
  },
  {
    code: 179,
    desc: "Fruits and Tree Nuts, NEC",
  },
  {
    code: 181,
    desc: "Ornamental Floriculture and Nursery Products",
  },
  {
    code: 182,
    desc: "Food Crops Grown Under Cover",
  },
  {
    code: 191,
    desc: "General Farms, Primarily Crop",
  },
  {
    code: 211,
    desc: "Beef Cattle Feedlots",
  },
  {
    code: 212,
    desc: "Beef Cattle, Except Feedlots",
  },
  {
    code: 213,
    desc: "Hogs",
  },
  {
    code: 214,
    desc: "Sheep and Goats",
  },
  {
    code: 219,
    desc: "General Livestock,  Except Dairy and Poultry",
  },
  {
    code: 241,
    desc: "Dairy Farms",
  },
  {
    code: 251,
    desc: "Broiler, Fryers, and Roaster Chickens",
  },
  {
    code: 252,
    desc: "Chicken Eggs",
  },
  {
    code: 253,
    desc: "Turkey and Turkey Eggs",
  },
  {
    code: 254,
    desc: "Poultry Hatcheries",
  },
  {
    code: 259,
    desc: "Poultry and Eggs, NEC",
  },
  {
    code: 271,
    desc: "Fur-Bearing Animals and Rabbits",
  },
  {
    code: 272,
    desc: "Horses and Other Equines",
  },
  {
    code: 273,
    desc: "Animal Aquaculture",
  },
  {
    code: 279,
    desc: "Animal Specialities, NEC",
  },
  {
    code: 291,
    desc: "General Farms, Primarily Livestock and Animal Specialties",
  },
  {
    code: 711,
    desc: "Soil Preparation Services",
  },
  {
    code: 721,
    desc: "Crop Planting, Cultivating and Protecting",
  },
  {
    code: 722,
    desc: "Crop Harvesting, Primarily by Machine",
  },
  {
    code: 723,
    desc: "Crop Preparation Services For Market, except Cotton Ginning",
  },
  {
    code: 724,
    desc: "Cotton Ginning",
  },
  {
    code: 741,
    desc: "Veterinary Service For Livestock",
  },
  {
    code: 742,
    desc: "Veterinary Services for Animal Specialties",
  },
  {
    code: 751,
    desc: "Livestock Services, Except Veterinary",
  },
  {
    code: 752,
    desc: "Animal Specialty Services, Except Veterinary",
  },
  {
    code: 761,
    desc: "Farm Labor Contractors and Crew Leaders",
  },
  {
    code: 762,
    desc: "Farm Management Services",
  },
  {
    code: 781,
    desc: "Landscape Counseling and Planning",
  },
  {
    code: 782,
    desc: "Lawn and Garden Services",
  },
  {
    code: 783,
    desc: "Ornamental Shrub and Tree Services",
  },
  {
    code: 811,
    desc: "Timber Tracts",
  },
  {
    code: 831,
    desc: "Forest Nurseries and Gathering of Forest Products",
  },
  {
    code: 851,
    desc: "Forestry Services",
  },
  {
    code: 912,
    desc: "Finfish",
  },
  {
    code: 913,
    desc: "Shellfish",
  },
  {
    code: 919,
    desc: "Miscellaneous Marine Products",
  },
  {
    code: 921,
    desc: "Fish Hatcheries and Preserves",
  },
  {
    code: 971,
    desc: "Hunting, Trapping, and Game Propagation",
  },
  {
    code: 1011,
    desc: "Iron Ores",
  },
  {
    code: 1021,
    desc: "Copper Ores",
  },
  {
    code: 1031,
    desc: "Lead and Zinc Ores",
  },
  {
    code: 1041,
    desc: "Gold Ores",
  },
  {
    code: 1044,
    desc: "Silver Ores",
  },
  {
    code: 1061,
    desc: "Ferroalloy Ores, Except Vanadium",
  },
  {
    code: 1081,
    desc: "Metal Mining Services",
  },
  {
    code: 1094,
    desc: "Uranium-Radium-Vanadium Ores",
  },
  {
    code: 1099,
    desc: "Miscellaneous Metal Ores, NEC",
  },
  {
    code: 1221,
    desc: "Bituminous Coal and Lignite Surface Mining",
  },
  {
    code: 1222,
    desc: "Bituminous Coal Underground Mining",
  },
  {
    code: 1231,
    desc: "Anthracite Mining",
  },
  {
    code: 1241,
    desc: "Coal Mining Services",
  },
  {
    code: 1311,
    desc: "Crude Petroleum and Natural Gas",
  },
  {
    code: 1321,
    desc: "Natural Gas Liquids",
  },
  {
    code: 1381,
    desc: "Drilling Oil and Gas Wells",
  },
  {
    code: 1382,
    desc: "Oil and Gas Field Exploration Services",
  },
  {
    code: 1389,
    desc: "Oil and Gas Field Services, NEC",
  },
  {
    code: 1411,
    desc: "Dimension Stone",
  },
  {
    code: 1422,
    desc: "Crushed and Broken Limestone",
  },
  {
    code: 1423,
    desc: "Crushed and Broken Granite",
  },
  {
    code: 1429,
    desc: "Crushed and Broken Stone, NEC",
  },
  {
    code: 1442,
    desc: "Construction Sand and Gravel",
  },
  {
    code: 1446,
    desc: "Industrial Sand",
  },
  {
    code: 1455,
    desc: "Kaolin and Ball Clay",
  },
  {
    code: 1459,
    desc: "Clay, Ceramic, and Refractory Minerals, NEC",
  },
  {
    code: 1474,
    desc: "Potash, Soda, and Borate Minerals",
  },
  {
    code: 1475,
    desc: "Phosphate Rock",
  },
  {
    code: 1479,
    desc: "Chemical and Fertilizer Mineral Mining, NEC",
  },
  {
    code: 1481,
    desc: "Nonmetallic Minerals Services Except Fuels",
  },
  {
    code: 1499,
    desc: "Miscellaneous Nonmetallic Minerals, Except Fuels",
  },
  {
    code: 1521,
    desc: "General Contractors-Single-Family Houses",
  },
  {
    code: 1522,
    desc: "General Contractors-Residential Buildings, Other Than Single-Family",
  },
  {
    code: 1531,
    desc: "Operative Builders",
  },
  {
    code: 1541,
    desc: "General Contractors-Industrial Buildings and Warehouses",
  },
  {
    code: 1542,
    desc: "General Contractors-Nonresidential Buildings, Other than Industrial Buildings and Warehouses",
  },
  {
    code: 1611,
    desc: "Highway and Street Construction, Except Elevated Highways",
  },
  {
    code: 1622,
    desc: "Bridge, Tunnel, and Elevated Highway Construction",
  },
  {
    code: 1623,
    desc: "Water, Sewer, Pipeline, and Communications and Power Line Construction",
  },
  {
    code: 1629,
    desc: "Heavy Construction, NEC",
  },
  {
    code: 1711,
    desc: "Plumbing, Heating, and Air-Conditioning",
  },
  {
    code: 1721,
    desc: "Painting and Paper Hanging",
  },
  {
    code: 1731,
    desc: "Electrical Work",
  },
  {
    code: 1741,
    desc: "Masonry, Stone Setting and Other Stone Work",
  },
  {
    code: 1742,
    desc: "Plastering, Drywall, Acoustical and Insulation Work",
  },
  {
    code: 1743,
    desc: "Terrazzo, Tile, Marble, and Mosaic Work",
  },
  {
    code: 1751,
    desc: "Carpentry Work",
  },
  {
    code: 1752,
    desc: "Floor Laying and Other Floor Work, NEC",
  },
  {
    code: 1761,
    desc: "Roofing, Siding, and Sheet Metal Work",
  },
  {
    code: 1771,
    desc: "Concrete Work",
  },
  {
    code: 1781,
    desc: "Water Well Drilling",
  },
  {
    code: 1791,
    desc: "Structural Steel Erection",
  },
  {
    code: 1793,
    desc: "Glass and Glazing Work",
  },
  {
    code: 1794,
    desc: "Excavation Work",
  },
  {
    code: 1795,
    desc: "Wrecking and Demolition Work",
  },
  {
    code: 1796,
    desc: "Installation or Erection of Building Equipment, NEC",
  },
  {
    code: 1799,
    desc: "Special Trade Contractors, NEC",
  },
  {
    code: 2011,
    desc: "Meat Packing Plants",
  },
  {
    code: 2013,
    desc: "Sausages and Other Prepared Meats",
  },
  {
    code: 2015,
    desc: "Poultry Slaughtering and Processing",
  },
  {
    code: 2021,
    desc: "Creamery Butter",
  },
  {
    code: 2022,
    desc: "Natural, Processed, and Imitation Cheese",
  },
  {
    code: 2023,
    desc: "Dry, Condensed, and Evaporated Dairy Products",
  },
  {
    code: 2024,
    desc: "Ice Cream and Frozen Desserts",
  },
  {
    code: 2026,
    desc: "Fluid Milk",
  },
  {
    code: 2032,
    desc: "Canned Specialties",
  },
  {
    code: 2033,
    desc: "Canned Fruits, Vegetables, Preserves, Jams, and Jellies",
  },
  {
    code: 2034,
    desc: "Dried and Dehydrated Fruits, Vegetables, and Soup Mixes",
  },
  {
    code: 2035,
    desc: "Pickled Fruits and Vegetables, Vegetables Sauces and Seasonings, and Salad Dressings",
  },
  {
    code: 2037,
    desc: "Frozen Fruits, Fruit Juices, and Vegetables",
  },
  {
    code: 2038,
    desc: "Frozen Specialties, NEC",
  },
  {
    code: 2041,
    desc: "Flour and Other Grain Mill Products",
  },
  {
    code: 2043,
    desc: "Cereal Breakfast Foods",
  },
  {
    code: 2044,
    desc: "Rice Milling",
  },
  {
    code: 2045,
    desc: "Prepared Flour Mixes and Doughs",
  },
  {
    code: 2046,
    desc: "Wet Corn Milling",
  },
  {
    code: 2047,
    desc: "Dog and Cat Food",
  },
  {
    code: 2048,
    desc: "Prepared Feed and Feed Ingredients for Animals and Fowls, Except Dogs and Cats",
  },
  {
    code: 2051,
    desc: "Bread and Other Bakery Products, Except Cookies and Crackers",
  },
  {
    code: 2052,
    desc: "Cookies and Crackers",
  },
  {
    code: 2053,
    desc: "Frozen Bakery Products, Except Bread",
  },
  {
    code: 2061,
    desc: "Cane Sugar, Except Refining",
  },
  {
    code: 2062,
    desc: "Cane Sugar Refining",
  },
  {
    code: 2063,
    desc: "Beet Sugar",
  },
  {
    code: 2064,
    desc: "Candy and Other Confectionery Products",
  },
  {
    code: 2066,
    desc: "Chocolate and Cocoa Products",
  },
  {
    code: 2067,
    desc: "Chewing Gum",
  },
  {
    code: 2068,
    desc: "Salted and Roasted Nuts and Seeds",
  },
  {
    code: 2074,
    desc: "Cottonseed Oil Mills",
  },
  {
    code: 2075,
    desc: "Soybean Oil Mills",
  },
  {
    code: 2076,
    desc: "Vegetable Oil Mills, Except Corn, Cottonseed, and Soybeans",
  },
  {
    code: 2077,
    desc: "Animal and Marine Fats and Oils",
  },
  {
    code: 2079,
    desc: "Shortening, Table Oils, Margarine, and Other Edible Fats and Oils, NEC",
  },
  {
    code: 2082,
    desc: "Malt Beverages",
  },
  {
    code: 2083,
    desc: "Malt",
  },
  {
    code: 2084,
    desc: "Wines, Brandy, and Brandy Spirits",
  },
  {
    code: 2085,
    desc: "Distilled and Blended Liquors",
  },
  {
    code: 2086,
    desc: "Bottled and Canned Soft Drinks and Carbonated Waters",
  },
  {
    code: 2087,
    desc: "Flavoring Extracts and Flavoring Syrups NEC",
  },
  {
    code: 2091,
    desc: "Canned and Cured Fish and Seafood",
  },
  {
    code: 2092,
    desc: "Prepared Fresh or Frozen Fish and Seafoods",
  },
  {
    code: 2095,
    desc: "Roasted Coffee",
  },
  {
    code: 2096,
    desc: "Potato Chips, Corn Chips, and Similar Snacks",
  },
  {
    code: 2097,
    desc: "Manufactured Ice",
  },
  {
    code: 2098,
    desc: "Macaroni, Spaghetti, Vermicelli, and Noodles",
  },
  {
    code: 2099,
    desc: "Food Preparations, NEC",
  },
  {
    code: 2111,
    desc: "Cigarettes",
  },
  {
    code: 2121,
    desc: "Cigars",
  },
  {
    code: 2131,
    desc: "Chewing and Smoking Tobacco and Snuff",
  },
  {
    code: 2141,
    desc: "Tobacco Stemming and Redrying",
  },
  {
    code: 2211,
    desc: "Broadwoven Fabric Mills, Cotton",
  },
  {
    code: 2221,
    desc: "Broadwoven Fabric Mills, Manmade Fiber and Silk",
  },
  {
    code: 2231,
    desc: "Broadwoven Fabric Mills, Wool (Including Dyeing and Finishing)",
  },
  {
    code: 2241,
    desc: "Narrow  Fabric and Other Smallware Mills:  Cotton, Wool, Silk, and Manmade Fiber",
  },
  {
    code: 2251,
    desc: "Women's Full-Length and Knee-Length Hosiery, Except Socks",
  },
  {
    code: 2252,
    desc: "Hosiery, NEC",
  },
  {
    code: 2253,
    desc: "Knit Outerwear Mills",
  },
  {
    code: 2254,
    desc: "Knit Underwear and Nightwear Mills",
  },
  {
    code: 2257,
    desc: "Weft Knit Fabric Mills",
  },
  {
    code: 2258,
    desc: "Lace and Warp Knit Fabric Mills",
  },
  {
    code: 2259,
    desc: "Knitting Mills, NEC",
  },
  {
    code: 2261,
    desc: "Finishers of Broadwoven Fabrics of Cotton",
  },
  {
    code: 2262,
    desc: "Finishers of Broadwoven Fabrics of Manmade Fiber and Silk",
  },
  {
    code: 2269,
    desc: "Finishers of Textiles, NEC",
  },
  {
    code: 2273,
    desc: "Carpets and Rugs",
  },
  {
    code: 2281,
    desc: "Yarn Spinning Mills",
  },
  {
    code: 2282,
    desc: "Yarn Texturizing, Throwing, Twisting, and Winding Mills",
  },
  {
    code: 2284,
    desc: "Thread Mills",
  },
  {
    code: 2295,
    desc: "Coated Fabrics, Not Rubberized",
  },
  {
    code: 2296,
    desc: "Tire Cord and Fabrics",
  },
  {
    code: 2297,
    desc: "Nonwoven Fabrics",
  },
  {
    code: 2298,
    desc: "Cordage and Twine",
  },
  {
    code: 2299,
    desc: "Textile Goods, NEC",
  },
  {
    code: 2311,
    desc: "Men's and Boys' Suits, Coats and Overcoats",
  },
  {
    code: 2321,
    desc: "Men's and Boys' Shirts, Except Work Shirts",
  },
  {
    code: 2322,
    desc: "Men's and Boys' Underwear and Nightwear",
  },
  {
    code: 2323,
    desc: "Men's and Boys' Neckwear",
  },
  {
    code: 2325,
    desc: "Men's and Boys' Trousers and Slacks",
  },
  {
    code: 2326,
    desc: "Men's and Boys' Work Clothing",
  },
  {
    code: 2329,
    desc: "Men's and Boys' Clothing, NEC",
  },
  {
    code: 2331,
    desc: "Women's, Misses', and Juniors' Blouses and Shirts",
  },
  {
    code: 2335,
    desc: "Women's, Misses' and Junior's Dresses",
  },
  {
    code: 2337,
    desc: "Women's, Misses' and Juniors' Suits, Skirts and Coats",
  },
  {
    code: 2339,
    desc: "Women's, Misses' and Juniors' Outerwear, NEC",
  },
  {
    code: 2341,
    desc: "Women's, Misses, Children's, and Infants' Underwear and Nightwear",
  },
  {
    code: 2342,
    desc: "Brassieres, Girdles, and Allied Garments",
  },
  {
    code: 2353,
    desc: "Hats, Caps, and Millinery",
  },
  {
    code: 2361,
    desc: "Girls', Children's and Infants' Dresses, Blouses and Shirts",
  },
  {
    code: 2369,
    desc: "Girls', Children's and Infants' Outerwear, NEC",
  },
  {
    code: 2371,
    desc: "Fur Goods",
  },
  {
    code: 2381,
    desc: "Dress and Work Gloves, Except Knit and All-Leather",
  },
  {
    code: 2384,
    desc: "Robes and Dressing Gowns",
  },
  {
    code: 2385,
    desc: "Waterproof Outerwear",
  },
  {
    code: 2386,
    desc: "Leather and Sheep-Lined Clothing",
  },
  {
    code: 2387,
    desc: "Apparel Belts",
  },
  {
    code: 2389,
    desc: "Apparel and Accessories, NEC",
  },
  {
    code: 2391,
    desc: "Curtains and Draperies",
  },
  {
    code: 2392,
    desc: "Housefurnishings, Except Curtains and Draperies",
  },
  {
    code: 2393,
    desc: "Textile Bags",
  },
  {
    code: 2394,
    desc: "Canvas and Related Products",
  },
  {
    code: 2395,
    desc: "Pleating, Decorative and Novelty Stitching, and Tucking for the Trade",
  },
  {
    code: 2396,
    desc: "Automotive Trimmings, Apparel Findings, and Related Products",
  },
  {
    code: 2397,
    desc: "Schiffli Machine Embroideries",
  },
  {
    code: 2399,
    desc: "Fabricated Textile Products, NEC",
  },
  {
    code: 2411,
    desc: "Logging",
  },
  {
    code: 2421,
    desc: "Sawmills and Planing Mills, General",
  },
  {
    code: 2426,
    desc: "Hardwood Dimension and Flooring Mills",
  },
  {
    code: 2429,
    desc: "Special Product Sawmills, NEC",
  },
  {
    code: 2431,
    desc: "Millwork",
  },
  {
    code: 2434,
    desc: "Wood Kitchen Cabinets",
  },
  {
    code: 2435,
    desc: "Hardwood Veneer and Plywood",
  },
  {
    code: 2436,
    desc: "Softwood Veneer and Plywood",
  },
  {
    code: 2439,
    desc: "Structural Wood Members, NEC",
  },
  {
    code: 2441,
    desc: "Nailed and Lock Corner Wood Boxes and Shook",
  },
  {
    code: 2448,
    desc: "Wood Pallets and Skids",
  },
  {
    code: 2449,
    desc: "Wood Containers, NEC",
  },
  {
    code: 2451,
    desc: "Mobile Homes",
  },
  {
    code: 2452,
    desc: "Prefabricated Wood Buildings and Components",
  },
  {
    code: 2491,
    desc: "Wood Preserving",
  },
  {
    code: 2493,
    desc: "Reconstituted Wood Products",
  },
  {
    code: 2499,
    desc: "Wood Products, NEC",
  },
  {
    code: 2511,
    desc: "Wood Household Furniture, Except Upholstered",
  },
  {
    code: 2512,
    desc: "Wood Household Furniture, Upholstered",
  },
  {
    code: 2514,
    desc: "Metal Household Furniture",
  },
  {
    code: 2515,
    desc: "Mattresses, Foundations, and Convertible Beds",
  },
  {
    code: 2517,
    desc: "Wood Television, Radio, Phonograph and Sewing Machine Cabinets",
  },
  {
    code: 2519,
    desc: "Household Furniture, NEC",
  },
  {
    code: 2521,
    desc: "Wood Office Furniture",
  },
  {
    code: 2522,
    desc: "Office Furniture, Except Wood",
  },
  {
    code: 2531,
    desc: "Public Building and Related Furniture",
  },
  {
    code: 2541,
    desc: "Wood Office and Store Fixtures, Partitions, Shelving, and Lockers",
  },
  {
    code: 2542,
    desc: "Office and Store Fixtures, Partitions Shelving, and Lockers, Except Wood",
  },
  {
    code: 2591,
    desc: "Drapery Hardware and Window Blinds and Shades",
  },
  {
    code: 2599,
    desc: "Furniture and Fixtures, NEC",
  },
  {
    code: 2611,
    desc: "Pulp Mills",
  },
  {
    code: 2621,
    desc: "Paper Mills",
  },
  {
    code: 2631,
    desc: "Paperboard Mills",
  },
  {
    code: 2652,
    desc: "Setup Paperboard Boxes",
  },
  {
    code: 2653,
    desc: "Corrugated and Solid Fiber Boxes",
  },
  {
    code: 2655,
    desc: "Fiber Cans, Tubes, Drums, and Similar Products",
  },
  {
    code: 2656,
    desc: "Sanitary Food Containers, Except Folding",
  },
  {
    code: 2657,
    desc: "Folding Paperboard Boxes, Including Sanitary",
  },
  {
    code: 2671,
    desc: "Packaging Paper and Plastics Film, Coated and Laminated",
  },
  {
    code: 2672,
    desc: "Coated and Laminated Paper, NEC",
  },
  {
    code: 2673,
    desc: "Plastics, Foil, and Coated Paper Bags",
  },
  {
    code: 2674,
    desc: "Uncoated Paper and Multiwall Bags",
  },
  {
    code: 2675,
    desc: "Die-Cut Paper and Paperboard and Cardboard",
  },
  {
    code: 2676,
    desc: "Sanitary Paper Products",
  },
  {
    code: 2677,
    desc: "Envelopes",
  },
  {
    code: 2678,
    desc: "Stationery, Tablets, and Related Products",
  },
  {
    code: 2679,
    desc: "Converted Paper and Paperboard Products, NEC",
  },
  {
    code: 2711,
    desc: "Newspapers:  Publishing, or Publishing and Printing",
  },
  {
    code: 2721,
    desc: "Periodicals: Publishing, or Publishing and Printing",
  },
  {
    code: 2731,
    desc: "Books: Publishing, or Publishing and Printing",
  },
  {
    code: 2732,
    desc: "Book Printing",
  },
  {
    code: 2741,
    desc: "Miscellaneous Publishing",
  },
  {
    code: 2752,
    desc: "Commercial Printing, Lithographic",
  },
  {
    code: 2754,
    desc: "Commercial Printing, Gravure",
  },
  {
    code: 2759,
    desc: "Commercial Printing, NEC",
  },
  {
    code: 2761,
    desc: "Manifold Business Forms",
  },
  {
    code: 2771,
    desc: "Greeting Cards",
  },
  {
    code: 2782,
    desc: "Blankbooks, Loose-leaf Binders and Devices",
  },
  {
    code: 2789,
    desc: "Bookbinding and Related Work",
  },
  {
    code: 2791,
    desc: "Typesetting",
  },
  {
    code: 2796,
    desc: "Platemaking and Related Services",
  },
  {
    code: 2812,
    desc: "Alkalies and Chlorine",
  },
  {
    code: 2813,
    desc: "Industrial Gases",
  },
  {
    code: 2816,
    desc: "Inorganic Pigments",
  },
  {
    code: 2819,
    desc: "Industrial Inorganic Chemicals, NEC",
  },
  {
    code: 2821,
    desc: "Plastics Material Synthetic Resins, and Nonvulcanizable Elastomers",
  },
  {
    code: 2822,
    desc: "Synthetic Rubber",
  },
  {
    code: 2823,
    desc: "Cellulosic Manmade Fibers",
  },
  {
    code: 2824,
    desc: "Manmade Organic Fibers, Except Cellulosic",
  },
  {
    code: 2833,
    desc: "Medicinal Chemicals and Botanical Products",
  },
  {
    code: 2834,
    desc: "Pharmaceutical Preparations",
  },
  {
    code: 2835,
    desc: "In Vitro and In Vivo Diagnostic Substances",
  },
  {
    code: 2836,
    desc: "Biological Products, Except Diagnostic Substances",
  },
  {
    code: 2841,
    desc: "Soaps and Other Detergents, Except Speciality Cleaners",
  },
  {
    code: 2842,
    desc: "Speciality Cleaning, Polishing, and Sanitary Preparations",
  },
  {
    code: 2843,
    desc: "Surface Active Agents, Finishing Agents, Sulfonated Oils, and Assistants",
  },
  {
    code: 2844,
    desc: "Perfumes, Cosmetics, and Other Toilet Preparations",
  },
  {
    code: 2851,
    desc: "Paints, Varnishes, Lacquers, Enamels, and Allied Products",
  },
  {
    code: 2861,
    desc: "Gum and Wood Chemicals",
  },
  {
    code: 2865,
    desc: "Cyclic Organic Crudes and Intermediates, and Organic Dyes and Pigments",
  },
  {
    code: 2869,
    desc: "Industrial Organic Chemicals, NEC",
  },
  {
    code: 2873,
    desc: "Nitrogenous Fertilizers",
  },
  {
    code: 2874,
    desc: "Phosphatic Fertilizers",
  },
  {
    code: 2875,
    desc: "Fertilizers, Mixing Only",
  },
  {
    code: 2879,
    desc: "Pesticides and Agricultural Chemicals, NEC",
  },
  {
    code: 2891,
    desc: "Adhesives and Sealants",
  },
  {
    code: 2892,
    desc: "Explosives",
  },
  {
    code: 2893,
    desc: "Printing Ink",
  },
  {
    code: 2895,
    desc: "Carbon Black",
  },
  {
    code: 2899,
    desc: "Chemicals and Chemical Preparations, NEC",
  },
  {
    code: 2911,
    desc: "Petroleum Refining",
  },
  {
    code: 2951,
    desc: "Asphalt Paving Mixtures and Blocks",
  },
  {
    code: 2952,
    desc: "Asphalt Felts and Coatings",
  },
  {
    code: 2992,
    desc: "Lubricating Oils and Greases",
  },
  {
    code: 2999,
    desc: "Products of Petroleum and Coal, NEC",
  },
  {
    code: 3011,
    desc: "Tires and Inner Tubes",
  },
  {
    code: 3021,
    desc: "Rubber and Plastics Footwear",
  },
  {
    code: 3052,
    desc: "Rubber and Plastics Hose and Belting",
  },
  {
    code: 3053,
    desc: "Gaskets, Packing, and Sealing Devices",
  },
  {
    code: 3061,
    desc: "Molded, Extruded, and Lathe-Cut Mechanical Rubber Products",
  },
  {
    code: 3069,
    desc: "Fabricated Rubber Products, NEC",
  },
  {
    code: 3081,
    desc: "Unsupported Plastics Film and Sheet",
  },
  {
    code: 3082,
    desc: "Unsupported Plastics Profile Shapes",
  },
  {
    code: 3083,
    desc: "Laminated Plastics Plate, Sheet, and Profile Shapes",
  },
  {
    code: 3084,
    desc: "Plastic Pipe",
  },
  {
    code: 3085,
    desc: "Plastics Bottles",
  },
  {
    code: 3086,
    desc: "Plastics Foam Products",
  },
  {
    code: 3087,
    desc: "Custom Compounding of Purchased Plastics Resins",
  },
  {
    code: 3088,
    desc: "Plastics Plumbing Fixtures",
  },
  {
    code: 3089,
    desc: "Plastics Products, NEC",
  },
  {
    code: 3111,
    desc: "Leather Tanning and Finishing",
  },
  {
    code: 3131,
    desc: "Boot and Shoe Cut Stock and Findings",
  },
  {
    code: 3142,
    desc: "House Slippers",
  },
  {
    code: 3143,
    desc: "Men's Footwear, Except Athletic",
  },
  {
    code: 3144,
    desc: "Women's Footwear, Except Athletic",
  },
  {
    code: 3149,
    desc: "Footwear, Except Rubber, NEC",
  },
  {
    code: 3151,
    desc: "Leather Gloves and Mittens",
  },
  {
    code: 3161,
    desc: "Luggage",
  },
  {
    code: 3171,
    desc: "Women's Handbags and Purses",
  },
  {
    code: 3172,
    desc: "Personal Leather Goods, Except Women's Handbags and Purses",
  },
  {
    code: 3199,
    desc: "Leather Goods, NEC",
  },
  {
    code: 3211,
    desc: "Flat Glass",
  },
  {
    code: 3221,
    desc: "Glass Containers",
  },
  {
    code: 3229,
    desc: "Pressed and Blown Glass and Glassware, NEC",
  },
  {
    code: 3231,
    desc: "Glass Products, Made of Purchased Glass",
  },
  {
    code: 3241,
    desc: "Cement, Hydraulic",
  },
  {
    code: 3251,
    desc: "Brick and Structural Clay Tile",
  },
  {
    code: 3253,
    desc: "Ceramic Wall and Floor Tile",
  },
  {
    code: 3255,
    desc: "Clay Refractories",
  },
  {
    code: 3259,
    desc: "Structural Clay Products, NEC",
  },
  {
    code: 3261,
    desc: "Vitreous China Plumbing Fixtures and China and Earthenware Fittings and Bathroom Accessories",
  },
  {
    code: 3262,
    desc: "Vitreous China Table and Kitchen Articles",
  },
  {
    code: 3263,
    desc: "Fine Earthenware (Whiteware) Table and Kitchen Articles",
  },
  {
    code: 3264,
    desc: "Porcelain Electrical Supplies",
  },
  {
    code: 3269,
    desc: "Pottery Products, NEC",
  },
  {
    code: 3271,
    desc: "Concrete Block and Brick",
  },
  {
    code: 3272,
    desc: "Concrete Products, Except Block and Brick",
  },
  {
    code: 3273,
    desc: "Ready-Mixed Concrete",
  },
  {
    code: 3274,
    desc: "Lime",
  },
  {
    code: 3275,
    desc: "Gypsum Products",
  },
  {
    code: 3281,
    desc: "Cut Stone and Stone Products",
  },
  {
    code: 3291,
    desc: "Abrasive Products",
  },
  {
    code: 3292,
    desc: "Asbestos Products",
  },
  {
    code: 3295,
    desc: "Minerals and Earths, Ground or Otherwise Treated",
  },
  {
    code: 3296,
    desc: "Mineral Wool",
  },
  {
    code: 3297,
    desc: "Nonclay Refractories",
  },
  {
    code: 3299,
    desc: "Nonmetallic Mineral Products, NEC",
  },
  {
    code: 3312,
    desc: "Steel Works, Blast Furnaces (Including Coke Ovens), and Rolling Mills",
  },
  {
    code: 3313,
    desc: "Electrometallurgical Products, Except Steel",
  },
  {
    code: 3315,
    desc: "Steel Wiredrawing and Steel Nails and Spikes",
  },
  {
    code: 3316,
    desc: "Cold-Rolled Steel Sheet, Strip, and Bars",
  },
  {
    code: 3317,
    desc: "Steel Pipe and Tubes",
  },
  {
    code: 3321,
    desc: "Gray and Ductile Iron Foundries",
  },
  {
    code: 3322,
    desc: "Malleable Iron Foundries",
  },
  {
    code: 3324,
    desc: "Steel Investment Foundries",
  },
  {
    code: 3325,
    desc: "Steel Foundries, NEC",
  },
  {
    code: 3331,
    desc: "Primary Smelting and Refining of Copper",
  },
  {
    code: 3334,
    desc: "Primary Production of Aluminum",
  },
  {
    code: 3339,
    desc: "Primary Smelting and Refining of Nonferrous Metals, Except Copper and Aluminum",
  },
  {
    code: 3341,
    desc: "Secondary Smelting and Refining of Nonferrous Metals",
  },
  {
    code: 3351,
    desc: "Rolling, Drawing, and Extruding of Copper",
  },
  {
    code: 3353,
    desc: "Aluminum Sheet, Plate, and Foil",
  },
  {
    code: 3354,
    desc: "Aluminum Extruded Products",
  },
  {
    code: 3355,
    desc: "Aluminum Rolling and Drawing, NEC",
  },
  {
    code: 3356,
    desc: "Rolling, Drawing, and Extruding of Nonferrous Metals, Except Copper and Aluminum",
  },
  {
    code: 3357,
    desc: "Drawing and Insulating of Nonferrous Wire",
  },
  {
    code: 3363,
    desc: "Aluminum Die-Castings",
  },
  {
    code: 3364,
    desc: "Nonferrous Die-Castings, Except Aluminum",
  },
  {
    code: 3365,
    desc: "Aluminum Foundries",
  },
  {
    code: 3366,
    desc: "Copper Foundries",
  },
  {
    code: 3369,
    desc: "Nonferrous Foundries, Except Aluminum and Copper",
  },
  {
    code: 3398,
    desc: "Metal Heat Treating",
  },
  {
    code: 3399,
    desc: "Primary Metal Products, NEC",
  },
  {
    code: 3411,
    desc: "Metal Cans",
  },
  {
    code: 3412,
    desc: "Metal Shipping Barrels, Drums, Kegs and Pails",
  },
  {
    code: 3421,
    desc: "Cutlery",
  },
  {
    code: 3423,
    desc: "Hand and Edge Tools, Except Machine Tools and Handsaws",
  },
  {
    code: 3425,
    desc: "Saw Blades and Handsaws",
  },
  {
    code: 3429,
    desc: "Hardware, NEC",
  },
  {
    code: 3431,
    desc: "Enameled Iron and Metal Sanitary Ware",
  },
  {
    code: 3432,
    desc: "Plumbing Fixture Fittings and Trim",
  },
  {
    code: 3433,
    desc: "Heating Equipment, Except Electric and Warm Air Furnaces",
  },
  {
    code: 3441,
    desc: "Fabricated Structural Metal",
  },
  {
    code: 3442,
    desc: "Metal Doors, Sash, Frames, Molding, and Trim Manufacturing",
  },
  {
    code: 3443,
    desc: "Fabricated Plate Work (Boiler Shops)",
  },
  {
    code: 3444,
    desc: "Sheet Metal Work",
  },
  {
    code: 3446,
    desc: "Architectural and Ornamental Metal Work",
  },
  {
    code: 3448,
    desc: "Prefabricated Metal Buildings and Components",
  },
  {
    code: 3449,
    desc: "Miscellaneous Structural Metal Work",
  },
  {
    code: 3451,
    desc: "Screw Machine Products",
  },
  {
    code: 3452,
    desc: "Bolts, Nuts, Screws, Rivets, and Washers",
  },
  {
    code: 3462,
    desc: "Iron and Steel Forgings",
  },
  {
    code: 3463,
    desc: "Nonferrous Forgings",
  },
  {
    code: 3465,
    desc: "Automotive Stamping",
  },
  {
    code: 3466,
    desc: "Crowns and Closures",
  },
  {
    code: 3469,
    desc: "Metal Stamping, NEC",
  },
  {
    code: 3471,
    desc: "Electroplating, Plating, Polishing, Anodizing, and Coloring",
  },
  {
    code: 3479,
    desc: "Coating, Engraving, and Allied Services, NEC",
  },
  {
    code: 3482,
    desc: "Small Arms Ammunition",
  },
  {
    code: 3483,
    desc: "Ammunition, Except for Small Arms",
  },
  {
    code: 3484,
    desc: "Small Arms",
  },
  {
    code: 3489,
    desc: "Ordnance and Accessories, NEC",
  },
  {
    code: 3491,
    desc: "Industrial Valves",
  },
  {
    code: 3492,
    desc: "Fluid Power Valves and Hose Fittings",
  },
  {
    code: 3493,
    desc: "Steel Springs, Except Wire",
  },
  {
    code: 3494,
    desc: "Valves and Pipe Fittings, NEC",
  },
  {
    code: 3495,
    desc: "Wire Springs",
  },
  {
    code: 3496,
    desc: "Miscellaneous Fabricated Wire Products",
  },
  {
    code: 3497,
    desc: "Metal Foil and Leaf",
  },
  {
    code: 3498,
    desc: "Fabricated Pipe and Pipe Fittings",
  },
  {
    code: 3499,
    desc: "Fabricated Metal Products, NEC",
  },
  {
    code: 3511,
    desc: "Steam, Gas, and Hydraulic Turbines, and Turbine Generator Set Units",
  },
  {
    code: 3519,
    desc: "Internal Combustion Engines, NEC",
  },
  {
    code: 3523,
    desc: "Farm Machinery and Equipment",
  },
  {
    code: 3524,
    desc: "Lawn and Garden Tractors and Home Lawn and Garden  Equipment",
  },
  {
    code: 3531,
    desc: "Construction Machinery and Equipment",
  },
  {
    code: 3532,
    desc: "Mining Machinery and Equipment, Except Oil and Gas Field  Machinery and Equipment",
  },
  {
    code: 3533,
    desc: "Oil and Gas Field Machinery and Equipment",
  },
  {
    code: 3534,
    desc: "Elevators and Moving Stairways",
  },
  {
    code: 3535,
    desc: "Conveyors and Conveying Equipment",
  },
  {
    code: 3536,
    desc: "Overhead Traveling Cranes, Hoists and Monorail Systems",
  },
  {
    code: 3537,
    desc: "Industrial Trucks, Tractors, Trailers, and Stackers",
  },
  {
    code: 3541,
    desc: "Machine Tools,  Metal Cutting Type",
  },
  {
    code: 3542,
    desc: "Machine Tools, Metal Forming Type",
  },
  {
    code: 3543,
    desc: "Industrial Patterns",
  },
  {
    code: 3544,
    desc: "Special Dies and Tools, Die Sets, Jigs and Fixtures, and Industrial Molds",
  },
  {
    code: 3545,
    desc: "Cutting Tools, Machine Tool Accessories, and Machinists' Precision Measuring Devices",
  },
  {
    code: 3546,
    desc: "Power-Driven Handtools",
  },
  {
    code: 3547,
    desc: "Rolling Mill Machinery and Equipment",
  },
  {
    code: 3548,
    desc: "Electric and Gas Welding and Soldering Equipment",
  },
  {
    code: 3549,
    desc: "Metalworking Machinery, NEC",
  },
  {
    code: 3552,
    desc: "Textile Machinery",
  },
  {
    code: 3553,
    desc: "Woodworking Machinery",
  },
  {
    code: 3554,
    desc: "Paper Industries Machinery",
  },
  {
    code: 3555,
    desc: "Printing Trades Machinery and Equipment",
  },
  {
    code: 3556,
    desc: "Food Products Machinery",
  },
  {
    code: 3559,
    desc: "Special  Industry Machinery, NEC",
  },
  {
    code: 3561,
    desc: "Pumps and Pumping Equipment",
  },
  {
    code: 3562,
    desc: "Ball and Roller Bearings",
  },
  {
    code: 3563,
    desc: "Air and Gas Compressors",
  },
  {
    code: 3564,
    desc: "Industrial and Commercial Fans and Blowers and Air  Purification Equipment",
  },
  {
    code: 3565,
    desc: "Packaging Machinery",
  },
  {
    code: 3566,
    desc: "Speed Changers, Industrial High-Speed Drives, and Gears",
  },
  {
    code: 3567,
    desc: "Industrial Process Furnaces and Ovens",
  },
  {
    code: 3568,
    desc: "Mechanical Power Transmission Equipment, NEC",
  },
  {
    code: 3569,
    desc: "General Industrial Machinery and Equipment, NEC",
  },
  {
    code: 3571,
    desc: "Electronic Computers",
  },
  {
    code: 3572,
    desc: "Computer Storage Devices",
  },
  {
    code: 3575,
    desc: "Computer Terminals",
  },
  {
    code: 3577,
    desc: "Computer Peripheral Equipment, NEC",
  },
  {
    code: 3578,
    desc: "Calculating and Accounting Machines, Except Electronic Computers",
  },
  {
    code: 3579,
    desc: "Office Machines, NEC",
  },
  {
    code: 3581,
    desc: "Automatic Vending Machines",
  },
  {
    code: 3582,
    desc: "Commercial Laundry, Drycleaning, and Pressing Machines",
  },
  {
    code: 3585,
    desc: "Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment",
  },
  {
    code: 3586,
    desc: "Measuring and Dispensing Pumps",
  },
  {
    code: 3589,
    desc: "Service Industry Machinery, NEC",
  },
  {
    code: 3592,
    desc: "Carburetors, Pistons, Piston Rings and Valves",
  },
  {
    code: 3593,
    desc: "Fluid Power Cylinders and Actuators",
  },
  {
    code: 3594,
    desc: "Fluid Power Pumps and Motors",
  },
  {
    code: 3596,
    desc: "Scales and Balances, Except Laboratory",
  },
  {
    code: 3599,
    desc: "Industrial and Commercial Machinery and Equipment, NEC",
  },
  {
    code: 3612,
    desc: "Power, Distribution, and Specialty Transformers",
  },
  {
    code: 3613,
    desc: "Switchgear and Switchboard Apparatus",
  },
  {
    code: 3621,
    desc: "Motors and Generators",
  },
  {
    code: 3624,
    desc: "Carbon and Graphite Products",
  },
  {
    code: 3625,
    desc: "Relays and Industrial Controls",
  },
  {
    code: 3629,
    desc: "Electrical Industrial Apparatus, NEC",
  },
  {
    code: 3631,
    desc: "Household Cooking Equipment",
  },
  {
    code: 3632,
    desc: "Household Refrigerators and Home and Farm Freezers",
  },
  {
    code: 3633,
    desc: "Household Laundry Equipment",
  },
  {
    code: 3634,
    desc: "Electric Housewares and Fans",
  },
  {
    code: 3635,
    desc: "Household Vacuum Cleaners",
  },
  {
    code: 3639,
    desc: "Household Appliances, NEC",
  },
  {
    code: 3641,
    desc: "Electric Lamp Bulbs and Tubes",
  },
  {
    code: 3643,
    desc: "Current-Carrying Wiring Devices",
  },
  {
    code: 3644,
    desc: "Noncurrent-Carrying Wiring Devices",
  },
  {
    code: 3645,
    desc: "Residential Electric Lighting Fixtures",
  },
  {
    code: 3646,
    desc: "Commercial, Industrial, and Institutional Electric Lighting Fixtures",
  },
  {
    code: 3647,
    desc: "Vehicular Lighting Equipment",
  },
  {
    code: 3648,
    desc: "Lighting Equipment, NEC",
  },
  {
    code: 3651,
    desc: "Household Audio and Video Equipment",
  },
  {
    code: 3652,
    desc: "Phonograph Records and Prerecorded Audio Tapes and Disks",
  },
  {
    code: 3661,
    desc: "Telephone and Telegraph Apparatus",
  },
  {
    code: 3663,
    desc: "Radio and Television Broadcasting and Communication Equipment",
  },
  {
    code: 3669,
    desc: "Communications Equipment, NEC",
  },
  {
    code: 3671,
    desc: "Electron Tubes",
  },
  {
    code: 3672,
    desc: "Printed Circuit Boards",
  },
  {
    code: 3674,
    desc: "Semiconductors and Related Devices",
  },
  {
    code: 3675,
    desc: "Electronic Capacitors",
  },
  {
    code: 3676,
    desc: "Electronic Resistors",
  },
  {
    code: 3677,
    desc: "Electronic Coils, Transformers, and Other Inductors",
  },
  {
    code: 3678,
    desc: "Electronic Connectors",
  },
  {
    code: 3679,
    desc: "Electronic Components, NEC",
  },
  {
    code: 3691,
    desc: "Storage Batteries",
  },
  {
    code: 3692,
    desc: "Primary Batteries, Dry and Wet",
  },
  {
    code: 3694,
    desc: "Electrical Equipment for Internal Combustion Engines",
  },
  {
    code: 3695,
    desc: "Magnetic and Optical Recording Media",
  },
  {
    code: 3699,
    desc: "Electrical Machinery, Equipment, and Supplies, NEC",
  },
  {
    code: 3711,
    desc: "Motor Vehicles and Passenger Car Bodies",
  },
  {
    code: 3713,
    desc: "Truck and Bus Bodies",
  },
  {
    code: 3714,
    desc: "Motor Vehicle Parts and Accessories",
  },
  {
    code: 3715,
    desc: "Truck Trailers",
  },
  {
    code: 3716,
    desc: "Motor  Homes",
  },
  {
    code: 3721,
    desc: "Aircraft",
  },
  {
    code: 3724,
    desc: "Aircraft Engines and Engine Parts",
  },
  {
    code: 3728,
    desc: "Aircraft Parts and Auxiliary Equipment, NEC",
  },
  {
    code: 3731,
    desc: "Ship Building and Repairing",
  },
  {
    code: 3732,
    desc: "Boat  Building and Repairing",
  },
  {
    code: 3743,
    desc: "Railroad Equipment",
  },
  {
    code: 3751,
    desc: "Motorcycles, Bicycles, and Parts",
  },
  {
    code: 3761,
    desc: "Guided Missiles and Space Vehicles",
  },
  {
    code: 3764,
    desc: "Guided Missile and Space Vehicle Propulsion Units and Propulsion Unit Parts",
  },
  {
    code: 3769,
    desc: "Guided Missile Space Vehicle Parts and Auxiliary Equipment, NEC",
  },
  {
    code: 3792,
    desc: "Travel Trailers and Campers",
  },
  {
    code: 3795,
    desc: "Tanks and Tank Components",
  },
  {
    code: 3799,
    desc: "Transportation Equipment, NEC",
  },
  {
    code: 3812,
    desc: "Search, Detection, Navigation, Guidance, Aeronautical, and Nautical Systems and  Instruments",
  },
  {
    code: 3821,
    desc: "Laboratory Apparatus and Furniture",
  },
  {
    code: 3822,
    desc: "Automatic Controls for Regulating Residential and Commercial Environments and Appliances",
  },
  {
    code: 3823,
    desc: "Industrial Instruments for Measurement, Display, and Control of Process Variables; and Related Products",
  },
  {
    code: 3824,
    desc: "Totalizing Fluid Meters and Counting Devices",
  },
  {
    code: 3825,
    desc: "Instruments for Measuring and Testing of Electricity and Electrical Signals",
  },
  {
    code: 3826,
    desc: "Laboratory Analytical Instruments",
  },
  {
    code: 3827,
    desc: "Optical Instruments and Lenses",
  },
  {
    code: 3829,
    desc: "Measuring and Controlling Devices, NEC",
  },
  {
    code: 3841,
    desc: "Surgical and Medical Instruments and Apparatus",
  },
  {
    code: 3842,
    desc: "Orthopedic, Prosthetic, and Surgical Appliances and Supplies",
  },
  {
    code: 3843,
    desc: "Dental Equipment and Supplies",
  },
  {
    code: 3844,
    desc: "X-Ray Apparatus and Tubes and Related Irradiation Apparatus",
  },
  {
    code: 3845,
    desc: "Electromedical and Electrotherapeutic Apparatus",
  },
  {
    code: 3851,
    desc: "Ophthalmic Goods",
  },
  {
    code: 3861,
    desc: "Photographic Equipment and Supplies",
  },
  {
    code: 3873,
    desc: "Watches, Clocks, Clockwork Operated Devices and Parts",
  },
  {
    code: 3911,
    desc: "Jewelry, Precious Metal",
  },
  {
    code: 3914,
    desc: "Silverware, Plated Ware, and Stainless Steel Ware",
  },
  {
    code: 3915,
    desc: "Jewelers' Findings and Materials, and Lapidary Work",
  },
  {
    code: 3931,
    desc: "Musical Instruments",
  },
  {
    code: 3942,
    desc: "Dolls and Stuffed Toys",
  },
  {
    code: 3944,
    desc: "Games, Toys, and Children's Vehicles, Except Dolls and Bicycles",
  },
  {
    code: 3949,
    desc: "Sporting and Athletic Goods, NEC",
  },
  {
    code: 3951,
    desc: "Pens, Mechanical Pencils and Parts",
  },
  {
    code: 3952,
    desc: "Lead Pencils, Crayons, and Artist's Materials",
  },
  {
    code: 3953,
    desc: "Marking Devices",
  },
  {
    code: 3955,
    desc: "Carbon Paper and Inked Ribbons",
  },
  {
    code: 3961,
    desc: "Costume Jewelry and Costume Novelties, Except Precious Metals",
  },
  {
    code: 3965,
    desc: "Fasteners, Buttons, Needles, and Pins",
  },
  {
    code: 3991,
    desc: "Brooms and Brushes",
  },
  {
    code: 3993,
    desc: "Signs and Advertising Specialties",
  },
  {
    code: 3995,
    desc: "Burial Caskets",
  },
  {
    code: 3996,
    desc: "Linoleum, Asphalted-Felt-Base, and Other Hard Surface Floor Coverings, NEC",
  },
  {
    code: 3999,
    desc: "Manufacturing Industries, NEC",
  },
  {
    code: 4011,
    desc: "Railroads, Line-haul Operating",
  },
  {
    code: 4013,
    desc: "Railroad Switching and Terminal Establishments",
  },
  {
    code: 4111,
    desc: "Local and Suburban Transit",
  },
  {
    code: 4119,
    desc: "Local Passenger Transportation, NEC",
  },
  {
    code: 4121,
    desc: "Taxicabs",
  },
  {
    code: 4131,
    desc: "Intercity and Rural Bus Transportation",
  },
  {
    code: 4141,
    desc: "Local Bus Charter Service",
  },
  {
    code: 4142,
    desc: "Bus Charter Service, Except Local",
  },
  {
    code: 4151,
    desc: "School Buses",
  },
  {
    code: 4173,
    desc: "Terminal and Service Facilities for Motor Vehicle Passenger Transportation",
  },
  {
    code: 4212,
    desc: "Local Trucking Without Storage",
  },
  {
    code: 4213,
    desc: "Trucking, Except Local",
  },
  {
    code: 4214,
    desc: "Local Trucking with Storage",
  },
  {
    code: 4215,
    desc: "Couriers Services Except by Air",
  },
  {
    code: 4221,
    desc: "Farm Product Warehousing and Storage",
  },
  {
    code: 4222,
    desc: "Refrigerated Warehousing and Storage",
  },
  {
    code: 4225,
    desc: "General Warehousing and Storage",
  },
  {
    code: 4226,
    desc: "Special Warehousing and Storage, NEC",
  },
  {
    code: 4231,
    desc: "Terminal and Joint Terminal Maintenance Facilities for Motor Freight Transportation",
  },
  {
    code: 4311,
    desc: "United States Postal Service",
  },
  {
    code: 4412,
    desc: "Deep Sea Foreign Transportation of  Freight",
  },
  {
    code: 4424,
    desc: "Deep Sea Domestic Transportation of Freight",
  },
  {
    code: 4432,
    desc: "Freight Transportation on the Great Lakes - St. Lawrence Seaway",
  },
  {
    code: 4449,
    desc: "Water Transportation of Freight, NEC",
  },
  {
    code: 4481,
    desc: "Deep Sea Transportation of Passengers, Except by Ferry",
  },
  {
    code: 4482,
    desc: "Ferries",
  },
  {
    code: 4489,
    desc: "Water Transportation of Passengers, NEC",
  },
  {
    code: 4491,
    desc: "Marine Cargo Handling",
  },
  {
    code: 4492,
    desc: "Towing and Tugboat Services",
  },
  {
    code: 4493,
    desc: "Marinas",
  },
  {
    code: 4499,
    desc: "Water Transportation Services, NEC",
  },
  {
    code: 4512,
    desc: "Air Transportation, Scheduled",
  },
  {
    code: 4513,
    desc: "Air Courier Services",
  },
  {
    code: 4522,
    desc: "Air Transportation, Nonscheduled",
  },
  {
    code: 4581,
    desc: "Airports, Flying Fields, and Airport Terminal Services",
  },
  {
    code: 4612,
    desc: "Crude Petroleum Pipelines",
  },
  {
    code: 4613,
    desc: "Refined Petroleum Pipelines",
  },
  {
    code: 4619,
    desc: "Pipelines, NEC",
  },
  {
    code: 4724,
    desc: "Travel Agencies",
  },
  {
    code: 4725,
    desc: "Tour Operators",
  },
  {
    code: 4729,
    desc: "Arrangement of Passenger Transportation, NEC",
  },
  {
    code: 4731,
    desc: "Arrangement of Transportation of  Freight and Cargo",
  },
  {
    code: 4741,
    desc: "Rental of Railroad Cars",
  },
  {
    code: 4783,
    desc: "Packing and Crating",
  },
  {
    code: 4785,
    desc: "Fixed Facilities and Inspection and Weighing Services for Motor Vehicle Transportation",
  },
  {
    code: 4789,
    desc: "Transportation Services, NEC",
  },
  {
    code: 4812,
    desc: "Radiotelephone Communications",
  },
  {
    code: 4813,
    desc: "Telephone Communications, Except Radiotelephone",
  },
  {
    code: 4822,
    desc: "Telegraph and Other  Message Communications",
  },
  {
    code: 4832,
    desc: "Radio Broadcasting Stations",
  },
  {
    code: 4833,
    desc: "Television Broadcasting Stations",
  },
  {
    code: 4841,
    desc: "Cable and Other Pay Television Services",
  },
  {
    code: 4899,
    desc: "Communications Services, NEC",
  },
  {
    code: 4911,
    desc: "Electric Services",
  },
  {
    code: 4922,
    desc: "Natural Gas Transmission",
  },
  {
    code: 4923,
    desc: "Natural Gas Transmission and Distribution",
  },
  {
    code: 4924,
    desc: "Natural Gas Distribution",
  },
  {
    code: 4925,
    desc: "Mixed, Manufactured, or Liquefied Petroleum Gas Production and/or Distribution",
  },
  {
    code: 4931,
    desc: "Electric and Other Services Combined",
  },
  {
    code: 4932,
    desc: "Gas and Other Services Combined",
  },
  {
    code: 4939,
    desc: "Combination Utilities, NEC",
  },
  {
    code: 4941,
    desc: "Water Supply",
  },
  {
    code: 4952,
    desc: "Sewerage Systems",
  },
  {
    code: 4953,
    desc: "Refuse Systems",
  },
  {
    code: 4959,
    desc: "Sanitary Services, NEC",
  },
  {
    code: 4961,
    desc: "Steam and Air-Conditioning Supply",
  },
  {
    code: 4971,
    desc: "Irrigation Systems",
  },
  {
    code: 5012,
    desc: "Automobiles and Other Motor Vehicles",
  },
  {
    code: 5013,
    desc: "Motor Vehicle Supplies and New Parts",
  },
  {
    code: 5014,
    desc: "Tires and Tubes",
  },
  {
    code: 5015,
    desc: "Motor Vehicle Parts, Used",
  },
  {
    code: 5021,
    desc: "Furniture",
  },
  {
    code: 5023,
    desc: "Home Furnishings",
  },
  {
    code: 5031,
    desc: "Lumber, Plywood, Millwork, and Wood Panels",
  },
  {
    code: 5032,
    desc: "Brick, Stone and Related Construction Materials",
  },
  {
    code: 5033,
    desc: "Roofing, Siding, and Insulation Materials",
  },
  {
    code: 5039,
    desc: "Construction Materials, NEC",
  },
  {
    code: 5043,
    desc: "Photographic Equipment and Supplies",
  },
  {
    code: 5044,
    desc: "Office Equipment",
  },
  {
    code: 5045,
    desc: "Computers and Computer Peripheral Equipment and Software",
  },
  {
    code: 5046,
    desc: "Commercial Equipment, NEC",
  },
  {
    code: 5047,
    desc: "Medical, Dental, and Hospital Equipment and Supplies",
  },
  {
    code: 5048,
    desc: "Ophthalmic Goods",
  },
  {
    code: 5049,
    desc: "Professional Equipment and Supplies, NEC",
  },
  {
    code: 5051,
    desc: "Metals Service Centers and Offices",
  },
  {
    code: 5052,
    desc: "Coal and Other Minerals and Ores",
  },
  {
    code: 5063,
    desc: "Electrical Apparatus and Equipment Wiring Supplies, and Construction Materials",
  },
  {
    code: 5064,
    desc: "Electrical Appliances, Television and Radio Sets",
  },
  {
    code: 5065,
    desc: "Electronic Parts and Equipment, Not Elsewhere Classified",
  },
  {
    code: 5072,
    desc: "Hardware",
  },
  {
    code: 5074,
    desc: "Plumbing and Heating Equipment and Supplies (Hydronics)",
  },
  {
    code: 5075,
    desc: "Warm Air Heating and Air-Conditioning Equipment and Supplies",
  },
  {
    code: 5078,
    desc: "Refrigeration Equipment and Supplies",
  },
  {
    code: 5082,
    desc: "Construction and Mining (Except Petroleum) Machinery and Equipment",
  },
  {
    code: 5083,
    desc: "Farm and Garden Machinery and Equipment",
  },
  {
    code: 5084,
    desc: "Industrial Machinery and Equipment",
  },
  {
    code: 5085,
    desc: "Industrial Supplies",
  },
  {
    code: 5087,
    desc: "Service Establishment Equipment and Supplies",
  },
  {
    code: 5088,
    desc: "Transportation Equipment and Supplies, Except Motor Vehicles",
  },
  {
    code: 5091,
    desc: "Sporting and Recreational Goods and Supplies",
  },
  {
    code: 5092,
    desc: "Toys and Hobby Goods and Supplies",
  },
  {
    code: 5093,
    desc: "Scrap and Waste Materials",
  },
  {
    code: 5094,
    desc: "Jewelry, Watches, Precious Stones, and Precious Metals",
  },
  {
    code: 5099,
    desc: "Durable Goods, NEC",
  },
  {
    code: 5111,
    desc: "Printing and Writing Paper",
  },
  {
    code: 5112,
    desc: "Stationery and Office Supplies",
  },
  {
    code: 5113,
    desc: "Industrial and Personal Service Paper",
  },
  {
    code: 5122,
    desc: "Drugs, Drug Proprietaries, and Druggists' Sundries",
  },
  {
    code: 5131,
    desc: "Piece Goods, Notions, and Other Dry Goods",
  },
  {
    code: 5136,
    desc: "Men's and Boys' Clothing and Furnishings",
  },
  {
    code: 5137,
    desc: "Women's Children's and Infants' Clothing and Accessories",
  },
  {
    code: 5139,
    desc: "Footwear",
  },
  {
    code: 5141,
    desc: "Groceries, General Line",
  },
  {
    code: 5142,
    desc: "Packaged Frozen Foods",
  },
  {
    code: 5143,
    desc: "Dairy Products, Except Dried or Canned",
  },
  {
    code: 5144,
    desc: "Poultry and Poultry Products",
  },
  {
    code: 5145,
    desc: "Confectionery",
  },
  {
    code: 5146,
    desc: "Fish and Seafoods",
  },
  {
    code: 5147,
    desc: "Meats and Meat Products",
  },
  {
    code: 5148,
    desc: "Fresh Fruits and Vegetables",
  },
  {
    code: 5149,
    desc: "Groceries and Related Products, NEC",
  },
  {
    code: 5153,
    desc: "Grain and Field Beans",
  },
  {
    code: 5154,
    desc: "Livestock",
  },
  {
    code: 5159,
    desc: "Farm-Product Raw Materials, NEC",
  },
  {
    code: 5162,
    desc: "Plastics Materials and Basic Forms and Shapes",
  },
  {
    code: 5169,
    desc: "Chemicals and Allied Products, NEC",
  },
  {
    code: 5171,
    desc: "Petroleum Bulk Stations and Terminals",
  },
  {
    code: 5172,
    desc: "Petroleum and Petroleum Products Wholesalers, Except Bulk Stations and Terminals",
  },
  {
    code: 5181,
    desc: "Beer and Ale",
  },
  {
    code: 5182,
    desc: "Wine and Distilled Alcoholic Beverages",
  },
  {
    code: 5191,
    desc: "Farm Supplies",
  },
  {
    code: 5192,
    desc: "Books, Periodicals, and Newspapers",
  },
  {
    code: 5193,
    desc: "Flowers, Nursery Stock, and Florists' Supplies",
  },
  {
    code: 5194,
    desc: "Tobacco and Tobacco Products",
  },
  {
    code: 5198,
    desc: "Paint, Varnishes, and Supplies",
  },
  {
    code: 5199,
    desc: "Nondurable Goods, NEC",
  },
  {
    code: 5211,
    desc: "Lumber and Other Building Materials Dealers",
  },
  {
    code: 5231,
    desc: "Paint, Glass, and Wallpaper Stores",
  },
  {
    code: 5251,
    desc: "Hardware Stores",
  },
  {
    code: 5261,
    desc: "Retail Nurseries, Lawn and Garden Supply Stores",
  },
  {
    code: 5271,
    desc: "Mobile Home Dealers",
  },
  {
    code: 5311,
    desc: "Department Stores",
  },
  {
    code: 5331,
    desc: "Variety Stores",
  },
  {
    code: 5399,
    desc: "Miscellaneous General Merchandise Stores",
  },
  {
    code: 5411,
    desc: "Grocery Stores",
  },
  {
    code: 5421,
    desc: "Meat and Fish (Seafood) Markets, Including Freezer Provisioners",
  },
  {
    code: 5431,
    desc: "Fruit and Vegetable Markets",
  },
  {
    code: 5441,
    desc: "Candy, Nut, and Confectionery Stores",
  },
  {
    code: 5451,
    desc: "Dairy Products Stores",
  },
  {
    code: 5461,
    desc: "Retail Bakeries",
  },
  {
    code: 5499,
    desc: "Miscellaneous Food Stores",
  },
  {
    code: 5511,
    desc: "Motor Vehicle Dealers (New and Used)",
  },
  {
    code: 5521,
    desc: "Motor Vehicle Dealers (Used Only)",
  },
  {
    code: 5531,
    desc: "Auto and Home Supply Stores",
  },
  {
    code: 5541,
    desc: "Gasoline Service Stations",
  },
  {
    code: 5551,
    desc: "Boat Dealers",
  },
  {
    code: 5561,
    desc: "Recreational Vehicle Dealers",
  },
  {
    code: 5571,
    desc: "Motorcycle Dealers",
  },
  {
    code: 5599,
    desc: "Automotive Dealers, NEC",
  },
  {
    code: 5611,
    desc: "Men's and Boys' Clothing and Accessory Stores",
  },
  {
    code: 5621,
    desc: "Women's Clothing Stores",
  },
  {
    code: 5632,
    desc: "Women's Accessory and Specialty Stores",
  },
  {
    code: 5641,
    desc: "Children's and Infants' Wear Stores",
  },
  {
    code: 5651,
    desc: "Family Clothing Stores",
  },
  {
    code: 5661,
    desc: "Shoe Stores",
  },
  {
    code: 5699,
    desc: "Miscellaneous Apparel and Accessory Stores",
  },
  {
    code: 5712,
    desc: "Furniture Stores",
  },
  {
    code: 5713,
    desc: "Floor Covering Stores",
  },
  {
    code: 5714,
    desc: "Drapery, Curtain, and Upholstery Stores",
  },
  {
    code: 5719,
    desc: "Miscellaneous Homefurnishings Stores",
  },
  {
    code: 5722,
    desc: "Household Appliance Stores",
  },
  {
    code: 5731,
    desc: "Radio, Television, and Consumer Electronics Stores",
  },
  {
    code: 5734,
    desc: "Computer and Computer Software Stores",
  },
  {
    code: 5735,
    desc: "Record and Prerecorded Tape Stores",
  },
  {
    code: 5736,
    desc: "Musical Instrument Stores",
  },
  {
    code: 5812,
    desc: "Eating and Drinking Places",
  },
  {
    code: 5813,
    desc: "Drinking Places (Alcoholic Beverages)",
  },
  {
    code: 5912,
    desc: "Drug Stores and Proprietary Stores",
  },
  {
    code: 5921,
    desc: "Liquor Stores",
  },
  {
    code: 5932,
    desc: "Used Merchandise Stores",
  },
  {
    code: 5941,
    desc: "Sporting Goods Stores and Bicycle Shops",
  },
  {
    code: 5942,
    desc: "Book Stores",
  },
  {
    code: 5943,
    desc: "Stationery Stores",
  },
  {
    code: 5944,
    desc: "Jewelry Stores",
  },
  {
    code: 5945,
    desc: "Hobby, Toy, and Game Shops",
  },
  {
    code: 5946,
    desc: "Camera and Photographic Supply Stores",
  },
  {
    code: 5947,
    desc: "Gift, Novelty, and Souvenir Shops",
  },
  {
    code: 5948,
    desc: "Luggage and Leather Goods Stores",
  },
  {
    code: 5949,
    desc: "Sewing, Needlework, and Piece Goods Stores",
  },
  {
    code: 5961,
    desc: "Catalog and Mail-Order Houses",
  },
  {
    code: 5962,
    desc: "Automatic Merchandising Machine Operator",
  },
  {
    code: 5963,
    desc: "Direct Selling Establishments",
  },
  {
    code: 5983,
    desc: "Fuel Oil Dealers",
  },
  {
    code: 5984,
    desc: "Liquefied Petroleum Gas (Bottled Gas) Dealers",
  },
  {
    code: 5989,
    desc: "Fuel Dealers, NEC",
  },
  {
    code: 5992,
    desc: "Florists",
  },
  {
    code: 5993,
    desc: "Tobacco Stores and Stands",
  },
  {
    code: 5994,
    desc: "News Dealers and Newsstands",
  },
  {
    code: 5995,
    desc: "Optical Goods Stores",
  },
  {
    code: 5999,
    desc: "Miscellaneous Retail Stores, NEC",
  },
  {
    code: 6011,
    desc: "Federal Reserve Banks",
  },
  {
    code: 6019,
    desc: "Central Reserve Depository Institutions, NEC",
  },
  {
    code: 6021,
    desc: "National Commercial Banks",
  },
  {
    code: 6022,
    desc: "State Commercial Banks",
  },
  {
    code: 6029,
    desc: "Commercial Banks, NEC",
  },
  {
    code: 6035,
    desc: "Savings Institutions, Federally Chartered",
  },
  {
    code: 6036,
    desc: "Savings institutions, Not Federally Chartered",
  },
  {
    code: 6061,
    desc: "Credit Unions, Federally Chartered",
  },
  {
    code: 6062,
    desc: "Credit Unions, Not Federally Chartered",
  },
  {
    code: 6081,
    desc: "Branches and Agencies of Foreign Banks",
  },
  {
    code: 6082,
    desc: "Foreign Trade and International Banking Institutions",
  },
  {
    code: 6091,
    desc: "Nondeposit Trust Facilities",
  },
  {
    code: 6099,
    desc: "Functions Related to Deposit Banking, NEC",
  },
  {
    code: 6111,
    desc: "Federal and Federally Sponsored Credit Agencies",
  },
  {
    code: 6141,
    desc: "Personal Credit Institutions",
  },
  {
    code: 6153,
    desc: "Short-Term Business Credit Institutions, Except Agricultural",
  },
  {
    code: 6159,
    desc: "Miscellaneous Business Credit Institutions",
  },
  {
    code: 6162,
    desc: "Mortgage Bankers and Loan Correspondents",
  },
  {
    code: 6163,
    desc: "Loan Brokers",
  },
  {
    code: 6211,
    desc: "Security Brokers, Dealers, and Flotation Companies",
  },
  {
    code: 6219,
    desc: "Other Ambulatory Health Care Services",
  },
  {
    code: 6221,
    desc: "Commodity Contracts Brokers and Dealers",
  },
  {
    code: 6231,
    desc: "Security and Commodity Exchanges",
  },
  {
    code: 6282,
    desc: "Investment Advice",
  },
  {
    code: 6289,
    desc: "Services Allied With the Exchange of Securities or Commodities, NEC",
  },
  {
    code: 6311,
    desc: "Life Insurance",
  },
  {
    code: 6321,
    desc: "Accident and Health Insurance",
  },
  {
    code: 6324,
    desc: "Hospital and Medical Service Plans",
  },
  {
    code: 6331,
    desc: "Fire, Marine, and Casualty Insurance",
  },
  {
    code: 6351,
    desc: "Surety Insurance",
  },
  {
    code: 6361,
    desc: "Title Insurance",
  },
  {
    code: 6371,
    desc: "Pension, Health, and Welfare Funds",
  },
  {
    code: 6399,
    desc: "Insurance Carriers, NEC",
  },
  {
    code: 6411,
    desc: "Insurance Agents, Brokers, and Service",
  },
  {
    code: 6512,
    desc: "Operators of Nonresidential Buildings",
  },
  {
    code: 6513,
    desc: "Operators of Apartment Buildings",
  },
  {
    code: 6514,
    desc: "Operators of Dwellings Other Than Apartment Buildings",
  },
  {
    code: 6515,
    desc: "Operators of Residential Mobile Home Sites",
  },
  {
    code: 6517,
    desc: "Lessors of Railroad Property",
  },
  {
    code: 6519,
    desc: "Lessors of Real Property, NEC",
  },
  {
    code: 6531,
    desc: "Real Estate Agents and Managers",
  },
  {
    code: 6541,
    desc: "Title Abstract Offices",
  },
  {
    code: 6552,
    desc: "Land Subdividers and Developers, Except Cemeteries",
  },
  {
    code: 6553,
    desc: "Cemetery Subdividers and Developers",
  },
  {
    code: 6712,
    desc: "Offices of Bank Holding Companies",
  },
  {
    code: 6719,
    desc: "Offices of Holding Companies, NEC",
  },
  {
    code: 6722,
    desc: "Management Investment Offices, Open-End",
  },
  {
    code: 6726,
    desc: "Unit Investment Trusts, Face-Amount Certificate Offices, and Closed-End Management Investment Offices",
  },
  {
    code: 6732,
    desc: "Education, Religious, and Charitable Trusts",
  },
  {
    code: 6733,
    desc: "Trusts, Except Educational, Religious, and Charitable",
  },
  {
    code: 6792,
    desc: "Oil Royalty Traders",
  },
  {
    code: 6794,
    desc: "Patent Owners and Lessors",
  },
  {
    code: 6798,
    desc: "Real Estate Investment Trusts",
  },
  {
    code: 6799,
    desc: "Investors, NEC",
  },
  {
    code: 7011,
    desc: "Hotels and Motels",
  },
  {
    code: 7021,
    desc: "Rooming and Boarding Houses",
  },
  {
    code: 7032,
    desc: "Sporting and Recreational Camps",
  },
  {
    code: 7033,
    desc: "Recreational Vehicle Parks and Campsites",
  },
  {
    code: 7041,
    desc: "Organization Hotels and Lodging Houses, on Membership Basis",
  },
  {
    code: 7211,
    desc: "Power Laundries, Family and Commercial",
  },
  {
    code: 7212,
    desc: "Garment Pressing, and Agents for Laundries",
  },
  {
    code: 7213,
    desc: "Linen Supply",
  },
  {
    code: 7215,
    desc: "Coin-Operated Laundry and Drycleaning",
  },
  {
    code: 7216,
    desc: "Drycleaning Plants, Except Rug Cleaning",
  },
  {
    code: 7217,
    desc: "Carpet and Upholstery Cleaning",
  },
  {
    code: 7218,
    desc: "Industrial Launderers",
  },
  {
    code: 7219,
    desc: "Laundry and Garment Services, NEC",
  },
  {
    code: 7221,
    desc: "Photographic Studios, Portrait",
  },
  {
    code: 7231,
    desc: "Beauty Shops",
  },
  {
    code: 7241,
    desc: "Barber Shops",
  },
  {
    code: 7251,
    desc: "Shoe Repair Shops and Shoeshine Parlors",
  },
  {
    code: 7261,
    desc: "Funeral Services and Crematories",
  },
  {
    code: 7291,
    desc: "Tax Return Preparation Services",
  },
  {
    code: 7299,
    desc: "Miscellaneous Personal Services, NEC",
  },
  {
    code: 7311,
    desc: "Advertising Agencies",
  },
  {
    code: 7312,
    desc: "Outdoor Advertising Services",
  },
  {
    code: 7313,
    desc: "Radio, Television, and Publishers' Advertising Representatives",
  },
  {
    code: 7319,
    desc: "Advertising, NEC",
  },
  {
    code: 7322,
    desc: "Adjustment and Collection Services",
  },
  {
    code: 7323,
    desc: "Credit Reporting Services",
  },
  {
    code: 7331,
    desc: "Direct Mail Advertising Services",
  },
  {
    code: 7334,
    desc: "Photocopying and Duplicating Services",
  },
  {
    code: 7335,
    desc: "Commercial Photography",
  },
  {
    code: 7336,
    desc: "Commercial Art and Graphic Design",
  },
  {
    code: 7338,
    desc: "Secretarial and Court Reporting Services",
  },
  {
    code: 7342,
    desc: "Disinfecting and Pest Control Services",
  },
  {
    code: 7349,
    desc: "Building Cleaning and Maintenance Services, NEC",
  },
  {
    code: 7352,
    desc: "Medical Equipment Rental and Leasing",
  },
  {
    code: 7353,
    desc: "Heavy Construction Equipment Rental and Leasing",
  },
  {
    code: 7359,
    desc: "Equipment Rental and Leasing, NEC",
  },
  {
    code: 7361,
    desc: "Employment Agencies",
  },
  {
    code: 7363,
    desc: "Help Supply Services",
  },
  {
    code: 7371,
    desc: "Computer Programming Services",
  },
  {
    code: 7372,
    desc: "Prepackaged Software",
  },
  {
    code: 7373,
    desc: "Computer Integrated Systems Design",
  },
  {
    code: 7374,
    desc: "Computer Processing and Data Preparation and Processing Services",
  },
  {
    code: 7375,
    desc: "Information Retrieval Services",
  },
  {
    code: 7376,
    desc: "Computer Facilities Management Services",
  },
  {
    code: 7377,
    desc: "Computer Rental and Leasing",
  },
  {
    code: 7378,
    desc: "Computer Maintenance and Repair",
  },
  {
    code: 7379,
    desc: "Computer Related Services, NEC",
  },
  {
    code: 7381,
    desc: "Detective, Guard, and Armored Car Services",
  },
  {
    code: 7382,
    desc: "Security Systems Services",
  },
  {
    code: 7383,
    desc: "News Syndicates",
  },
  {
    code: 7384,
    desc: "Photofinishing Laboratories",
  },
  {
    code: 7389,
    desc: "Business Services, NEC",
  },
  {
    code: 7513,
    desc: "Truck Rental and Leasing, Without Drivers",
  },
  {
    code: 7514,
    desc: "Passenger Car Rental",
  },
  {
    code: 7515,
    desc: "Passenger Car Leasing",
  },
  {
    code: 7519,
    desc: "Utility Trailer and Recreational Vehicle Rental",
  },
  {
    code: 7521,
    desc: "Automobile Parking",
  },
  {
    code: 7532,
    desc: "Top, Body, and Upholstery Repair Shops and Paint Shops",
  },
  {
    code: 7533,
    desc: "Automotive Exhaust System Repair Shops",
  },
  {
    code: 7534,
    desc: "Tire Retreading and Repair Shops",
  },
  {
    code: 7536,
    desc: "Automotive Glass Replacement Shops",
  },
  {
    code: 7537,
    desc: "Automotive Transmission Repair Shops",
  },
  {
    code: 7538,
    desc: "General Automotive Repair Shops",
  },
  {
    code: 7539,
    desc: "Automotive Repair Shops, NEC",
  },
  {
    code: 7542,
    desc: "Carwashes",
  },
  {
    code: 7549,
    desc: "Automotive Services, Except Repair and Carwashes",
  },
  {
    code: 7622,
    desc: "Radio and Television Repair Shops",
  },
  {
    code: 7623,
    desc: "Refrigeration and Air-Conditioning Services and Repair Shops",
  },
  {
    code: 7629,
    desc: "Electrical and Electronic Repair Shops, NEC",
  },
  {
    code: 7631,
    desc: "Watch, Clock, and Jewelry Repair",
  },
  {
    code: 7641,
    desc: "Reupholster and Furniture Repair",
  },
  {
    code: 7692,
    desc: "Welding Repair",
  },
  {
    code: 7694,
    desc: "Armature Rewinding Shops",
  },
  {
    code: 7699,
    desc: "Repair Shops and Related Services, NEC",
  },
  {
    code: 7812,
    desc: "Motion Picture and Video Tape Production",
  },
  {
    code: 7819,
    desc: "Services Allied to Motion Picture Production",
  },
  {
    code: 7822,
    desc: "Motion Picture and Video Tape Distribution",
  },
  {
    code: 7829,
    desc: "Services Allied to Motion Picture Distribution",
  },
  {
    code: 7832,
    desc: "Motion Picture Theaters, Except Drive-Ins.",
  },
  {
    code: 7833,
    desc: "Drive-In Motion Picture Theaters",
  },
  {
    code: 7841,
    desc: "Video Tape Rental",
  },
  {
    code: 7911,
    desc: "Dance Studios, Schools, and Halls",
  },
  {
    code: 7922,
    desc: "Theatrical Producers (Except Motion Picture) and Miscellaneous Theatrical Services",
  },
  {
    code: 7929,
    desc: "Bands, Orchestras, Actors, and Other Entertainers and Entertainment Groups",
  },
  {
    code: 7933,
    desc: "Bowling Centers",
  },
  {
    code: 7941,
    desc: "Professional Sports Clubs and Promoters",
  },
  {
    code: 7948,
    desc: "Racing, Including Track Operations",
  },
  {
    code: 7991,
    desc: "Physical Fitness Facilities",
  },
  {
    code: 7992,
    desc: "Public Golf Courses",
  },
  {
    code: 7993,
    desc: "Coin Operated Amusement Devices",
  },
  {
    code: 7996,
    desc: "Amusement Parks",
  },
  {
    code: 7997,
    desc: "Membership Sports and Recreation Clubs",
  },
  {
    code: 7999,
    desc: "Amusement and Recreation Services, NEC",
  },
  {
    code: 8011,
    desc: "Offices and Clinics of Doctors of Medicine",
  },
  {
    code: 8021,
    desc: "Offices and Clinics of Dentists",
  },
  {
    code: 8031,
    desc: "Offices and Clinics of Doctors of Osteopathy",
  },
  {
    code: 8041,
    desc: "Offices and Clinics of Chiropractors",
  },
  {
    code: 8042,
    desc: "Offices and Clinics of Optometrists",
  },
  {
    code: 8043,
    desc: "Offices and Clinics of Podiatrists",
  },
  {
    code: 8049,
    desc: "Offices and Clinics of Health Practitioners, NEC",
  },
  {
    code: 8051,
    desc: "Skilled Nursing Care Facilities",
  },
  {
    code: 8052,
    desc: "Intermediate Care Facilities",
  },
  {
    code: 8059,
    desc: "Nursing and Personal Care Facilities, NEC",
  },
  {
    code: 8062,
    desc: "General Medical and Surgical Hospitals",
  },
  {
    code: 8063,
    desc: "Psychiatric Hospitals",
  },
  {
    code: 8069,
    desc: "Specialty Hospitals, Except Psychiatric",
  },
  {
    code: 8071,
    desc: "Medical Laboratories",
  },
  {
    code: 8072,
    desc: "Dental Laboratories",
  },
  {
    code: 8082,
    desc: "Home Health Care Services",
  },
  {
    code: 8092,
    desc: "Kidney Dialysis Centers",
  },
  {
    code: 8093,
    desc: "Specialty Outpatient Facilities, NEC",
  },
  {
    code: 8099,
    desc: "Health and Allied Services, NEC",
  },
  {
    code: 8111,
    desc: "Legal Services",
  },
  {
    code: 8211,
    desc: "Elementary and Secondary Schools",
  },
  {
    code: 8221,
    desc: "Colleges, Universities, and Professional Schools",
  },
  {
    code: 8222,
    desc: "Junior Colleges and Technical Institutes",
  },
  {
    code: 8231,
    desc: "Libraries",
  },
  {
    code: 8243,
    desc: "Data Processing Schools",
  },
  {
    code: 8244,
    desc: "Business and Secretarial Schools",
  },
  {
    code: 8249,
    desc: "Vocational Schools, NEC",
  },
  {
    code: 8299,
    desc: "Schools and Educational Services, NEC",
  },
  {
    code: 8322,
    desc: "Individual and Family Social Services",
  },
  {
    code: 8331,
    desc: "Job Training and Vocational Rehabilitation Services",
  },
  {
    code: 8351,
    desc: "Child Day Care Services",
  },
  {
    code: 8361,
    desc: "Residential Care",
  },
  {
    code: 8399,
    desc: "Social Services, NEC",
  },
  {
    code: 8412,
    desc: "Museums and Art Galleries",
  },
  {
    code: 8422,
    desc: "Arboreta and Botanical or Zoological Gardens",
  },
  {
    code: 8611,
    desc: "Business Associations",
  },
  {
    code: 8621,
    desc: "Professional Membership Organizations",
  },
  {
    code: 8631,
    desc: "Labor Unions and Similar Labor Organizations",
  },
  {
    code: 8641,
    desc: "Civic, Social, and Fraternal Associations",
  },
  {
    code: 8651,
    desc: "Political Organizations",
  },
  {
    code: 8661,
    desc: "Religious Organizations",
  },
  {
    code: 8699,
    desc: "Membership Organizations, NEC",
  },
  {
    code: 8711,
    desc: "Engineering Services",
  },
  {
    code: 8712,
    desc: "Architectural Services",
  },
  {
    code: 8713,
    desc: "Surveying Services",
  },
  {
    code: 8721,
    desc: "Accounting, Auditing, and Bookkeeping Services",
  },
  {
    code: 8731,
    desc: "Commercial Physical and Biological Research",
  },
  {
    code: 8732,
    desc: "Commercial Economic, Sociological, and Educational Research",
  },
  {
    code: 8733,
    desc: "Noncommercial Research Organizations",
  },
  {
    code: 8734,
    desc: "Testing Laboratories",
  },
  {
    code: 8741,
    desc: "Management Services",
  },
  {
    code: 8742,
    desc: "Management Consulting Services",
  },
  {
    code: 8743,
    desc: "Public Relations Services",
  },
  {
    code: 8744,
    desc: "Facilities Support Management Services",
  },
  {
    code: 8748,
    desc: "Business Consulting Services, NEC",
  },
  {
    code: 8811,
    desc: "Private Households",
  },
  {
    code: 8999,
    desc: "Services, NEC",
  },
  {
    code: 9111,
    desc: "Executive Offices",
  },
  {
    code: 9121,
    desc: "Legislative Bodies",
  },
  {
    code: 9131,
    desc: "Executive and Legislative Offices, Combined",
  },
  {
    code: 9199,
    desc: "General Government, NEC",
  },
  {
    code: 9211,
    desc: "Courts",
  },
  {
    code: 9220,
    desc: "Public Order and Safety",
  },
  {
    code: 9221,
    desc: "Police Protection",
  },
  {
    code: 9222,
    desc: "Legal Counsel and Prosecution",
  },
  {
    code: 9223,
    desc: "Correctional Institutions",
  },
  {
    code: 9224,
    desc: "Fire Protection",
  },
  {
    code: 9229,
    desc: "Public Order and Safety, NEC",
  },
  {
    code: 9311,
    desc: "Public Finance, Taxation, and Monetary Policy",
  },
  {
    code: 9411,
    desc: "Administration of Educational Programs",
  },
  {
    code: 9431,
    desc: "Administration of Public Health Programs",
  },
  {
    code: 9441,
    desc: "Administration of Social, Human Resource and Income Maintenance Programs",
  },
  {
    code: 9451,
    desc: "Administration of Veteran's Affairs, Except Health Insurance",
  },
  {
    code: 9511,
    desc: "Air and Water Resource and Solid Waste Management",
  },
  {
    code: 9512,
    desc: "Land, Mineral, Wildlife, and Forest Conservation",
  },
  {
    code: 9531,
    desc: "Administration of Housing Programs",
  },
  {
    code: 9532,
    desc: "Administration of Urban Planning and Community and Rural Development",
  },
  {
    code: 9611,
    desc: "Administration of General Economic Programs",
  },
  {
    code: 9621,
    desc: "Regulations and Administration of Transportation Programs",
  },
  {
    code: 9631,
    desc: "Regulation and Administration of Communications, Electric, Gas, and Other Utilities",
  },
  {
    code: 9641,
    desc: "Regulation of Agricultural Marketing and Commodity",
  },
  {
    code: 9651,
    desc: "Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors",
  },
  {
    code: 9661,
    desc: "Space Research and Technology",
  },
  {
    code: 9711,
    desc: "National Security",
  },
  {
    code: 9721,
    desc: "International Affairs",
  },
  {
    code: 9999,
    desc: "Nonclassifiable Establishments",
  },
].map((v) => {
  return { value: v.code, label: `${v.code} - ${v.desc}` };
});

import React, {useState, useEffect} from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography'
import {RaterContext} from "../../RaterV3";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import WarningModal from "../../Modals/WarningModal";
import {numberTest} from "../../../utils/numberTest";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        '&:hover': {
            cursor: 'pointer',
            border: `2px solid ${theme.palette.primary.main}`
        }
    },
    link : {
        padding: theme.spacing(2),
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.primary.main
        }
    }
}))

export default function TieredCensus() {
    const classes = useStyles()
    const [hasSeenWarning, setHasSeenWarning] = React.useState(false)
    const [showWarning, setShowWarning] = React.useState(false)
    const [hasParsed, setHasParsed] = React.useState(false)
    const [insuredOnly, setInsuredOnly] = useState({under40: 0, fortyToFortyNine: 0, fiftyPlus: 0, total: 0})
    const [insuredPlusOne, setInsuredPlusOne] = useState({under40: 0, fortyToFortyNine: 0, fiftyPlus: 0, total: 0})
    const [insuredSpouse, setInsuredSpouse] = useState({under40: 0, fortyToFortyNine: 0, fiftyPlus: 0, total: 0})
    const [insuredChildren, setInsuredChildren] = useState({under40: 0, fortyToFortyNine: 0, fiftyPlus: 0, total: 0})
    const [insuredFamily, setInsuredFamily] = useState({under40: 0, fortyToFortyNine: 0, fiftyPlus: 0, total: 0})
    const {setGapCensus, total, setTotal, setFourTierCensus, tier, setTier, gapCensus, census} = React.useContext(RaterContext)




React.useEffect(() => {
}, [census]);


    //Function that sets the value of the corresponding box inside of the census
    const setValue = (e, value, setValue, target) => {
        if (e.target.value > -1) {
            let holder = {...value}
            holder[target] = e.target.value
            holder.total = (+holder.under40) + (+holder.fortyToFortyNine) + (+holder.fiftyPlus)
            setValue(holder)
        }
    }


    //This useEffect calculates the sum in order to get an accurate total anytime that a value or tier is changed.
    useEffect(() => {
        let sum = 0
        list.forEach((item) => {
            if (item.tiers.indexOf(tier) !== -1) {
                sum = sum + item.values.total
            }
        })
        setTotal(sum)
    }, [insuredOnly, insuredSpouse, insuredChildren, insuredFamily, insuredPlusOne, tier])

    React.useEffect(() => {
        setHasParsed(false)
    }, [census]);


    // console.log("THIS IS GAP CENSUS")
    // console.log(gapCensus)
    //This useEffect parses the gapCensus if it hasn't been parsed already in order to fill out the boxes on the initial mounting of this component.
    React.useEffect(() => {
        if (!hasParsed) {
           
            setHasParsed(true)
            if (gapCensus && gapCensus.underForty) {
                setInsuredPlusOne({
                    under40: gapCensus.underForty.insuredPlusOne ? gapCensus.underForty.insuredPlusOne : 6,
                    fortyToFortyNine: gapCensus.fortyToFortyNine.insuredPlusOne ? gapCensus.fortyToFortyNine.insuredPlusOne : 6,
                    fiftyPlus: gapCensus.fiftyPlus.insuredPlusOne ? gapCensus.fiftyPlus.insuredPlusOne : 6,
                    total: gapCensus.fiftyPlus.insuredPlusOne ? +gapCensus.underForty.insuredPlusOne + +gapCensus.fortyToFortyNine.insuredPlusOne + +gapCensus.fiftyPlus.insuredPlusOne : 6
                })
                setInsuredChildren({
                    under40: gapCensus.underForty.insuredPlusChildren || 0,
                    fortyToFortyNine: gapCensus.fortyToFortyNine.insuredPlusChildren || 0,
                    fiftyPlus: gapCensus.fiftyPlus.insuredPlusChildren || 0,
                    total: gapCensus.underForty.insuredPlusChildren + gapCensus.fortyToFortyNine.insuredPlusChildren + gapCensus.fiftyPlus.insuredPlusChildren || 0
                })
                setInsuredOnly({
                    under40: gapCensus.underForty.insured || 0,
                    fortyToFortyNine: gapCensus.fortyToFortyNine.insured || 0,
                    fiftyPlus: gapCensus.fiftyPlus.insured || 0,
                    total: gapCensus.underForty.insured + gapCensus.fortyToFortyNine.insured + gapCensus.fiftyPlus.insured  || 0
                })
                setInsuredSpouse({
                    under40: gapCensus.underForty.insuredPlusSpouse || 0,
                    fortyToFortyNine: gapCensus.fortyToFortyNine.insuredPlusSpouse || 0,
                    fiftyPlus: gapCensus.fiftyPlus.insuredPlusSpouse || 0,
                    total: gapCensus.underForty.insuredPlusSpouse + gapCensus.fortyToFortyNine.insuredPlusSpouse + gapCensus.fiftyPlus.insuredPlusSpouse || 0
                })
                setInsuredFamily({
                    under40: gapCensus.underForty.insuredPlusFamily || 0,
                    fortyToFortyNine: gapCensus.fortyToFortyNine.insuredPlusFamily || 0,
                    fiftyPlus: gapCensus.fiftyPlus.insuredPlusFamily || 0,
                    total: gapCensus.underForty.insuredPlusFamily + gapCensus.fortyToFortyNine.insuredPlusFamily + gapCensus.fiftyPlus.insuredPlusFamily || 0
                })
            }
        }
    }, [gapCensus])

    //Sets the census total of the parent component any time the internal total changes.


    const list = [
            {name: 'Insured Only', values: insuredOnly, setter: setInsuredOnly, tiers: [2, 3, 4]},
            {name: 'Insured Plus Spouse', values: insuredSpouse, setter: setInsuredSpouse, tiers: [4]},
            {name: 'Insured Plus Children', values: insuredChildren, setter: setInsuredChildren, tiers: [4]},
            {name: 'Insured Plus One', values: insuredPlusOne, setter: setInsuredPlusOne, tiers: [3]},
            {name: 'Insured Plus Family', values: insuredFamily, setter: setInsuredFamily, tiers: [2, 3, 4]},
        ]



    useEffect(() => {
        let tierCensusData = {
            'fiftyPlus': {
                'insured': +(insuredOnly.fiftyPlus),
                'insuredPlusSpouse': +(insuredSpouse.fiftyPlus),
                'insuredPlusChildren': +(insuredChildren.fiftyPlus),
                'insuredPlusFamily': +(insuredFamily.fiftyPlus),
                'insuredPlusOne': +(insuredPlusOne.fiftyPlus)
            },
            'fortyToFortyNine': {
                'insured': +(insuredOnly.fortyToFortyNine),
                'insuredPlusSpouse': +(insuredSpouse.fortyToFortyNine),
                'insuredPlusChildren': +(insuredChildren.fortyToFortyNine),
                'insuredPlusFamily': +(insuredFamily.fortyToFortyNine),
                'insuredPlusOne': +(insuredPlusOne.fortyToFortyNine)
            },
            'underForty': {
                'insured': +(insuredOnly.under40),
                'insuredPlusSpouse': +(insuredSpouse.under40),
                'insuredPlusChildren': +(insuredChildren.under40),
                'insuredPlusFamily': +(insuredFamily.under40),
                'insuredPlusOne': +(insuredPlusOne.under40)
            }
        }
        let censusData = {}
        //Switch Statement is used to filter the Census Data in order to only send the proper data to the Rating Engine
        switch (tier) {
            case 2:
                censusData = {
                    'fiftyPlus': {
                        'insured': +(insuredOnly.fiftyPlus),
                        'insuredPlusSpouse': 0,
                        'insuredPlusChildren': 0,
                        'insuredPlusFamily': +(insuredFamily.fiftyPlus),
                    },
                    'fortyToFortyNine': {
                        'insured': +(insuredOnly.fortyToFortyNine),
                        'insuredPlusSpouse': 0,
                        'insuredPlusChildren': 0,
                        'insuredPlusFamily': +(insuredFamily.fortyToFortyNine),
                    },
                    'underForty': {
                        'insured': +(insuredOnly.under40),
                        'insuredPlusSpouse': 0,
                        'insuredPlusChildren': 0,
                        'insuredPlusFamily': +(insuredFamily.under40),
                    }
                }
                delete tierCensusData['fiftyPlus']['insuredPlusChildren']
                delete tierCensusData['fortyToFortyNine']['insuredPlusChildren']
                delete tierCensusData['underForty']['insuredPlusChildren']
                delete tierCensusData['fiftyPlus']['insuredPlusSpouse']
                delete tierCensusData['fortyToFortyNine']['insuredPlusSpouse']
                delete tierCensusData['underForty']['insuredPlusSpouse']
                delete tierCensusData['fiftyPlus']['insuredPlusOne']
                delete tierCensusData['fortyToFortyNine']['insuredPlusOne']
                delete tierCensusData['underForty']['insuredPlusOne']
                break;
            case 3:
                censusData = {
                    'fiftyPlus': {
                        'insured': +(insuredOnly.fiftyPlus),
                        'insuredPlusSpouse': +(insuredPlusOne.fiftyPlus),
                        'insuredPlusChildren': 0,
                        'insuredPlusFamily': +(insuredFamily.fiftyPlus),
                    },
                    'fortyToFortyNine': {
                        'insured': +(insuredOnly.fortyToFortyNine),
                        'insuredPlusSpouse': +(insuredPlusOne.fiftyPlus),
                        'insuredPlusChildren': 0,
                        'insuredPlusFamily': +(insuredFamily.fortyToFortyNine),
                    },
                    'underForty': {
                        'insured': +(insuredOnly.under40),
                        'insuredPlusSpouse': insuredPlusOne.fiftyPlus,
                        'insuredPlusChildren': 0,
                        'insuredPlusFamily': +(insuredFamily.under40),
                    }
                }
                delete tierCensusData['fiftyPlus']['insuredPlusChildren']
                delete tierCensusData['fortyToFortyNine']['insuredPlusChildren']
                delete tierCensusData['underForty']['insuredPlusChildren']
                delete tierCensusData['fiftyPlus']['insuredPlusSpouse']
                delete tierCensusData['fortyToFortyNine']['insuredPlusSpouse']
                delete tierCensusData['underForty']['insuredPlusSpouse']
                break;
            case 4:
                delete tierCensusData['fiftyPlus']['insuredPlusOne']
                delete tierCensusData['fortyToFortyNine']['insuredPlusOne']
                delete tierCensusData['underForty']['insuredPlusOne']
                censusData = {...tierCensusData}
                break;
            default:
                break;
        }
        setGapCensus(tierCensusData)
        setFourTierCensus(censusData)
    },[insuredOnly, insuredFamily, insuredChildren, insuredSpouse, insuredPlusOne, tier])


    const setNewTier = (newTier) => {
        setTier(newTier)
        if (!hasSeenWarning && !showWarning && tier !== 4) {
            setShowWarning(true)
            setHasSeenWarning(true)
        }
    }

    return (

        <Grid container justify={'center'} spacing={2}>
            <Grid container spacing={2} item xs={12} justify={'center'}>
                <Grid item xs={12}>
                    <Typography align={'center'}>
                        Select your tier
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Paper className={classes.paper} elevation={tier === 4 ? 10:2} onClick={() => setNewTier(4)} style={{border: tier === 4 ? '2px solid darkblue':''}}>
                        <Typography align={'center'} style={{color:'#0C2659'}}>4</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper className={classes.paper} elevation={tier === 3 ? 10:2} onClick={() => setNewTier(3)} style={{border: tier === 3 ? '2px solid darkblue':''}}>
                        <Typography align={'center'} style={{color:'#0C2659'}}>3</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper className={classes.paper} elevation={tier === 2 ? 10:2} onClick={() => setNewTier(2)} style={{border: tier === 2 ? '2px solid darkblue':''}}>
                        <Typography align={'center'} style={{color:'#0C2659'}}>2</Typography>
                    </Paper>

                </Grid>
                <Grid item xs={3}>

                </Grid>
                <Grid item xs={2}>
                    <Typography align={'center'}>
                        Under 40
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography align={'center'}>
                        40-49
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography align={'center'}>
                        Above 50
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography align={'center'}>
                        Totals
                    </Typography>
                </Grid>
                {list.map((item) => {
                    if (item.tiers.indexOf(tier) !== -1) {
                        return (
                            <React.Fragment key={item.name}>
                                <Grid item xs={3}>
                                    {item.name}
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField fullWidth variant={'outlined'} type="number" onKeyDown={numberTest} value={item.values.under40} onChange={e => setValue(e, item.values, item.setter, 'under40')}/>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField fullWidth variant={'outlined'} type="number" onKeyDown={numberTest} value={item.values.fortyToFortyNine} onChange={e => setValue(e, item.values, item.setter, 'fortyToFortyNine')}/>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField fullWidth variant={'outlined'} type="number" onKeyDown={numberTest} value={item.values.fiftyPlus} onChange={e => setValue(e, item.values, item.setter, 'fiftyPlus')}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography align={'center'}>
                                        {item.values.total}
                                    </Typography>
                                </Grid>
                            </React.Fragment>
                        )
                    }
                })}
                <Grid item xs={12}>
                    <Typography>
                        Total: {total}
                    </Typography>
                </Grid>
            </Grid>
            <WarningModal open={showWarning} close={() => setShowWarning(false)}/>
        </Grid>
    )
}


import axios from "axios";
import React from "react";
import { shelterPayload, zurichPayload, markelPayload, chubbPayload, nationwidePayload } from "./payloads";
import { replacePayload, replaceChubbPayload } from "./functions";
import { RaterContext } from "../RaterV3";

const constructRequest = (census, payload) => {
  return {
    censusData: census,
    inputData: {
      ...payload,
    },
  };
};

// const {
//   ihpBrokerFee,
//   setIHPBrokerFee,
//   shelterPayloadTracker,
//   setSheltPayload,
//   nationPayloadTracker,
//   setNationPayload,
//   zurichPayloadTracker,
//   setZurPayload,
//   markelPayloadTracker,
//   setMarkelPayload,
//   chubbPayloadTracker,
//   setChubbPayload,
//   IHPPayloadTracker,
//   setIHPPayload,
//   isQQ,
//   setQQ,
// } = React.useContext(RaterContext);

// Handles the API request for each provider availible in step 5 and returns created object to save
const getQuickQuotes = async (
  providers,
  header,
  { updatedPayloadData, setSheltPayload, setNationPayload, setZurPayload, setMarkelPayload, setChubbPayload, setQQ }
) => {
  const data = [];

  // const {
  //   ihpBrokerFee,
  //   setIHPBrokerFee,
  //   shelterPayloadTracker,
  //   setSheltPayload,
  //   nationPayloadTracker,
  //   setNationPayload,
  //   zurichPayloadTracker,
  //   setZurPayload,
  //   markelPayloadTracker,
  //   setMarkelPayload,
  //   chubbPayloadTracker,
  //   setChubbPayload,
  //   IHPPayloadTracker,
  //   setIHPPayload,
  //   isQQ,
  //   setQQ,
  // } = React.useContext(RaterContext);
  setQQ(true);
  for (const prov in providers) {
    const provider = providers[prov];
    console.log("REQUEST FOR: " + provider);

    // switch cases update the payload being sent with what is selected in step 4
    let payloadToSend = {};
    switch (provider) {
      case "shelter":
        console.log("ACTIVATING SHELTER HERE!!!!");
        payloadToSend = {
          ...shelterPayload,
          sicCode: updatedPayloadData.sicCode,
          groupEffectiveDate: updatedPayloadData.groupEffectiveDate,
          state: updatedPayloadData.state,
        };
        payloadToSend = replacePayload(
          payloadToSend,
          updatedPayloadData.quickQuoteDeductible,
          updatedPayloadData.quickQuoteCombinedBenefit
        );
        setSheltPayload(payloadToSend);
        break;
      case "nationwide":
        payloadToSend = {
          ...nationwidePayload,
          eligibleEmployees: updatedPayloadData.censusTotal,
          sicCode: updatedPayloadData.sicCode,
          groupEffectiveDate: updatedPayloadData.groupEffectiveDate,
          state: updatedPayloadData.state,
        };
        payloadToSend = replacePayload(
          payloadToSend,
          updatedPayloadData.quickQuoteDeductible,
          updatedPayloadData.quickQuoteCombinedBenefit
        );
        setNationPayload(payloadToSend);
        break;
      case "zurich":
        payloadToSend = {
          ...zurichPayload,
          sicCode: updatedPayloadData.sicCode,
          groupEffectiveDate: updatedPayloadData.groupEffectiveDate,
          state: updatedPayloadData.state,
        };
        payloadToSend = replacePayload(
          payloadToSend,
          updatedPayloadData.quickQuoteDeductible,
          updatedPayloadData.quickQuoteCombinedBenefit
        );
        setZurPayload(payloadToSend);
        break;
      case "markel":
        payloadToSend = {
          ...markelPayload,
          sicCode: updatedPayloadData.sicCode,
          groupEffectiveDate: updatedPayloadData.groupEffectiveDate,
          state: updatedPayloadData.state,
        };
        payloadToSend = replacePayload(
          payloadToSend,
          updatedPayloadData.quickQuoteDeductible,
          updatedPayloadData.quickQuoteCombinedBenefit
        );
        setMarkelPayload(payloadToSend);
        break;
      case "chubb":
        payloadToSend = {
          ...chubbPayload,
          sicCode: updatedPayloadData.sicCode,
          groupEffectiveDate: updatedPayloadData.groupEffectiveDate,
          state: updatedPayloadData.state,
        };
        payloadToSend = replaceChubbPayload(
          payloadToSend,
          updatedPayloadData.quickQuoteDeductible,
          updatedPayloadData.quickQuoteCombinedBenefit
        );
        setChubbPayload(payloadToSend);
        break;
      default:
        payloadToSend = {
          ...chubbPayload,
          sicCode: updatedPayloadData.sicCode,
          groupEffectiveDate: updatedPayloadData.groupEffectiveDate,
          state: updatedPayloadData.state,
        };
        break;
    }

    const dataToSend = constructRequest(updatedPayloadData.census, payloadToSend);
    // console.log("CHECKING GLOBAL VARIABLES");
    // console.log(shelterPayloadTracker);
    // console.log(zurichPayloadTracker);
    // console.log(chubbPayloadTracker);
    // console.log(markelPayloadTracker);
    // console.log(nationPayloadTracker);

    // To test with a local provider, replace url with the one below
    // http://localhost:3001/rating-engine/v1/multiRates/${provider}?plan=combined_benefit

    // prod url
    // https://secure.dev.optimedhealth.com/rating/rates/${provider}?plan=combined_benefit

    console.log("this is data to send");
    console.log(dataToSend);
    let requestUrl = `https://secure.optimedhealth.com/rating/rates/${provider}?plan=combined_benefit`;
    if (process.env.IS_DEVELOPMENT === 1) {
      requestUrl = `https://secure.dev.optimedhealth.com/rating/rates/${provider}?plan=combined_benefit`;
    }

    const reqestData = await axios({
      url: requestUrl,

      method: "POST",
      data: dataToSend,
      headers: header,
      timeout: 25000,
      responseType: "json",
    })
      .then((res) => {
        console.log("API REQUEST RESPONSE DATA");
        console.log(res);
        return res.data.response;
      })
      .catch((error) => {
        console.error("Error making the request: ", error);
        if (error.response) {
          // The request was made and the server responded with a status code
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
        }
        throw error;
      });
    // some providers don't have a carrier field so the carrier is manually incerted into the object
    if (!reqestData.hasOwnProperty(reqestData.carrier)) {
      reqestData.carrier = provider;
    }
    data.push(reqestData);
  }
  console.log("DATA BEING PUSHED TO SAVE QQs");
  console.log(data);
  return data;
};

export { getQuickQuotes };

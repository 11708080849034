// The deductible and combined benifit values had to be hardcoded as there was an issue with the
// way in which scala interperated javascript numbers. A dynamic solution was tried and tested but this
// was the solution that worked for now.

function replacePayload(payload, deductible, combinedBenefit) {
  let updatedPayload = payload;

  switch (deductible) {
    case "0":
      updatedPayload = {
        ...updatedPayload,
        deductible: 0,
      };
      break;
    case "250":
      updatedPayload = {
        ...updatedPayload,
        deductible: 250,
      };
      break;
    case "500":
      updatedPayload = {
        ...updatedPayload,
        deductible: 500,
      };
      break;
    case "750":
      updatedPayload = {
        ...updatedPayload,
        deductible: 750,
      };
      break;
    case "1000":
      updatedPayload = {
        ...updatedPayload,
        deductible: 1000,
      };
      break;
    case "1500":
      updatedPayload = {
        ...updatedPayload,
        deductible: 1500,
      };
      break;
    case "2000":
      updatedPayload = {
        ...updatedPayload,
        deductible: 2000,
      };
      break;
    case "2500":
      updatedPayload = {
        ...updatedPayload,
        deductible: 2500,
      };
      break;
    case "3000":
      updatedPayload = {
        ...updatedPayload,
        deductible: 3000,
      };
      break;
    case "3500":
      updatedPayload = {
        ...updatedPayload,
        deductible: 3500,
      };
      break;
    case "4000":
      updatedPayload = {
        ...updatedPayload,
        deductible: 4000,
      };
      break;
  }

  switch (combinedBenefit) {
    case "500":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 500,
      };
      break;
    case "1000":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 1000,
      };
      break;
    case "1500":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 1500,
      };
      break;
    case "2000":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 2000,
      };
      break;
    case "2500":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 2500,
      };
      break;
    case "3000":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 3000,
      };
      break;
    case "3500":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 3500,
      };
      break;
    case "4000":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 4000,
      };
      break;
    case "4500":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 4500,
      };
      break;
    case "5000":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 5000,
      };
      break;
    case "5500":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 5500,
      };
      break;
    case "6000":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 6000,
      };
      break;
    case "6500":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 6500,
      };
      break;
    case "7000":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 7000,
      };
      break;
  }

  console.log("UPDATED PAYLOAD");
  console.log(updatedPayload);

  return updatedPayload;
}

// Chubb has a different name for deductible so a function had to be made to work around that.
// More conditionals could have been made instead but this was a faster way

function replaceChubbPayload(payload, deductible, combinedBenefit) {
  let updatedPayload = payload;

  switch (deductible) {
    case "0":
      updatedPayload = {
        ...updatedPayload,
        ipOpDeductible: 0,
      };
      break;
    case "250":
      updatedPayload = {
        ...updatedPayload,
        ipOpDeductible: 250,
      };
      break;
    case "500":
      updatedPayload = {
        ...updatedPayload,
        ipOpDeductible: 500,
      };
      break;
    case "750":
      updatedPayload = {
        ...updatedPayload,
        ipOpDeductible: 750,
      };
      break;
    case "1000":
      updatedPayload = {
        ...updatedPayload,
        ipOpDeductible: 1000,
      };
      break;
    case "1500":
      updatedPayload = {
        ...updatedPayload,
        ipOpDeductible: 1500,
      };
      break;
    case "2000":
      updatedPayload = {
        ...updatedPayload,
        ipOpDeductible: 2000,
      };
      break;
    case "2500":
      updatedPayload = {
        ...updatedPayload,
        ipOpDeductible: 2500,
      };
      break;
    case "3000":
      updatedPayload = {
        ...updatedPayload,
        ipOpDeductible: 3000,
      };
      break;
    case "3500":
      updatedPayload = {
        ...updatedPayload,
        ipOpDeductible: 3500,
      };
      break;
    case "4000":
      updatedPayload = {
        ...updatedPayload,
        ipOpDeductible: 4000,
      };
      break;
  }

  switch (combinedBenefit) {
    case "500":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 500,
      };
      break;
    case "1000":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 1000,
      };
      break;
    case "1500":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 1500,
      };
      break;
    case "2000":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 2000,
      };
      break;
    case "2500":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 2500,
      };
      break;
    case "3000":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 3000,
      };
      break;
    case "3500":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 3500,
      };
      break;
    case "4000":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 4000,
      };
      break;
    case "4500":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 4500,
      };
      break;
    case "5000":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 5000,
      };
      break;
    case "5500":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 5500,
      };
      break;
    case "6000":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 6000,
      };
      break;
    case "6500":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 6500,
      };
      break;
    case "7000":
      updatedPayload = {
        ...updatedPayload,
        combinedBenefit: 7000,
      };
      break;
  }

  console.log("UPDATED PAYLOAD");
  console.log(updatedPayload);

  return updatedPayload;
}

function replacePayloadIHP(payload, brokerFee) {
  let updatedPayload = payload;

  switch (brokerFee) {
    case "0":
      updatedPayload = {
        ...updatedPayload,
        deductible: 0,
      };
      break;
    case "5":
      updatedPayload = {
        ...updatedPayload,
        deductible: 5,
      };
      break;
    case "10":
      updatedPayload = {
        ...updatedPayload,
        deductible: 10,
      };
      break;
    case "15":
      updatedPayload = {
        ...updatedPayload,
        deductible: 15,
      };
      break;
    case "20":
      updatedPayload = {
        ...updatedPayload,
        deductible: 20,
      };
      break;
    case "25":
      updatedPayload = {
        ...updatedPayload,
        deductible: 25,
      };
      break;
    case "30":
      updatedPayload = {
        ...updatedPayload,
        deductible: 30,
      };
      break;
    case "35":
      updatedPayload = {
        ...updatedPayload,
        deductible: 35,
      };
      break;
    case "40":
      updatedPayload = {
        ...updatedPayload,
        deductible: 40,
      };
      break;
    case "55":
      updatedPayload = {
        ...updatedPayload,
        deductible: 55,
      };
      break;
    case "60":
      updatedPayload = {
        ...updatedPayload,
        deductible: 60,
      };
      break;
    case "65":
      updatedPayload = {
        ...updatedPayload,
        deductible: 65,
      };
      break;
    case "70":
      updatedPayload = {
        ...updatedPayload,
        deductible: 70,
      };
      break;
    case "75":
      updatedPayload = {
        ...updatedPayload,
        deductible: 75,
      };
      break;
    case "80":
      updatedPayload = {
        ...updatedPayload,
        deductible: 80,
      };
      break;
    case "85":
      updatedPayload = {
        ...updatedPayload,
        deductible: 85,
      };
      break;
    case "90":
      updatedPayload = {
        ...updatedPayload,
        deductible: 90,
      };
      break;
    case "95":
      updatedPayload = {
        ...updatedPayload,
        deductible: 95,
      };
      break;
    case "100":
      updatedPayload = {
        ...updatedPayload,
        deductible: 100,
      };
      break;
  }

  console.log("UPDATED PAYLOAD");
  console.log(updatedPayload);

  return updatedPayload;
}

export { replacePayload, replaceChubbPayload, replacePayloadIHP };

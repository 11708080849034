import {Grid, Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import React from "react";

export default function CFLRates(props) {

    const fees = props.fees || 0
    const rate = (props.data.involuntaryRates || props.data.voluntaryRates).per100WeeklyBenefit

    return (
        <>
            <Grid item xs={6}>
                <Typography>Annual Per $100 Weekly Benefit</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant={'body2'} color={'primary'} align={'right'}>
                    ${(rate + fees).toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </>
    )
}
import {Grid, Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import React from "react";

export default function LTD(props) {
    let rates = props.data.ratesPerHundredDollars ? props.data.ratesPerHundredDollars : ''
    return (
        <>
            <Grid item xs={6}>
                <Typography>
                    Age Range
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography align={'right'}>
                    Rate Per Hundred Dollars
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={6}>
                <Typography>
                    {'< 25'}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color={'#0C2659'} align={'right'}>
                    ${rates.lessThen25 ? rates.lessThen25.toFixed(2): ''}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={6}>
                <Typography>
                    {'25-29'}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color={'#0C2659'} align={'right'}>
                    ${rates['25-29'].toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={6}>
                <Typography>
                    {'30-34'}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color={'#0C2659'} align={'right'}>
                    ${rates['30-34'].toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={6}>
                <Typography>
                    {'35-39'}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color={'#0C2659'} align={'right'}>
                    ${rates['35-39'].toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={6}>
                <Typography>
                    {'40-44'}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color={'#0C2659'} align={'right'}>
                    ${rates['40-44'] ? rates['40-44'].toFixed(2):''}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={6}>
                <Typography>
                    {'45-49'}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color={'#0C2659'} align={'right'}>
                    ${rates['45-49'].toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={6}>
                <Typography>
                    {'50-54'}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color={'#0C2659'} align={'right'}>
                    ${rates['50-54'].toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={6}>
                <Typography>
                    {'55-59'}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color={'#0C2659'} align={'right'}>
                    ${rates['55-59'].toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={6}>
                <Typography>
                    {'60-64'}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color={'#0C2659'} align={'right'}>
                    ${rates['60-64'].toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={6}>
                <Typography>
                    {'65+'}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color={'primary'} align={'right'}>
                    ${rates['65+'].toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
        </>
    )

}

import {Grid, Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import React from "react";

export default function MajorMedical(props) {
    let miscOnly = props.data.miscFees.insuredOnly || 0
    let miscChildren = props.data.miscFees.insuredPlusChildren || 0
    let miscSpouse = props.data.miscFees.insuredPlusSpouse || 0
    let miscFamily = props.data.miscFees.insuredPlusFamily || 0
    return (

        <>
            <Grid item xs={3}>
                <Typography align={'left'} variant={'body2'}>
                    Claims Funding
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'center'} variant={'body2'}>
                    Agg. Premium
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'center'} variant={'body2'}>
                    Admin Fees
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'center'} variant={'body2'}>
                    Total Cost
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={12}>
                <Typography>Insured Only</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'left'} style={{color:'#0C2659'}} variant={'body2'}>
                    ${props.data.claimsFunding.insuredOnly.toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'center'} style={{color:'#0C2659'}} variant={'body2'}>
                    ${props.data.aggPremium.insuredOnly.toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'center'} style={{color:'#0C2659'}} variant={'body2'}>
                    ${(props.fees + miscOnly).toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'right'} style={{color:'#0C2659'}} variant={'body2'}>
                    ${(props.data.claimsFunding.insuredOnly + props.data.aggPremium.insuredOnly + miscOnly + props.fees).toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={12}>
                <Typography>Insured Plus Spouse</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'left'} style={{color:'#0C2659'}} variant={'body2'}>
                    ${props.data.claimsFunding.insuredPlusSpouse.toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'center'} style={{color:'#0C2659'}} variant={'body2'}>
                    ${props.data.aggPremium.insuredPlusSpouse.toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'center'} style={{color:'#0C2659'}} variant={'body2'}>
                    ${(props.fees  + miscSpouse).toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'right'} style={{color:'#0C2659'}} variant={'body2'}>
                    ${(props.data.claimsFunding.insuredPlusSpouse + props.data.aggPremium.insuredPlusSpouse + miscSpouse + props.fees).toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={12}>
                <Typography>Insured Plus Children</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'left'} style={{color:'#0C2659'}} variant={'body2'}>
                    ${props.data.claimsFunding.insuredPlusChildren.toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'center'} style={{color:'#0C2659'}} variant={'body2'}>
                    ${props.data.aggPremium.insuredPlusChildren.toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'center'} style={{color:'#0C2659'}} variant={'body2'}>
                    ${(props.fees + miscChildren).toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'right'} style={{color:'#0C2659'}} variant={'body2'}>
                    ${(props.data.claimsFunding.insuredPlusChildren + props.data.aggPremium.insuredPlusChildren +miscChildren+ props.fees).toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>

            <Grid item xs={12}>
                <Typography>Insured Plus Family</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'left'} style={{color:'#0C2659'}} variant={'body2'}>
                    ${props.data.claimsFunding.insuredPlusFamily.toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'center'} style={{color:'#0C2659'}} variant={'body2'}>
                    ${props.data.aggPremium.insuredPlusFamily.toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'center'} style={{color:'#0C2659'}} variant={'body2'}>
                    ${(props.fees + miscFamily).toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography align={'right'} style={{color:'#0C2659'}} variant={'body2'}>
                    ${(props.data.claimsFunding.insuredPlusFamily + props.data.aggPremium.insuredPlusFamily + miscFamily + props.fees).toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>

        </>
    )
}

import React from 'react';
import './App.css';
import RaterV3 from "./components/RaterV3";
import {FirebaseProvider} from 'react-ugp-firebase'

export default function App() {
  return (
      <FirebaseProvider roles={process.env.NODE_ENV === 'development' ? [] : ['rating']}>
              <RaterV3/>
      </FirebaseProvider>
  );
}


import React from 'react'
import Grid from "@material-ui/core/Grid";
import CensusCard from "./CensusCard";

export default function Census() {
    return(
            <Grid container spacing={0} justify={'center'} alignItems={'center'}>
                <Grid item xs={12}>
                    <CensusCard/>
                </Grid>
            </Grid>
    )
}

import { Grid, Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import React from "react";

export default function Standard(props) {
  const fee = props.fees || 0;

  return (
    <>
      {props.data.rates.insuredOnly && (
        <>
          <Grid item xs={6}>
            <Typography>Coverage</Typography>
          </Grid>
          {/* <Grid item xs={3}>
                <Typography align={'right'}>
                    {props.data.rxBoost ? 'RX Boost':''}
                </Typography>
            </Grid> */}
          <Grid item xs={3}>
            <Typography align={"right"}>Total</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Typography>Insured Only</Typography>
          </Grid>
          {/* <Grid item xs={3}>
            <Typography
              style={{ color: "#0C2659" }}
              align={"right"}
              variant={"body2"}
            >
              {props.data.rxBoost
                ? "$" + props.data.rxBoost.insuredOnly.toFixed(2)
                : ""}
            </Typography>
          </Grid> */}
          <Grid item xs={3}>
            <Typography
              style={{ color: "#0C2659" }}
              align={"right"}
              variant={"body2"}
            >
              $
              {props.data.stopLossPremium
                ? props.data.rxBoost
                  ? (
                      props.data.rates.insuredOnly +
                      props.fees +
                      props.data.stopLossPremium.insuredOnly +
                      props.data.rxBoost.insuredOnly
                    ).toFixed(2)
                  : (
                      props.data.rates.insuredOnly +
                      props.fees +
                      props.data.stopLossPremium.insuredOnly
                    ).toFixed(2)
                : props.data.rxBoost
                ? (
                    props.data.rates.insuredOnly +
                    props.fees +
                    props.data.rxBoost.insuredOnly
                  ).toFixed(2)
                : (props.data.rates.insuredOnly + props.fees).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}
      {props.data.rates.insuredPlusSpouse && (
        <>
          <Grid item xs={6}>
            <Typography>Insured Plus Spouse</Typography>
          </Grid>
          {/* <Grid item xs={3}>
            <Typography
              style={{ color: "#0C2659" }}
              align={"right"}
              variant={"body2"}
            >
              {props.data.rxBoost
                ? "$" + props.data.rxBoost.insuredPlusSpouse.toFixed(2)
                : ""}
            </Typography>
          </Grid> */}
          <Grid item xs={3}>
            <Typography
              style={{ color: "#0C2659" }}
              align={"right"}
              variant={"body2"}
            >
              $
              {props.data.stopLossPremium
                ? props.data.rxBoost
                  ? (
                      props.data.rates.insuredPlusSpouse +
                      props.fees +
                      props.data.stopLossPremium.insuredPlusSpouse +
                      props.data.rxBoost.insuredPlusSpouse
                    ).toFixed(2)
                  : (
                      props.data.rates.insuredPlusSpouse +
                      props.fees +
                      props.data.stopLossPremium.insuredPlusSpouse
                    ).toFixed(2)
                : props.data.rxBoost
                ? (
                    props.data.rates.insuredPlusSpouse +
                    props.fees +
                    props.data.rxBoost.insuredPlusSpouse
                  ).toFixed(2)
                : (props.data.rates.insuredPlusSpouse + props.fees).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}
      {props.data.rates.insuredPlusOne && (
        <>
          <Grid item xs={6}>
            <Typography>Insured Plus One</Typography>
          </Grid>
          {/* <Grid item xs={3}>
            <Typography
              style={{ color: "#0C2659" }}
              align={"right"}
              variant={"body2"}
            >
              {props.data.rxBoost
                ? "$" + props.data.rxBoost.insuredPlusOne.toFixed(2)
                : ""}
            </Typography>
          </Grid> */}
          <Grid item xs={3}>
            <Typography
              style={{ color: "#0C2659" }}
              align={"right"}
              variant={"body2"}
            >
              $
              {props.data.stopLossPremium
                ? props.data.rxBoost
                  ? (
                      props.data.rates.insuredPlusOne +
                      props.fees +
                      props.data.stopLossPremium.insuredPlusOne +
                      props.data.rxBoost.insuredPlusOne
                    ).toFixed(2)
                  : (
                      props.data.rates.insuredPlusOne +
                      props.fees +
                      props.data.stopLossPremium.insuredPlusOne
                    ).toFixed(2)
                : props.data.rxBoost
                ? (
                    props.data.rates.insuredPlusOne +
                    props.fees +
                    props.data.rxBoost.insuredPlusOne
                  ).toFixed(2)
                : (props.data.rates.insuredPlusOne + props.fees).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}
      {props.data.rates.insuredPlusChildren && (
        <>
          <Grid item xs={6}>
            <Typography>Insured Plus Children</Typography>
          </Grid>
          {/* <Grid item xs={3}>
            <Typography
              style={{ color: "#0C2659" }}
              align={"right"}
              variant={"body2"}
            >
              {props.data.rxBoost
                ? "$" + props.data.rxBoost.insuredPlusChildren.toFixed(2)
                : ""}
            </Typography>
          </Grid> */}
          <Grid item xs={3}>
            <Typography
              style={{ color: "#0C2659" }}
              align={"right"}
              variant={"body2"}
            >
              $
              {props.data.stopLossPremium
                ? props.data.rxBoost
                  ? (
                      props.data.rates.insuredPlusChildren +
                      props.fees +
                      props.data.stopLossPremium.insuredPlusChildren +
                      props.data.rxBoost.insuredPlusChildren
                    ).toFixed(2)
                  : (
                      props.data.rates.insuredPlusChildren +
                      props.fees +
                      props.data.stopLossPremium.insuredPlusChildren
                    ).toFixed(2)
                : props.data.rxBoost
                ? (
                    props.data.rates.insuredPlusChildren +
                    props.fees +
                    props.data.rxBoost.insuredPlusChildren
                  ).toFixed(2)
                : (props.data.rates.insuredPlusChildren + props.fees).toFixed(
                    2
                  )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}
      {props.data.rates.insuredPlusFamily && (
        <>
          <Grid item xs={6}>
            <Typography>Insured Plus Family</Typography>
          </Grid>
          {/* <Grid item xs={3}>
            <Typography
              style={{ color: "#0C2659" }}
              align={"right"}
              variant={"body2"}
            >
              {props.data.rxBoost
                ? "$" + props.data.rxBoost.insuredPlusFamily.toFixed(2)
                : ""}
            </Typography>
          </Grid> */}
          <Grid item xs={3}>
            <Typography
              style={{ color: "#0C2659" }}
              align={"right"}
              variant={"body2"}
            >
              $
              {props.data.stopLossPremium
                ? props.data.rxBoost
                  ? (
                      props.data.rates.insuredPlusFamily +
                      fee +
                      props.data.stopLossPremium.insuredPlusFamily +
                      props.data.rxBoost.insuredPlusFamily
                    ).toFixed(2)
                  : (
                      props.data.rates.insuredPlusFamily +
                      fee +
                      props.data.stopLossPremium.insuredPlusFamily
                    ).toFixed(2)
                : props.data.rxBoost
                ? (
                    props.data.rates.insuredPlusFamily +
                    fee +
                    props.data.rxBoost.insuredPlusFamily
                  ).toFixed(2)
                : (props.data.rates.insuredPlusFamily + fee).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}
    </>
  );
}

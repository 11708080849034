import axios from "axios";
import { ihpPayload } from "./payloads";
import { replacePayloadIHP } from "./functions";

const constructRequest = (census, payload) => {
  return {
    censusData: census,
    inputData: {
      ...payload,
    },
  };
};

// Handles the API request for each provider availible in step 5 and returns created object to save
const getIHPQuickQuotes = async (header, { updatedPayloadData }) => {
  const data = [];
  const rateSelectionOptions = [
    "IHP Basic",
    "IHP Plus",
    "IHP Premier",
    "IHP Ultimate",
  ];

  for (const selection in rateSelectionOptions) {
    // just incase we want to scale this ill keep the switch statement that updates the payload
    const provider = "ihp";

    // switch cases update the payload being sent with what is selected in step 4
    let payloadToSend = {};
    switch (provider) {
      case "ihp":
        payloadToSend = {
          ...ihpPayload,
          sicCode: updatedPayloadData.sicCode,
          groupEffectiveDate: updatedPayloadData.groupEffectiveDate,
          state: updatedPayloadData.state,
          rateSelection: rateSelectionOptions[selection],
        };
        // payloadToSend = replacePayloadIHP(
        //   payloadToSend,
        //   updatedPayloadData.ihpBrokerFee
        // );
        break;
      default:
        payloadToSend = {
          ...ihpPayload,
          sicCode: updatedPayloadData.sicCode,
          groupEffectiveDate: updatedPayloadData.groupEffectiveDate,
          state: updatedPayloadData.state,
        };
        break;
    }

    const dataToSend = constructRequest(
      updatedPayloadData.census,
      payloadToSend
    );

    // To test with a local provider, replace url with the one below
    // http://localhost:3001/rating-engine/v1/multiRates/ihp?plan=1

    // prod url
    // https://secure.dev.optimedhealth.com/rating/rates/ihp?plan=1

    const reqestData = await axios({
      url: `https://secure.dev.optimedhealth.com/rating/rates/ihp?plan=1`,
      method: "POST",
      data: dataToSend,
      headers: header,
      timeout: 25000,
      responseType: "json",
    }).then((res) => {
      console.log("API REQUEST RESPONSE DATA");
      console.log(res);
      return res.data.response;
    });
    // some providers don't have a carrier field so the carrier is manually incerted into the object
    if (!reqestData.hasOwnProperty(reqestData.carrier)) {
      reqestData.carrier = provider;
    }
    data.push(reqestData);
  }

  console.log("DATA BEING PUSHED TO SAVE QQs");
  console.log(data);
  return data;
};

export { getIHPQuickQuotes };

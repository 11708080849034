import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {RaterContext} from "../RaterV3";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from '@material-ui/icons/Check'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SuccessModal() {
    const {success, setSuccess, message} = React.useContext(RaterContext)
    return (
        <div>
            <Dialog
                open={success}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setSuccess(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <IconButton onClick={() => setSuccess(false)} color={'#0C2659'} style={{position: 'absolute', right: 10, top: 10}}>
                    <CloseIcon style={{backgroundColor: '#0C2659', color: 'white'}}/>
                </IconButton>
                <DialogTitle id="alert-dialog-slide-title">Success <CheckIcon color={'success'}/></DialogTitle>
                <DialogContent>
                    <Divider style={{marginBottom: 10}}/>
                    <DialogContentText id="alert-dialog-slide-description">
                        {message}
                    </DialogContentText>
                    <Divider style={{marginTop: 10}}/>

                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </div>
    );
}

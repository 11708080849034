import { Grid, Typography } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import makeControl from "../../Inputs/makeControl";
import Divider from "@material-ui/core/Divider";
import Slide from "react-reveal/Slide";
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import _ from "lodash";

export default function Field(props) {
  /* NOTE: This type of custom logic is not a great idea in the UI but this is special case */


  const markelPOVCheck =
    props.product &&
    props.product.toLowerCase() === "gap" &&
    props.provider === "markel_basic";

  const ltdmedCheck =
    props.product &&
    props.product.toLowerCase() === "ltdmed" &&
    props.provider === "zurich_ltdmed";

  const ihpCheck =
    props.product &&
    props.product.toLowerCase() === "ihp" &&
    props.provider === "mec_ihp";

  const mecCheck =
    props.product &&
    props.product.toLowerCase() === "mec" &&
    props.provider === "breckpoint_mec";

  /* Selections in povCopayOrDeductible field */

  const copayPOVSelection = "Copay";
  const deductiblePOVSelection = "Applied to Deductible";

  /* Fields getting custom message */

  const povCopayOrDeductibleKey = "povCopayOrDeductible";
  const physiciansCopayKey = "physiciansCopay";
  const physiciansAnnualVisitsStrKey = "physiciansAnnualVisitsStr";
  const physiciansBenefitPerVisitKey = "physiciansBenefitPerVisit";
  const mecRxKey = "valueAddedProducts";
  const rxKey = "rx";

  const allPOVKeys = [
    physiciansCopayKey,
    physiciansAnnualVisitsStrKey,
    physiciansBenefitPerVisitKey,
    rxKey,
    mecRxKey,
  ];
  const copayKey = "copay";
  const deductibleKey = "deductible";
  const allOptionsKey = "All options";

  const messages = {
    [physiciansCopayKey]: {
      [copayKey]: "Please select the Copay amount covered per visit",
      [deductibleKey]: "",
    },
    [physiciansAnnualVisitsStrKey]: {
      [copayKey]:
        "Please select the number of visits per year where copay is reimbursed",
      [deductibleKey]:
        "Amount is set to maximum of 4 visits per year. If you require another amount, please contact your account manager for a customized quote",
    },
    [physiciansBenefitPerVisitKey]: {
      [copayKey]:
        "Amount is set to maximum of $25 paid per visit. If you require another amount, please contact your account manager for a customized quote",
      [deductibleKey]:
        "Amount is set to maximum of $25 paid per visit. If you require another amount, please contact your account manager for a customized quote",
    },
    [rxKey]: {
      [allOptionsKey]: " ",
    },
    [mecRxKey]: {
      [allOptionsKey]: " ",
    },
  };

  const getPOVMessageByField = (name) => {
    let message = "";

    if (markelPOVCheck && allPOVKeys.includes(name)) {
      let povSelection = "";

      let filtered = _.filter(
        props.fields,
        (f) => f.name === povCopayOrDeductibleKey
      );

      if (filtered.length === 1) {
        povSelection = _.head(filtered).value || "";
      }

      if (
        povSelection === copayPOVSelection ||
        povSelection === deductiblePOVSelection
      ) {
        const lookupKey =
          povSelection === copayPOVSelection ? copayKey : deductibleKey;

        message = messages[name][lookupKey];
      }
    }

    if (ltdmedCheck && allPOVKeys.includes(name)) {
      message = messages[name][allOptionsKey];
    }

    if (ihpCheck && allPOVKeys.includes(name)) {
      message = messages[name][allOptionsKey];
    }

    if (mecCheck && allPOVKeys.includes(name)) {
      message = messages[name][allOptionsKey];
    }

    return message;
  };

  let allPOVFieldMessage = "";

  try {
    allPOVFieldMessage = getPOVMessageByField(props.field.name);
  } catch (error) {
    console.error(error);
  }

  return (
    <Slide
      in={props.field.isEnabled}
      right
      unMountOnExit
      mountOnEnter
      duration={500}
    >
      <Grid
        item
        container
        justify="center"
        alignItems="center"
        alignContent={"center"}
        spacing={1}
      >
        <Grid
          item
          xs={6}
          container
          alignContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={10} sm={10} md={11}>
            <Typography
              variant="subtitle1"
              align={"left"}
              color={props.field.isCurrent ? "secondary" : "#0C2659"}
              style={{ fontWeight: props.field.isCurrent ? "bold" : "normal" }}
            >
              {props.field.desc}{" "}
              {props.field.isError && <ErrorIcon color={"#FF0000"} />}
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={1}>
            {props.field.help && (
              <Tooltip
                title={
                  props.field.help
                    ? props.field.help.indexOf("https") !== -1
                      ? "For more product information, click here"
                      : props.field.help
                    : ""
                }
                placement={"top"}
              >
                <IconButton
                  onClick={(e) => {
                    if (
                      props.field.help &&
                      props.field.help.indexOf("https") !== -1
                    ) {
                      e.preventDefault();
                      window.open(props.field.help, "_blank");
                    }
                  }}
                >
                  <InfoIcon style={{ color: "#0C2659" }} />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
          {props.field.isError && (
            <>
              <Typography variant={"caption"} color={"error"}>
                {props.field.errorMessage}
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={6}>
          {makeControl(props.field, props.walking, props.provider)}
        </Grid>
        {props.field.name.indexOf("inpatientBenefit") !== -1 &&
          props.product &&
          props.product.toLowerCase() === "gap" && (
            <Grid item xs={12}>
              <Typography variant={"caption"} color={"#FF0000"}>
                Inpatient Benefit Amount must not exceed total Out of Pocket
                maximum of Major Medical Plan
              </Typography>
            </Grid>
          )}
        {props.field.name.indexOf("combinedBenefit") !== -1 &&
          props.product &&
          props.product.toLowerCase() === "gap" && (
            <Grid item xs={12}>
              <Typography variant={"caption"} color={"#FF0000"}>
                Combined Benefit Amount must not exceed total Out of Pocket
                maximum of Major Medical Plan
              </Typography>
            </Grid>
          )}
        {allPOVFieldMessage.length > 0 && (
          <Grid item xs={12}>
            <Typography variant={"caption"} color={"#FF0000"}>
              {allPOVFieldMessage}
              {/* This solution is not scalable - had to do it this way to add the links */}
              {(props.field.name === "rx" ||
                props.field.name === "valueAddedProducts") && (
                <>
                  <p>
                    For Broadreach formularies click&nbsp;
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://documents.optimedhealth.com/Docs/Broadreach_Rx_Formulary.pdf"
                    >
                      here
                    </a>
                    .
                  </p>
                  <p>
                    For an OptiEnhance Rx formulary click&nbsp;
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://documents.optimedhealth.com/Docs/OptiEnhance_Formulary.pdf"
                    >
                      here
                    </a>
                    .
                  </p>
                </>
              )}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </Slide>
  );
}

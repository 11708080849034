import React from 'react'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";

export const CustomTextField = props => {
    return(
        <Grid item xs={12}>
            <Typography>{props.label}</Typography>
            <TextField
                size={'small'}
                {..._.omit(props,['label','notRequired'])}
                fullWidth
                variant={'outlined'}
                placeholder={props.label}
                required/>
        </Grid>

    )
}


function calculateAge(dob) {
    let diffInMS = Date.now() - new Date(dob).getTime();
    let ageDifference = new Date(diffInMS);

    return Math.abs(ageDifference.getUTCFullYear() - 1970);
}


/*NOTE:
*This function takes the full rowed census and turns it into a tiered census.
* It uses the calculate age function as well as the coverage code to properly add
* each row into the corresponding value.
* */
export const convertFullCensus = census => {
    if (census && census.length > 0) {
        let under40Only = 0
        let under40Child = 0
        let under40Spouse = 0
        let under40Family = 0
        let fortyToFortyNineOnly = 0
        let fortyToFortyNineChild = 0
        let fortyToFortyNineSpouse = 0
        let fortyToFortyNineFamily = 0
        let over50Only = 0
        let over50Child = 0
        let over50Spouse = 0
        let over50Family = 0
        census.forEach(employee => {
            if (calculateAge(employee.dob) < 40) {
                if (employee.coverage === 'EE') {
                    under40Only++
                }
                else if (employee.coverage === 'ES') {
                    under40Spouse++
                }
                else if (employee.coverage === 'EC') {
                    under40Child++
                }
                else if (employee.coverage === 'EF') {
                    under40Family++
                }
            }
            else if (calculateAge(employee.dob) < 50) {
                if (employee.coverage === 'EE') {
                    fortyToFortyNineOnly++
                }
                else if (employee.coverage === 'ES') {
                    fortyToFortyNineSpouse++
                }
                else if (employee.coverage === 'EC') {
                    fortyToFortyNineChild++
                }
                else if (employee.coverage === 'EF') {
                    fortyToFortyNineFamily++
                }
            }
            else if (calculateAge(employee.dob) >= 50) {
                if (employee.coverage === 'EE') {
                    over50Only++
                }
                else if (employee.coverage === 'ES') {
                    over50Spouse++
                }
                else if (employee.coverage === 'EC') {
                    over50Child++
                }
                else if (employee.coverage === 'EF') {
                    over50Family++
                }
            }
        })
        let censusObj = {
            'fiftyPlus': {
                'insured': over50Only,
                'insuredPlusSpouse': over50Spouse,
                'insuredPlusChildren': over50Child,
                'insuredPlusFamily': over50Family,
            },
            'fortyToFortyNine': {
                'insured': fortyToFortyNineOnly,
                'insuredPlusSpouse': fortyToFortyNineSpouse,
                'insuredPlusChildren': fortyToFortyNineChild,
                'insuredPlusFamily': fortyToFortyNineFamily,
            },
            'underForty': {
                'insured': under40Only,
                'insuredPlusSpouse': under40Spouse,
                'insuredPlusChildren': under40Child,
                'insuredPlusFamily': under40Family,
            }
        }
        return Promise.resolve(censusObj)
    }
    else return Promise.reject("No census")
}

import React from 'react'
import Button from '@material-ui/core/Button'

export default function (props) {
    return (
        <Button
            fullWidth

            color={'primary'}
            style={{backgroundColor: '#0C2659', color: '#FFFFFF'}}

            variant={'contained'}
            {...props} />
    )
}

import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    link: {
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
            color: theme.palette.primary.main
        }
    }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function InfoModal(props) {
    const classes  = useStyles()
    //"Stole" this function off stack overflow to capitalize the first letter of each word in a string
    function prettify(str) {

        return str === "CFL" ? str : 
            str.replace(/\w\S*/g, function(txt){
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
    }
    return (
        <div>
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={props.close}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <IconButton onClick={props.close} color={'#0C2659'} style={{position: 'absolute', right: 10, top: 10}}>
                    <CloseIcon style={{backgroundColor: '#0C2659', color: 'white'}}/>
                </IconButton>
                <DialogTitle id="alert-dialog-slide-title">{prettify(props.name)} Product Info</DialogTitle>
                <DialogContent>
                    <Divider style={{marginBottom: 10}}/>
                    <DialogContentText id="alert-dialog-slide-description">
                        {props.info ? props.info: "No Info Provided"}
                    </DialogContentText>
                    <Divider style={{marginTop: 10, marginBottom: 10}}/>
                    {props.underwriting &&
                    <Typography align={'center'} className={classes.link} color='#0C2659' onClick={() => {
                        window.open(props.underwriting, '_blank')
                    }}>
                        Underwriting Guidelines
                    </Typography>}
                    {props.moreInfo &&
                    <Typography align={'center'} className={classes.link} color='#0C2659' onClick={() => {
                        window.open(props.moreInfo, '_blank')
                    }}>
                        More Information
                    </Typography>}
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </div>
    );
}

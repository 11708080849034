import React from 'react'
import TabPanel from "./TabPanel";
import Container from "@material-ui/core/Container";
import {RaterContext} from "../RaterV3";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    panel: {
        padding: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(18),
    },
}));


export default function PanelContainer() {
    const {tabList, value} = React.useContext(RaterContext)
    const classes = useStyles()
    return (
        <Container maxWidth={'xl'}>
            {tabList.map((tab,index) => {
                return (
                    <TabPanel key={tab.name} value={value} index={index} className={classes.panel}>
                        {tab.component}
                    </TabPanel>
                )
            })}
        </Container>
    )
}

import React from "react";
import Grid from "@material-ui/core/Grid";
import { FirebaseContext, FirebaseDB } from "react-ugp-firebase";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { RaterContext } from "../../RaterV3";
import { Collapse } from "@material-ui/core";
import Select from "react-select";
import { makeStyles } from "@material-ui/core/styles";
import { PlansApi } from "../../../utils/engine";
import MissingEssentialsModal from "../../Modals/MissingEssentialsModal";
import _ from "lodash";
import { CustomTextField } from "../StepThree/CustomTextField";
import { latestVersion } from "../../../utils/Tools";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  title: {
    padding: theme.spacing(6),
    marginTop: theme.spacing(10),
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
}));

export default function Start() {
  const [userRoles, setUserRoles] = React.useState([]);
  const [opps, setOpps] = React.useState([]);
  const [oppId, setOppId] = React.useState("");
  const [opportunity, setOpportunity] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [isSelecting, setIsSelecting] = React.useState(false);
  const [essentialsOpen, setEssentialsOpen] = React.useState(false);
  const [showManualOpp, setShowManualOpp] = React.useState(false);
  const {
    setBrokerChange,
    log,
    allProducts,
    setAllProducts,
    setRequiredLevel,
    setGroupName,
    setCensus,
    setOpportunityId,
    setHasOpportunity,
    setSicCode,
    setGroupEffectiveDate,
    setZipCode,
    setState,
    setError,
    setMessage,
    setGapCensus,
    setValue,
    setAdminFees,
    setLoading,
    loading,
    setFourTierCensus,
    webError,
  } = React.useContext(RaterContext);
  const classes = useStyles();
  const { user, isUserLoading, restConfig } = React.useContext(FirebaseContext);

  const userRole = isUserLoading ? [] : user.roles;

  let api = new PlansApi();

  /*NOTE:
    useEffect function that is triggered once the user is done loading in. It pulls all the opportunities that a user has access to from Firebase
    and puts them in a format that can easily be used by a react-select component.
     */
  React.useEffect(() => {
    getOpportunitiesFromFirebase();
  }, [isUserLoading]);

  React.useEffect(() => {
    //just run it once.
    log("session_start", {});
    console.log("%c%s", "color: black; background: white; font-size: 24px;", `Running Version: ${latestVersion}`);
  }, []);

  const getOpportunitiesFromFirebase = () => {
    if (user) {
      FirebaseDB.collection("opportunities")
        .where("allowedUsers", "array-contains", user.uid)
        .where("isActive", "==", true)
        .get()
        .then((opportunities) => {
          let arr = [];
          let optionArr = [];
          opportunities.forEach((doc) => {
            let obj = doc.data();
            obj.id = doc.id;
            if (obj.name) {
              arr.push(obj);
              optionArr.push({ label: obj.name, value: obj });
            }
          });
          setOpps(arr);
          setOptions(optionArr);
          setLoading(false);
        })
        .catch((err) => {
          setError(true);
          setLoading(false);
          setMessage("Something went wrong. Please contact support.");
        });
    }
  };

  /*NOTE:
    Function that handles the selection of an opportunity. It verifies the opp is valid by hitting the getOpportunity function
     and then fills in the corresponding data into the global context.
     */
  const handleSelect = (opp) => {
    // console.log(opp)

    setBrokerChange(opp);

    setLoading(true);
    api
      .getOpportunity(opp.id, restConfig)
      .then((res) => {
        if (!res.data.isError) {
          setLoading(false);
          setGroupName(opp.name);
          setCensus(opp.census && opp.census.length > 0 ? opp.census : []);
          setOpportunityId(opp.id);
          setSicCode(Number(opp.sicCode));
          setZipCode(opp.zipCode);
          setGroupEffectiveDate(res.data.groupEffectiveDate ? res.data.groupEffectiveDate : opp.groupEffectiveDate);
          setState(opp.state);
          setHasOpportunity(true);
          setValue(1);
          setRequiredLevel(opp.requiredLevel ? opp.requiredLevel : 1);
          /*setProducts(opp.products ? opp.products: {})*/
          setGapCensus(opp.gapCensus ? opp.gapCensus : {});
          setFourTierCensus(opp.fourTierCensus ? opp.fourTierCensus : {});
          setAdminFees(res.data.adminFees);
        } else {
          setLoading(false);
          setError(true);
          setMessage(
            "It looks like the group selected no longer exists within CRM. Please verify the existence of the group and try again. If the problem persists, please contact support."
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setError(true);
        setMessage(
          "It looks like the group selected no longer exists within CRM. Please verify the existence of the group and try again. If the problem persists, please contact support."
        );
        webError({
          err: err.response ? err.response.data : "",
          text: "An error occurred when trying to validate an existing opportunity on the start page",
          opportunity: opp,
        });
      });
  };

  /*NOTE:
    Handling the manual entry of an opportunity ID. This hits the getOpportunity function and uses the response to fill
    in all the essential details such as zip and sic code. If details are missing then the user is prompted accordingly.
     */
  const handleEnterOpp = () => {
    setLoading(true);
    console.log(oppId.replace(/\s/g, ""));

    api
      .getOpportunity(oppId.replace(/\s/g, ""), restConfig)
      .then((res) => {
        console.log(res);

        if (!res.data.isError) {
          setLoading(false);
          setGroupName(res.data.groupName);
          setHasOpportunity(true);
          setOpportunityId(oppId.replace(/\s/g, ""));
          setAdminFees(res.data.adminFees);
          if (res.data.zipCode && res.data.sicCode && res.data.groupEffectiveDate && res.data.state) {
            setZipCode(res.data.zipCode);
            setSicCode(Number(res.data.sicCode));
            setGroupEffectiveDate(res.data.groupEffectiveDate);
            setState(res.data.state);
            setCensus(res.census || []);
            setGapCensus(res.gapCensus || {});
            setFourTierCensus(res.fourTierCensus || {});
            setValue(1);
            setBrokerChange({
              sicCode: res.data.sicCode,
              groupEffectiveDate: res.data.groupEffectiveDate,
              name: res.data.groupName,
              state: res.data.state,
              zipCode: res.data.zipCode,
              id: oppId.replace(/\s/g, ""),
              crmId: res.data.crmId,
            });
          } else {
            /*setEssentialsOpen(true)*/
            setError(true);
            webError({
              text: "Missing information when trying to load in an opportunity",
              res: res.data,
            });
            setMessage(
              "The given opportunity ID has missing information. Please make sure the corresponding opportunity has a State, SIC Code, Group Effective Date and Zip Code inside of CRM."
            );
          }
        } else {
          setError(true);
          setLoading(false);
          webError({
            res: res.data,
            oppId,
            text: 'An error occurred when trying to validate an opportunity"',
          });
          setMessage(
            "Something went wrong when trying to receive the opportunity. Please verify that the opportunity ID is correct and try again. If the problem persists, please contact support."
          );
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError(true);
        setMessage(
          "Something went wrong. Please wait a few minutes and then try again. If the problem persists, please contact support."
        );
        webError({
          err: err.response ? err.response.data : "",
          text: "Manual entry of the opportunity failed",
          oppId,
        });
      });
  };

  // User roles don't match up with roles in firebase so when the user loads in we manually fetch the user data from firebase and apply roles
  // based off of the data received

  React.useEffect(() => {
    const fetchRoles = async () => {
      const userRef = FirebaseDB.collection("users").doc(user.uid);

      const userRoles = await userRef
        .get()
        .then((doc) => {
          return doc.data().searchRoles;
        })
        .catch((err) => {
          console.log("Error getting roles", err);
        });

      setUserRoles(userRoles);
    };

    if (!isUserLoading) {
      fetchRoles().catch(console.error);
    }
  }, [isUserLoading]);

  React.useEffect(() => {
    if (!isUserLoading && userRoles.includes("ihp_agent")) {
      const filtered_array = _.pullAllBy(
        allProducts,
        [
          { type: "accident" },
          { type: "hospital" },
          { type: "life" },
          { type: "ltd" },
          { type: "std" },
          { type: "cfl" },
          // { type: "mec" },
          { type: "ihp_agent" },
          // { type: "ltdmed" },
          // { type: "major_medical" },
          { type: "vision" },
          { type: "dental" },
          // { type: "rx" },
          // { type: "gap" },
        ],
        "type"
      );
      setAllProducts(filtered_array);
    } else if (!isUserLoading && !userRoles.includes("employee")) {
      const filtered_array = _.pullAllBy(
        allProducts,
        [
          { type: "accident" },
          { type: "hospital" },
          { type: "life" },
          { type: "ltd" },
          { type: "std" },
          { type: "dental" },
          { type: "cfl" },
          { type: "ihp_agent" },
          { type: "ihp" },
          { type: "emec" },
          { type: "travelers" },
          // { type: "everest" },
          // { type: "ihp_agent" },
          { type: "vision" },
          // { type: "rx" },
        ],
        "type"
      );
      setAllProducts(filtered_array);
    } else if (!isUserLoading) {
      const filtered_array = _.pullAllBy(
        allProducts,
        [
          { type: "accident" },
          { type: "hospital" },
          { type: "ihp_agent" },
          { type: "life" },
          { type: "ltd" },
          { type: "std" },
          { type: "dental" },
          { type: "cfl" },
          { type: "vision" },
          { type: "emec" },
          { type: "travelers" },
          // { type: "everest" },
          //{ type: "ihp" },
          // { type: "rx" },
        ],
        "type"
      );
      setAllProducts(filtered_array);
    }
  }, [userRoles]);

  return (
    <div className={classes.paper}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant={"h4"} align={"center"} gutterBottom className={classes.title}>
            Hello
            {user && user.firstName && ` ${user.firstName} ${user.lastName}`}. Welcome to the OptiRater!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align={"center"} variant={"h6"}>
            To get started, select one of the options below
          </Typography>
        </Grid>
        <Grid container spacing={3} justify={"center"} className={classes.container}>
          <Grid item xs={10}>
            <Button
              style={{ backgroundColor: "#0C2659" }}
              variant="contained"
              size="large"
              onClick={() => {
                setValue(1);
                setHasOpportunity(false);
              }}
              fullWidth
              color={"primary"}
            >
              Create a new quote
            </Button>
          </Grid>
          <Grid item xs={10}>
            <Button
              style={{ backgroundColor: "#0C2659" }}
              variant="contained"
              size="large"
              onClick={() => setIsSelecting(true)}
              fullWidth
              color={"primary"}
            >
              Select a previously created quote
            </Button>
          </Grid>
          {isSelecting && (
            <Grid item xs={10}>
              <Collapse in={isSelecting}>
                <Grid container item xs={12}>
                  {!loading && opps && opps.length === 0 && (
                    <>
                      <Grid item xs={12}>
                        <Typography align={"center"}>
                          It looks like you don't have any group quotes in progress. Create a new quote to get started
                        </Typography>
                      </Grid>
                    </>
                  )}
                  {!loading && opps && opps.length > 0 && (
                    <>
                      <Grid item xs={12} style={{ zIndex: 30 }}>
                        <Typography variant={"body2"} color={"primary"}>
                          Select a previous quote below.
                        </Typography>
                        <Select
                          options={_.sortBy(options, (x) => x.label)}
                          onChange={(x) => {
                            setOpportunity(x);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          color="primary"
                          style={{ backgroundColor: "#0C2659" }}
                          variant="contained"
                          disabled={opportunity === "" || opportunity.value === ""}
                          onClick={() => {
                            handleSelect(opportunity.value);
                          }}
                        >
                          Continue with this quote
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Collapse>
            </Grid>
          )}
          {!isUserLoading && user && user.roles && user.roles.indexOf("broker") === -1 && (
            <Grid item xs={10}>
              <Button
                variant="contained"
                size="large"
                onClick={() => setShowManualOpp(true)}
                fullWidth
                color={"primary"}
                style={{ backgroundColor: "#0C2659" }}
              >
                Enter in an existing opportunity ID
              </Button>
            </Grid>
          )}
          {showManualOpp && (
            <Grid item xs={10}>
              <Collapse in={showManualOpp}>
                <Grid item xs={12}>
                  <CustomTextField value={oppId} onChange={(e) => setOppId(e.target.value)} label={"Opportunity ID"} />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    onClick={handleEnterOpp}
                    disabled={oppId === ""}
                    fullWidth
                    color={"primary"}
                    style={{ backgroundColor: "#0C2659" }}
                  >
                    Use this opportunity ID
                  </Button>
                </Grid>
              </Collapse>
            </Grid>
          )}
        </Grid>
        <MissingEssentialsModal open={essentialsOpen} close={() => setEssentialsOpen(false)} />
      </Grid>
    </div>
  );
}

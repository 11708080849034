import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { RaterContext } from "../RaterV3";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import { OptionsSelect, search } from "../../utils/AsyncSelect";
import { FirebaseContext, FirebaseDB } from "react-ugp-firebase";
import Button from "../Assets/Button";
import axios from "axios";
import { PulseLoader } from "react-spinners";

const useStyles = makeStyles((theme) => ({
  link: {
    color: "blue",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));

export default function ChangeBrokerModal() {
  const classes = useStyles();

  /* Using the brokerChange object does not always work due to timing issues. Instead, use the data that powers the Group Information popup */

  // const { brokerChange, brokerModal, setBrokerModal, setMessage, setError } = React.useContext(RaterContext)

  const {
    opportunityId,
    groupName,
    groupEffectiveDate,
    sicCode,
    zipCode,
    state,
    brokerModal,
    setBrokerModal,
    setMessage,
    setError,
    setSelectedBroker,
    selectedBroker,
    discounts,
    setDiscounts,
    userCRMid,
    setUserCRMid,
  } = React.useContext(RaterContext);

  const [broker, setBroker] = useState("");
  const [brokerOptions, setBrokerOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { restConfig, user } = React.useContext(FirebaseContext);
  const isDev = process.env.NODE_ENV === "development";

  /*
     This wont work because the FB document can take a few seconds so clicking on the #3 immediately after creating new will 
     cause the call to:

        brokerChange.crmId.toString()

    To fail and a white screen. Additionally, crmId returned from Opp Manager is the true CRM ID for the Opportuntiy and not 
    the id related to the document in crmBrokers table. Plus, the setBroker hook is called when using the dropdown and the value is the 
    id and not name to the screen ends up show a nasty ID instead of the brokers name
    */
  /*
    useEffect(()=>{

        // console.log(brokerChange.crmId)

        if (brokerModal === true){

            const docRef = FirebaseDB.collection('crmBrokers').doc(brokerChange.crmId.toString());

            docRef.get().then((doc) => {
                if (doc.exists) {

                    // console.log("Document data:", doc.data().brokerName);
                    setBroker(doc.data().brokerName)

                } else {
                    // console.log("Does not exist")
                    setBroker('')
                }
            }).catch((error) => {
                // console.log("Error getting document:", error);
                setBroker('')
            });

        }

    },[brokerModal])
    */

  // console.log("this is in broker modal crmid");

  // console.log(userCRMid);

  React.useEffect(() => {
    console.log("testing in discounts for internal");
    const getDiscounts = async () => {
      console.log("trigger discounts in broker modal");
      const brokerRef = FirebaseDB.collection("discounts").doc(userCRMid);
      await brokerRef
        .get()
        .then((doc) => {
          setDiscounts({ ...discounts, ...doc.data().discounts });
        })
        .catch((err) => {
          console.log("No discount found.", err);
        });
    };

    if (userCRMid !== "" && user.roles && user.roles.includes("broker")) {
      getDiscounts();
    }
  }, [userCRMid]);

  React.useEffect(() => {
    const getDiscounts = async () => {
      const brokerRef = FirebaseDB.collection("discounts").doc(selectedBroker);
      await brokerRef
        .get()
        .then((doc) => {
          setDiscounts({ ...discounts, ...doc.data().discounts });
        })
        .catch((err) => {
          console.log("No discount found.", err);
        });
    };

    if (selectedBroker != undefined) {
      getDiscounts();
    }
  }, [selectedBroker]);

  // console.log("this is selected Broker in modal");
  // console.log(selectedBroker);

  return (
    <div>
      <Dialog
        open={brokerModal}
        fullWidth={true}
        maxWidth={"md"}
        keepMounted
        onClose={() => setBrokerModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Group Information</DialogTitle>
        <DialogContent>
          <Grid container direction="column" justify="flex-start" alignItems="center">
            <Typography>Select a Broker (Start typing your broker's name to get options)</Typography>
            {/* { broker ?
                            <Typography align="center">Current Broker: {broker}</Typography> : null

                        } */}
            <OptionsSelect
              value={broker}
              onValueChanged={(value) => {
                setBroker(value);
                setSelectedBroker(value);
              }}
              options={brokerOptions}
              loadOptions={search(FirebaseDB.collection("crmBrokers"), "brokerName", setBrokerOptions)}
            />

            <Typography variant="subtitle1" gutterBottom>
              Group Name: {groupName || "NA"}
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Group Sic Code: {sicCode || "NA"}
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Group Effective Date: {groupEffectiveDate || "NA"}
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Group State: {state.label ? state.label : state || "NA"}
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Group Zipcode: {zipCode || "NA"}
            </Typography>
          </Grid>
          <Divider style={{ marginTop: 10 }} />
        </DialogContent>
        <DialogActions>
          {loading === true ? (
            <PulseLoader sizeUnit={"px"} size={10} color={"#0C2659"} loading={loading} />
          ) : (
            <Button
              disabled={broker.length <= 1}
              onClick={(e) => {
                setLoading(true);

                // console.log(brokerOptions)

                const url = isDev
                  ? "http://localhost:3002/opportunity-manager/v1/crm/updateOpportunity"
                  : "/rating/crm/updateOpportunity";

                return axios
                  .post(
                    url,
                    {
                      opportunityId: opportunityId,
                      agentId: broker,
                    },
                    restConfig
                  )

                  .then((x) => {
                    setLoading(false);

                    // console.log(x.data.isError)

                    if (x.data.isError === false) {
                      setBrokerModal(false);
                    } else {
                      setMessage(
                        "An error has occurred. Please try again later. If the problem persists, contact support"
                      );
                      setError(true);
                    }
                  })
                  .catch((e) => {
                    setLoading(false);
                    setBrokerModal(false);
                    setMessage(
                      "An error has occurred. Please try again later. If the problem persists, contact support"
                    );
                    setError(true);
                  });
              }}
              color="#0C2659"
            >
              Submit
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

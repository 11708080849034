import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Divider from "@material-ui/core/Divider";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/InfoRounded";
import CloseIcon from "@material-ui/icons/Close";
import DropdownInput from "../Inputs/DropdownInput";
import { Button } from "@material-ui/core";
import { RaterContext } from "../RaterV3";
import PayloadsModal from "./PayloadsModal";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  link: {
    color: "blue",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));

export default function QuickQuoteModal(props) {
  const {
    quickQuoteDeductible,
    setQuickQuoteDeductible,
    quickQuoteCombinedBenefit,
    setError,
    setMessage,
    total,
    setQuickQuoteCombinedBenefit,
  } = React.useContext(RaterContext);

  const [payloadsOpen, setPayloadsOpen] = React.useState(false);

  const deductibleOptions = [
    { label: "Select", deductibleValue: null },
    { label: "No Deductible", deductibleValue: 0 },
    { label: "250", deductibleValue: 250 },
    { label: "500", deductibleValue: 500 },
    { label: "750", deductibleValue: 750 },
    { label: "1000", deductibleValue: 1000 },
    { label: "1500", deductibleValue: 1500 },
    { label: "2000", deductibleValue: 2000 },
    { label: "2500", deductibleValue: 2500 },
    { label: "3000", deductibleValue: 3000 },
    { label: "3500", deductibleValue: 3500 },
    { label: "4000", deductibleValue: 4000 },
  ];

  const combinedBenefitOptions = [
    { label: "Select", combinedBenefitValue: null },
    { label: "500", combinedBenefitValue: 500 },
    { label: "1000", combinedBenefitValue: 1000 },
    { label: "1500", combinedBenefitValue: 1500 },
    { label: "2000", combinedBenefitValue: 2000 },
    { label: "2500", combinedBenefitValue: 2500 },
    { label: "3000", combinedBenefitValue: 3000 },
    { label: "3500", combinedBenefitValue: 3500 },
    { label: "4000", combinedBenefitValue: 4000 },
    { label: "4500", combinedBenefitValue: 4500 },
    { label: "5000", combinedBenefitValue: 5000 },
    { label: "5500", combinedBenefitValue: 5500 },
    { label: "6000", combinedBenefitValue: 6000 },
    { label: "6500", combinedBenefitValue: 6500 },
    { label: "7000", combinedBenefitValue: 7000 },
  ];
  const classes = useStyles();

  // const [deductibleValue, setDeductibleValue] = useState(0);
  let deductibleValue, combinedBenefitValue;

  // const [combinedBenefitValue, setCombinedBenefitValue] = useState(0);

  const handleDeductibleChange = (event) => {
    // setDeductibleValue(event.target.value);
    console.log("Setting deductible to " + event.target.value);
    setQuickQuoteDeductible(event.target.value);
  };

  const handleCombinedBenefitChange = (event) => {
    // setCombinedBenefitValue(event.target.value);
    console.log("Setting combined benifit to " + event.target.value);
    setQuickQuoteCombinedBenefit(event.target.value);
  };

  //function that registers submit in ConfigureProduct needs to be passed in as a prop and used in the function below
  const submitQuickQuote = () => {
    console.log("these are the qq values");
    console.log("deductable");
    console.log(quickQuoteDeductible);
    console.log("combined");
    console.log(quickQuoteCombinedBenefit);

    console.log("checking typeOf deductable b4 if");
    console.log(typeof quickQuoteDeductible);

    console.log("Checking typeOf combined b4 if");
    console.log(typeof quickQuoteCombinedBenefit);

    const deductibleNumber = Number(quickQuoteDeductible);

    console.log("this should deductable number");

    console.log(typeof deductibleNumber);

    const combinedBenefitNumber = Number(quickQuoteCombinedBenefit);

    console.log("this should combined number");
    console.log(typeof combinedBenefitNumber);

    console.log(deductibleNumber);

    console.log(combinedBenefitNumber);

    if (deductibleNumber >= combinedBenefitNumber) {
      console.log("nested deductable");
      console.log(quickQuoteDeductible);
      console.log("nested combined");
      console.log(quickQuoteCombinedBenefit);

      console.log("checking typeOf deductable");
      console.log(typeof quickQuoteDeductible);

      console.log("Checking typeOf combined");
      console.log(typeof quickQuoteCombinedBenefit);

      console.log("this should deductable number");

      console.log(typeof deductibleNumber);

      console.log("this should combined number");
      console.log(typeof combinedBenefitNumber);

      //alert("QQ is not available with these values");
      setError(true);
      setMessage("Quick Quote is not available with these values");
      props.close();
      return;
    }

    console.log("this is census total on QQ");
    console.log(total);

    if (total < 51) {
      setError(true);

      setMessage("Census total must be greater than 51 in order for Quick Quote to work");

      props.close();
      return;
    }

    //use context to bring in the census total, and then bring in the error message boxes
    props.quickQuote();
    console.log("SUBMIT QQ");
    console.log(props.quickQuote());
  };

  const openInfo = () => {
    setPayloadsOpen(true);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.close}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{ textAlign: "center" }} // Centers the dialog content
      >
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <DialogTitle id="alert-dialog-slide-title">Quick Quote</DialogTitle>

          <DialogActions>
            <IconButton onClick={openInfo} color={"#0C2659"}>
              <InfoIcon style={{ color: "#0C2659" }} />
            </IconButton>
            <IconButton onClick={props.close} color={"#0C2659"}>
              <CloseIcon style={{ backgroundColor: "#0C2659", color: "white" }} />
            </IconButton>
          </DialogActions>
        </div>

        <DialogContent>
          <Divider style={{ margin: "10px 0" }} />
          <DropdownInput
            label="Select your deductible: "
            options={deductibleOptions}
            value={deductibleValue}
            onChange={handleDeductibleChange}
          />

          <br />

          <DropdownInput
            label="Select your combined benefit: "
            options={combinedBenefitOptions}
            value={combinedBenefitValue}
            onChange={handleCombinedBenefitChange}
          />

          <br />

          <Button onClick={submitQuickQuote} style={{ marginTop: 10 }}>
            Submit
          </Button>
          <Divider style={{ margin: "10px 0" }} />
        </DialogContent>

        {/* <DialogActions>
          <IconButton onClick={openInfo} color={"#0C2659"}>
            <InfoIcon style={{ color: "#0C2659" }} />
          </IconButton>
          <IconButton onClick={props.close} color={"#0C2659"}>
            <CloseIcon style={{ backgroundColor: "#0C2659", color: "white" }} />
          </IconButton>
        </DialogActions> */}
      </Dialog>
      <PayloadsModal open={payloadsOpen} close={() => setPayloadsOpen(false)} />
    </div>
  );
}

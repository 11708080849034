import React from "react";

export const latestVersion = 'v12282021.235'

export function checkIfEmp(userRoles, dialog, functionCall) {

    if (userRoles.includes('employee')){

        dialog(true)

    } else {
        return functionCall(false)}
}

import {Grid, Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import React from "react";

export default function LifeRates(props) {
    return (
        <>
            {props.data.member && props.data.member.rate &&
            <>
                <Grid item xs={6}>
                    <Typography>Member Rates</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant={'body2'} color={'#0C2659'} align={'right'}>
                        ${(props.data.member.rate + props.fees).toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
            </>
            }
            {props.data.dependent && props.data.dependent.rate &&
            <>
                <Grid item xs={6}>
                    <Typography>Dependent Rates</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant={'body2'} color={'#0C2659'} align={'right'}>
                        ${(props.data.dependent.rate + props.fees).toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
            </>
            }
        </>
    )
}

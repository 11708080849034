import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import React from "react";

export default function Copyright() {
    let style = {
        width: '300px',
        position: 'fixed',
        zIndex: -1,
        left: '50%',
        marginLeft: '-150px',
        bottom: 5,
    }
    return (
        <div style={style}>
            <Typography variant="body2" color="textSecondary" align="center">
                {'Copyright © '}
                <Link color="inherit">
                    OptiMed Health
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </div>
    );
}

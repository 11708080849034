import React, { useEffect } from "react";

import { Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { RaterContext } from "../../RaterV3";
import _, { forEach } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { postRates } from "../../../utils/engine";
import { FirebaseContext, FirebaseDB } from "react-ugp-firebase";
import { ResultProduct } from "./ResultProduct";
import { removeNullValues } from "../../../utils/removeNullValues";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import { MenuItem, Select } from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { calcAdminFee } from "../StepFive/SavedPlanCard";
//import { RatingEngineContext } from "react-rating-engine";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  dialog: {
    paper: {
      padding: theme.spacing(3),
    },
  },
}));

export default function Results() {
  const {
    log,
    webError,
    loading,
    reset,
    products,
    setProducts,
    setSuccess,
    setMessage,
    setError,
    opportunityId,
    setLoading,
    gapCensus,
    census,
    setValue,
    setAdminFees,
    adminFees,
    requiredLevel,
    fourTierCensus,
    hasSubmit,
    setHasSubmit,
    sendHSAQuote,
    handleSendHSA,
    rxValues,
    setRxValues,
    setTotalSelectionFee,
    discounts,
    shelterPayloadTracker,
    setSheltPayload,
    nationPayloadTracker,
    setNationPayload,
    zurichPayloadTracker,
    setZurPayload,
    markelPayloadTracker,
    setMarkelPayload,
    chubbPayloadTracker,
    setChubbPayload,
    IHPPayloadTracker,
    setIHPPayload,
    isQQ,
    setQQ,
    setSendOptienhance,
    sendOptienhance,
    handleSendOptienhance,
    userCRMid,
    selectedBroker,
  } = React.useContext(RaterContext);
  const { restConfig, user, isUserLoading } = React.useContext(FirebaseContext);
  const [results, setResults] = React.useState([]);
  const [disabled, setDisabled] = React.useState(false);
  const classes = useStyles();

  const userRole = isUserLoading ? "" : user.roles;
  const [dialog, setDialog] = React.useState(false);
  const [HSADialog, setHSADialog] = React.useState(false);
  const [showOptiEnhance, setShowOptiEnhance] = React.useState(false);
  const [optienhanceDialog, setOptienhanceDialog] = React.useState(false);
  const [openAdminFeeDialog, setOpenAdminFeeDialog] = React.useState(false);
  const [shouldOpenOptienhanceDialog, setShouldOpenOptienhanceDialog] = React.useState(false);
  const [selectedProvider, setSelectedProvider] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");

  //const [HSADialog, setHSADialog] = React.useState(false);
  const [rxDialog, setRxDialog] = React.useState(sendHSAQuote ? false : false);
  const [submitState, setSubmitState] = React.useState({
    sendToSelfEmp: false,
    sendtoHSA: false,
    sendtoOptienhance: false,
  });
  const [rxSubmitState, setRxSubmitState] = React.useState({
    general_teleHealth: true,
    dermatology_teleHealth: true,
    medical_pricing_transparency: true,
    medical_bill_finance_guidance: true,
    lifestyle_rx: false,
    mental_health_teleHealth: false,
    teleHealth_wellness_coaching: false,
    mri_imaging: false,
    medical_bill_negotiation: false,
    electronic_medical_records: false,
    virtual_id_wallet: false,
    insurance_plan_verification: false,
  });
  const { sendToSelfEmp, sendtoHSA, sendtoOptienhance } = submitState;
  const {
    general_teleHealth,
    dermatology_teleHealth,
    medical_pricing_transparency,
    medical_bill_finance_guidance,
    lifestyle_rx,
    mental_health_teleHealth,
    teleHealth_wellness_coaching,
    mri_imaging,
    medical_bill_negotiation,
    electronic_medical_records,
    virtual_id_wallet,
    insurance_plan_verification,
  } = rxSubmitState;

  /*NOTE:
    useEffect that gets all the valid results (saved plans) that a user has an organizes them in a way that can be
    easily parsed for the user's convenience. This runs any time the global products variable changes in any way.
    */
  useEffect(() => {
    if (shouldOpenOptienhanceDialog) {
      // Use a small delay to ensure the first dialog closes fully before opening the second one
      setTimeout(() => {
        setOptienhanceDialog(true);
        setShouldOpenOptienhanceDialog(false);
      }, 300); // 300ms delay to ensure smooth transition
    }
  }, [shouldOpenOptienhanceDialog]);

  useEffect(() => {
    if (userRole.includes("employee")) {
      setOpenAdminFeeDialog(true);
    } else {
      setOpenAdminFeeDialog(false);
      setOptienhanceDialog(true);
    }
  }, [userRole]);

  React.useEffect(() => {
    curateResults();
  }, [products]);

  React.useEffect(() => {
    handleSendHSA(sendtoHSA);
  }, [sendtoHSA]);

  React.useEffect(() => {
    handleSendOptienhance(sendtoOptienhance);
  }, [sendtoOptienhance]);

  // Add this new useEffect hook to set the initial selectedProvider and inputValue
  React.useEffect(() => {
    if (activeProducts.length > 0 && !selectedProvider) {
      setSelectedProvider(activeProducts[0].product);
      // Set the initial inputValue to the current admin fee, or 10 if it's 0 or null
      setInputValue((adminFees[activeProducts[0].product] || 10).toString());
    }
  }, [activeProducts, adminFees]);

  const handleChange = (event) => {
    setSubmitState({
      ...submitState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleRxChange = (event) => {
    setRxSubmitState({
      ...rxSubmitState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleClickOptiEnhance = () => {
    setShowOptiEnhance(!showOptiEnhance);
  };

  const submitRx = () => {
    setRxValues({
      ...rxValues,
      ...rxSubmitState,
    });
    const total =
      0 + rxSubmitState.lifestyle_rx
        ? 7
        : 0 + rxSubmitState.mental_health_teleHealth
        ? 10
        : 0 + rxSubmitState.teleHealth_wellness_coaching
        ? 3
        : 0 + rxSubmitState.mri_imaging
        ? 2.5
        : 0 + rxSubmitState.medical_bill_negotiation
        ? 2.5
        : 0 + rxSubmitState.electronic_medical_records
        ? 2.5
        : 0 + rxSubmitState.virtual_id_wallet
        ? 2
        : 0 + rxSubmitState.insurance_plan_verification
        ? 2
        : 0;
    setTotalSelectionFee(total);
  };

  console.log("HEY THIS IS THE PRODCUTS");
  console.log(products);

  console.log("HEY THESE ARE THE PAYLOADS");
  console.log("markel payload");
  console.log(markelPayloadTracker);

  console.log("Shelter payload");

  console.log(shelterPayloadTracker);

  console.log("nationwide payload");

  console.log(nationPayloadTracker);

  console.log("zurich payload");
  console.log(zurichPayloadTracker);

  console.log("chubb payload");
  console.log(chubbPayloadTracker);

  console.log("this is qq checker");
  console.log(isQQ);

  console.log("MONEY TIME");

  // const shelterPayload = {
  //   state: "GA", // -- grabbed from step 2
  //   sicCode: 111, /// -- grabbed from step 2
  //   groupEffectiveDate: `10/01/2022`, // -- grabbed from step 2
  //   employerContributionPercent: 1.0,
  //   familyDeductibleMultiplier: "2X",
  //   combinedBenefit: 5000, // updated in qq modal
  //   familyBenefitMultiplier: "Family Max at 2X Individual",
  //   deductible: 250, // updated in qq modal
  //   coinsurancePercent: 0,
  //   physiciansBenefitSameAsAnyOther: "Not Covered", // ***** these stuff can be directly copied
  //   physiciansBenefit: "Not Covered",
  //   rxCoverageType: "Not Covered",
  //   hasMentalHealthAndSubstanceAbuseCoverage: true,
  // };

  // const nationwidePayload = {
  //   state: "AL",
  //   groupEffectiveDate: "01/01/2021",
  //   sicCode: 111,
  //   deductible: 250,
  //   employerContributionPercent: 1.0,
  //   familyBenefitMultiplier: "2x",
  //   combinedBenefit: 5000,
  //   eligibleEmployees: 120,
  //   ratingMethod: "Composite",
  //   hasRxBenefit: false,
  //   hasPhysiciansBenefit: false,
  //   includeDeductible: true,
  //   physiciansBenefitPerVisit: 0,
  //   coinsurancePercent: 0,
  //   familyDeductibleMultiplier: "2x",
  //   hasMentalHealthAndSubstanceAbuseCoverage: true,
  // };

  // const zurichPayload = {
  //   groupEffectiveDate: "08/01/2022",
  //   state: "GA",
  //   sicCode: 111,
  //   employerContributionPercent: 1.0,
  //   deductibleWaivedForAccident: false,
  //   familyDeductibleMultiplier: "2x",
  //   deductible: 250,
  //   combinedBenefit: 250,
  //   // hasPhysiciansBenefit: false,
  //   coinsurancePercent: 1,
  //   familyBenefitMultiplier: "2x",
  //   physiciansBenefitPerVisit: 0,
  //   physiciansAnnualVisits: 0,
  //   officeVisitFamilyMax: "No Limit",
  // };

  // const markelPayload = {
  //   groupEffectiveDate: "09/01/2022",
  //   state: "AL",
  //   sicCode: 8011,
  //   eligibleEmployeesSelection: "Less Than 100",
  //   employerContributionPercent: 1,
  //   deductible: 250,
  //   familyDeductibleMultiplier: "No Limit",
  //   familyBenefitMultiplier: "2x",
  //   combinedBenefit: 7000,
  //   physiciansOfficeCopayOption: false,
  //   coinsurancePercent: 0,
  //   hasPhysiciansBenefit: false,
  //   hasMentalHealthAndSubstanceAbuseCoverage: true,
  // };

  // // physiciansFamilyLimitMultiplier needs to be No Limit
  // const chubbPayload = {
  //   groupEffectiveDate: "01/01/2022",
  //   state: "CA",
  //   sicCode: 6311,
  //   employerContributionPercent: 1,
  //   employerContributionAppliesTo: "EE's & Dep's",
  //   combinedBenefit: 7000,
  //   coinsurancePercent: 1.0,
  //   ipOpDeductible: 250,
  //   baseMedFamilyBenefitMultiplier: "2X Traditional Ded",
  //   gapMedFamilyBenefitMultiplier: "2X Traditional Ded",
  //   hasPhysiciansBenefit: false,
  // };

  const fieldMarkel = [
    //the markell one
    {
      name: "groupEffectiveDate",
      desc: "Group Effective Date",
      value: "10/01/2023",
    },
    {
      name: "state",
      desc: "State",
      value: "GA",
    },
    {
      name: "sicCode",
      desc: "Industry SIC Code",
      value: 112,
    },
    {
      name: "eligibleEmployeesSelection",
      desc: "Number of Employees",
      value: "Less Than 100",
    },
    {
      name: "employerContributionPercent",
      desc: "Employer Contribution",
      value: "100%",
    },
    {
      name: "deductible",
      desc: "Overall Plan Year Policy Deductible",
      value: 250,
    },
    {
      name: "familyDeductibleMultiplier",
      desc: "Family Max Deductible",
      value: "2x",
    },
    {
      name: "familyBenefitMultiplier",
      desc: "Family Max Benefit",
      value: "2x",
    },
    {
      name: "combinedBenefit",
      desc: "Combined Benefit Amount",
      value: 7000,
    },
    {
      name: "physiciansOfficeCopayOption",
      desc: "Physician Office Copay Option",
      value: {
        index: 0,
        desc: "No",
      },
    },
    {
      name: "coinsurancePercent",
      desc: "Member Coinsurance Percentage",
      value: "0%",
    },
    {
      name: "hasPhysiciansBenefit",
      desc: "Physician Office Visit Coverage",
      value: {
        index: 0,
        desc: "No",
      },
    },
    {
      name: "hasMentalHealthAndSubstanceAbuseCoverage",
      desc: "Mental Health and Substance Abuse",
      value: {
        index: 0,
        desc: "No",
      },
    },
  ];

  const fieldChubb = [
    {
      name: "groupEffectiveDate",
      desc: "Rate Effective Date",
      value: "10/01/2023",
    },
    {
      name: "state",
      desc: "State",
      value: "GA",
    },
    {
      name: "sicCode",
      desc: "Industry SIC Code",
      value: 112,
    },
    {
      name: "employerContributionPercent",
      desc: "Employer Contribution",
      value: "100%",
    },
    {
      name: "employerContributionAppliesTo",
      desc: "Contribution Applies To",
      value: "EE's & Dep's",
    },
    {
      name: "combinedBenefit",
      desc: "Combined Benefit Amount",
      value: 7000,
    },
    {
      name: "coinsurancePercent",
      desc: "GAP Coinsurance",
      value: "100%",
    },
    {
      name: "ipOpDeductible",
      desc: "IP/OP Deductible Amount",
      value: 250,
    },
    {
      name: "baseMedFamilyBenefitMultiplier",
      desc: "Fam Ben Max: Base Med Plan",
      value: "2X Traditional Ded",
    },
    {
      name: "gapMedFamilyBenefitMultiplier",
      desc: "Fam Ben Max: GAP Plan",
      value: "2X Traditional Ded",
    },
    {
      name: "hasPhysiciansBenefit",
      desc: "Physician Office Visit",
      value: {
        index: 0,
        desc: "No",
      },
    },
  ];
  const fieldShelter = [
    {
      name: "state",
      desc: "Group State",
      value: "GA",
    },
    {
      name: "sicCode",
      desc: "Industry SIC Code",
      value: 112,
    },
    {
      name: "groupEffectiveDate",
      desc: "Group Effective Date",
      value: "10/01/2023",
    },
    {
      name: "employerContributionPercent",
      desc: "Employer Contribution",
      value: "100%",
    },
    {
      name: "combinedBenefit",
      desc: "Combined Benefit Amount",
      value: 5000,
    },
    {
      name: "familyBenefitMultiplier",
      desc: "Family Benefit Max (multiple of individual)",
      value: "Family Max at 2X Individual",
    },
    {
      name: "deductible",
      desc: "Covered Individual's Deductible Amount",
      value: 250,
    },
    {
      name: "coinsurancePercent",
      desc: "Covered Individual's Coinsurance %",
      value: "0%",
    },
    {
      name: "physiciansBenefitSameAsAnyOther",
      desc: "POV Option 1: Covered as Any Other Illness out of OP Max",
      value: "Not Covered",
    },
    {
      name: "physiciansBenefit",
      desc: "",
      value: "Not Covered",
    },
    {
      name: "rxCoverageType",
      desc: "Rx Coverage Option 1: Covered as part of Outpatient Max",
      value: "Not Covered",
    },
    {
      name: "hasMentalHealthAndSubstanceAbuseCoverage",
      desc: "Mental Health and Substance Abuse",
      value: {
        index: 0,
        desc: "No",
      },
    },
  ];

  const fieldZurich = [
    {
      name: "groupEffectiveDate",
      desc: "Group Effective Date",
      value: "10/01/2023",
    },
    {
      name: "state",
      desc: "State",
      value: "GA",
    },
    {
      name: "sicCode",
      desc: "Industry SIC Code",
      value: 112,
    },
    {
      name: "employerContributionPercent",
      desc: "Employer Contribution",
      value: "100%",
    },
    {
      name: "deductibleWaivedForAccident",
      desc: "Deductible Waived For Accident",
      value: "No",
    },
    {
      name: "familyDeductibleMultiplier",
      desc: "Family Max Deductible",
      value: "2x",
    },
    {
      name: "deductible",
      desc: "Overall Plan Year Policy Deductible",
      value: 250,
    },
    {
      name: "combinedBenefit",
      desc: "Combined Benefit Amount",
      value: 2500,
    },
    {
      name: "coinsurancePercent",
      desc: "Overall Plan Coinsurance Percentage",
      value: "100%",
    },
    {
      name: "familyBenefitMultiplier",
      desc: "Family Max Benefit",
      value: "2x",
    },
    {
      name: "physiciansBenefitPerVisit",
      desc: "Doctor's Office Visit Max per Visit",
      value: 0,
    },
    {
      name: "physiciansAnnualVisits",
      desc: "Doctor's Office number of visits",
      value: 0,
    },
    {
      name: "officeVisitFamilyMax",
      desc: "Office Visit Family Max",
      value: "No Limit",
    },
  ];

  const fieldNationwide = [
    {
      name: "state",
      desc: "State",
      value: "GA",
    },
    {
      name: "groupEffectiveDate",
      desc: "Group Effective Date",
      value: "10/01/2023",
    },
    {
      name: "sicCode",
      desc: "Industry SIC Code",
      value: 112,
    },
    {
      name: "deductible",
      desc: "Overall Plan Year Policy Deductible",
      value: 250,
    },
    {
      name: "employerContributionPercent",
      desc: "Employer Contribution",
      value: "100%",
    },
    {
      name: "familyBenefitMultiplier",
      desc: "Annual Maximum Benefit per family",
      value: "2x",
    },
    {
      name: "combinedBenefit",
      desc: "Combined Benefit Amount",
      value: 5000,
    },
    {
      name: "eligibleEmployees",
      desc: "Eligible Employees (# Enrolled in Major Medical)",
      value: 120,
    },
    {
      name: "ratingMethod",
      desc: "Rating Method",
      value: "Composite",
    },
    {
      name: "hasRxBenefit",
      desc: "RX Benefit",
      value: {
        index: 0,
        desc: "No",
      },
    },
    {
      name: "hasPhysiciansBenefit",
      desc: "Coverage of Professional Office Fees",
      value: {
        index: 0,
        desc: "No",
      },
    },
    {
      name: "includeDeductible",
      desc: "Include Deductible?",
      value: {
        index: 0,
        desc: "Yes",
      },
    },
    {
      name: "physiciansBenefitPerVisit",
      desc: "Per Day Benefit Amount",
      value: 0,
    },
    {
      name: "coinsurancePercent",
      desc: "Coinsurance Amount (Policyholder)",
      value: "0%",
    },
    {
      name: "familyDeductibleMultiplier",
      desc: "Family Max Deductible",
      value: "2x",
    },
    {
      name: "hasMentalHealthAndSubstanceAbuseCoverage",
      desc: "MNSA Coverage",
      value: {
        index: 0,
        desc: "No",
      },
    },
  ];

  //var masterField = []

  function updateSavedProducts3(products) {
    // Deep clone to prevent mutation of the original object
    console.log("this og product");
    console.log(products);
    const productsUpdated = _.cloneDeep(products);

    console.log("checking the TYPE");
    console.log(productsUpdated);
    console.log(productsUpdated.gap);
    console.log(productsUpdated.gap.saved);

    console.log("THIS IS RATEDATA");

    console.log(productsUpdated.gap.saved[0].rateData.inputWithDescs);

    // productsUpdated.gap.isQQ = true

    console.log("this is the QQ checker");
    console.log(productsUpdated);
    // Define configuration to map providers to their respective fields and trackers
    const providerConfig = {
      markel: { field: fieldMarkel, tracker: markelPayloadTracker },
      shelter: { field: fieldShelter, tracker: shelterPayloadTracker },
      nationwide: { field: fieldNationwide, tracker: nationPayloadTracker },
      zurich: { field: fieldZurich, tracker: zurichPayloadTracker },
      chubb: { field: fieldChubb, tracker: chubbPayloadTracker },
    };

    // Iterate over each product in the saved array
    for (let product of productsUpdated.gap.saved) {
      const config = providerConfig[product.provider];

      console.log("THIS IS RATEDATA");
      console.log(product.rateData.inputWithDescs);

      if (config) {
        let fieldsUpdated = updateFields(config.field, config.tracker);
        product.fields = fieldsUpdated;

        if (config.tracker === chubbPayloadTracker) {
          const sicCodeValues = [
            "1611",
            "1799",
            "912",
            "972",
            "4952",
            "4959",
            "3812",
            "4212",
            "4231",
            "2411",
            "1011",
            "1311",
            "1241",
            "1389",
            "9229",
            "7941",
            "9211",
            "922",
          ];
          const numericSicCodeValues = sicCodeValues.map(Number);
          if (numericSicCodeValues.includes(chubbPayloadTracker.sicCode)) {
            setError(true);
            setMessage(
              "Invalid sicCode and provider combination. Contact your OptiMed Representative for further assistance"
            );
          }
        }

        console.log("this is config");
        console.log(config);

        console.log("this is product provider");
        console.log(product.provider);

        let fieldsObject = {};
        for (let field of fieldsUpdated) {
          if (typeof field.value === "object" && field.value.hasOwnProperty("desc")) {
            if (field.value.desc === "No") {
              fieldsObject[field.name] = false;
            } else if (field.value.desc === "Yes") {
              fieldsObject[field.name] = true;
            }
          } else {
            fieldsObject[field.name] = field.value;
          }
        }
        product.rateData.inputWithDescs = fieldsObject;

        // if (product.provider === "markel"){
        //   productsUpdated.gap.fields = fieldsUpdated;
        // }

        // Add updated fields to masterField without duplicates
        // for (let field of fieldsUpdated) {
        //     if (!masterField.some(master => master.name === field.name)) {
        //         masterField.push(field);
        //     }
        // }
      } else {
        console.error(`Error: Unrecognized provider ${product.provider}`);
        return `Error: Unrecognized provider ${product.provider}`; // or handle this differently if desired
      }
    }

    //productsUpdated.gap.fields = fieldMarkel;

    console.log(productsUpdated);
    setProducts(productsUpdated);
  }

  function updateSavedProducts(products) {
    // Deep clone to prevent mutation of the original object
    console.log("this og product");
    console.log(products);
    const productsUpdated = _.cloneDeep(products);

    console.log("checking the TYPE");
    console.log(productsUpdated);
    console.log(productsUpdated.gap);
    console.log(productsUpdated.gap.saved);

    console.log("THIS IS RATEDATA");

    console.log(productsUpdated.gap.saved[0].rateData.inputWithDescs);

    // productsUpdated.gap.isQQ = true

    console.log("this is the QQ checker");
    console.log(productsUpdated);
    // Define configuration to map providers to their respective fields and trackers
    const providerConfig = {
      markel: { field: fieldMarkel, tracker: markelPayloadTracker },
      shelter: { field: fieldShelter, tracker: shelterPayloadTracker },
      nationwide: { field: fieldNationwide, tracker: nationPayloadTracker },
      zurich: { field: fieldZurich, tracker: zurichPayloadTracker },
      chubb: { field: fieldChubb, tracker: chubbPayloadTracker },
    };

    // Iterate over each product in the saved array
    productsUpdated.gap.saved = productsUpdated.gap.saved.filter((product) => {
      const config = providerConfig[product.provider];

      if (!config) {
        console.error(`Error: Unrecognized provider ${product.provider}`);
        return false; // Exclude this product from the array
      }

      let fieldsUpdated = updateFields(config.field, config.tracker);
      product.fields = fieldsUpdated;

      if (config.tracker === chubbPayloadTracker) {
        const sicCodeValues = [
          "1611",
          "1799",
          "912",
          "972",
          "4952",
          "4959",
          "3812",
          "4212",
          "4231",
          "2411",
          "1011",
          "1311",
          "1241",
          "1389",
          "9229",
          "7941",
          "9211",
          "922",
        ];
        const numericSicCodeValues = sicCodeValues.map(Number);
        if (numericSicCodeValues.includes(chubbPayloadTracker.sicCode)) {
          setError(true);
          setMessage(
            "Invalid sicCode and provider combination. Chubb is not available for this sicCode, thus it will not be included Contact your OptiMed Representative for further assistance."
          );
          return false; // Exclude this product from the array
        }
      }

      // Mapping fields to fieldsObject
      let fieldsObject = {};
      for (let field of fieldsUpdated) {
        if (typeof field.value === "object" && field.value.hasOwnProperty("desc")) {
          if (field.value.desc === "No") {
            fieldsObject[field.name] = false;
          } else if (field.value.desc === "Yes") {
            fieldsObject[field.name] = true;
          }
        } else {
          fieldsObject[field.name] = field.value;
        }
      }
      product.rateData.inputWithDescs = fieldsObject;

      return true; // Include this product in the array
    });

    // Continue with the rest of your code
    // ...

    setProducts(productsUpdated);
  }

  function updateSavedProducts2(products) {
    // Deep clone to prevent mutation of the original object
    console.log("this og product");
    console.log(products);
    const productsUpdated = _.cloneDeep(products);

    console.log("checking the TYPE");
    console.log(productsUpdated);
    console.log(productsUpdated.gap);
    console.log(productsUpdated.gap.saved);

    //productsUpdated.gap.isQQ = true

    console.log("this is the QQ checker");
    console.log(productsUpdated);

    productsUpdated.gap.saved = productsUpdated.gap.saved.filter((product) => product.provider === "markel");
    // Define configuration to map providers to their respective fields and trackers
    const providerConfig = {
      markel: { field: fieldMarkel, tracker: markelPayloadTracker },
      shelter: { field: fieldShelter, tracker: shelterPayloadTracker },
      nationwide: { field: fieldNationwide, tracker: nationPayloadTracker },
      zurich: { field: fieldZurich, tracker: zurichPayloadTracker },
      chubb: { field: fieldChubb, tracker: chubbPayloadTracker },
    };

    // Iterate over each product in the saved array
    for (let product of productsUpdated.gap.saved) {
      const config = providerConfig[product.provider];

      if (config) {
        let fieldsUpdated = updateFields(config.field, config.tracker);
        product.fields = fieldsUpdated;

        console.log("this is config");
        console.log(config);

        console.log("this is product provider");
        console.log(product.provider);

        if (product.provider === "markel") {
          productsUpdated.gap.fields = fieldsUpdated;
        }

        // Add updated fields to masterField without duplicates
        // for (let field of fieldsUpdated) {
        //     if (!masterField.some(master => master.name === field.name)) {
        //         masterField.push(field);
        //     }
        // }
      } else {
        console.error(`Error: Unrecognized provider ${product.provider}`);
        return `Error: Unrecognized provider ${product.provider}`; // or handle this differently if desired
      }

      productsUpdated.gap.saved.forEach((product) => {
        productsUpdated.gap.benefits = product.benefits;
        productsUpdated.gap.plan = product.plan;
        productsUpdated.gap.provider = product.provider;
        productsUpdated.gap.rateData = product.rateData;
      });
    }

    //productsUpdated.gap.fields = fieldMarkel;

    console.log(productsUpdated);
    setProducts(productsUpdated);
  }

  useEffect(() => {
    console.log("run");
    if (isQQ) {
      updateSavedProducts(products);
    }
  }, []);

  // // To clone the array, you can use the spread operator:
  // const clonedFields = [...fields];
  // const originalObject = {
  //   coinsurancePercent: 0,
  //   combinedBenefit: 1500,
  //   deductible: 500,
  //   eligibleEmployeesSelection: "Less Than 100",
  //   employerContributionPercent: 1,
  //   familyBenefitMultiplier: "2x",
  //   familyDeductibleMultiplier: "No Limit",
  //   groupEffectiveDate: "10/01/2023",
  //   hasMentalHealthAndSubstanceAbuseCoverage: true,
  //   hasPhysiciansBenefit: false,
  //   physiciansOfficeCopayOption: false,
  //   sicCode: 112,
  //   state: "GA",
  // };

  function updateFields(field, payloadFromGAP) {
    field.forEach((item) => {
      if (payloadFromGAP.hasOwnProperty(item.name)) {
        if (
          ["physiciansOfficeCopayOption", "hasPhysiciansBenefit", "hasMentalHealthAndSubstanceAbuseCoverage"].includes(
            item.name
          )
        ) {
          // Ensure item.value is an object
          if (typeof item.value !== "object") {
            item.value = {};
          }

          // Set or overwrite the desc property
          item.value.desc = payloadFromGAP[item.name] ? "Yes" : "No";
        } else {
          item.value = payloadFromGAP[item.name];
        }
      }
    });
    return field;
  }

  console.log("products SHOULD BE fine NOW!!");
  console.log(products);

  // This is a method to discount rates based off broker discounts that are set in firebase.

  const applyDiscounts = () => {
    let multiplier;
    const key = "discount";
    const rates = ["composite", "fiftyPlus", "fortyToFortyNine", "underForty"];
    let productClone = _.cloneDeep(products);
    console.log("PRODUCT CLONE");
    console.log(productClone);

    Object.entries(productClone).forEach((products) => {
      console.log("FIRST");
      if (products[0] == "gap") {
        console.log("SECOND");
        if (products && products[1] && products[1].hasOwnProperty("saved")) {
          console.log("THIRD");
          Object.entries(products[1].saved).forEach((product) => {
            multiplier = getDiscount(discounts[product[1].provider]) || 1;
            console.log("DISCOUNT LOGS");
            console.log(products);
            if (product[1].provider === "shelter" && product[1].request.state === "MI") {
              multiplier = 1;
            }
            if (product[1].provider === "nationwide" && product[1].fields[1].value === "AL") {
              if (multiplier > 10) multiplier = 10;
            }
            product[1][key] = multiplier;
            if (multiplier != 1) console.log("Discount: " + multiplier);
            Object.entries(product[1].rateData.raw).forEach((rate) => {
              if (rates.includes(rate[0])) {
                Object.keys(rate[1]).forEach(function (key, index) {
                  rate[1][key] *= multiplier;
                });
              }
            });
          });
        }
      }
    });
    setProducts(productClone);
    return productClone;
  };

  const getDiscount = (discount) => {
    if (discount != -1) {
      discount = (100 - discount) / 100;
      return discount;
    }
    return 1;
  };

  const curateResults = () => {
    let arr = [];
    let numProductsSent = 0;
    Object.entries(products).forEach((product) => {
      if (!_.isEmpty(product[1]) && product[1].saved && product[1].saved.length > 0) {
        let numSent = 0;
        product[1].saved.forEach((x) => {
          if (x.sent) numSent += 1;
        });
        let obj = product[1];
        obj.name = product[0];
        if (numSent === product[1].saved.length) numProductsSent += 1;
        arr.push(obj);
      }
    });
    if (numProductsSent === arr.length) setHasSubmit(true);
    setResults(arr);
  };

  // Have to use the product clone from the discounted rates method to sve proper rates in results array

  const curateDiscountedResults = (productClone) => {
    let arr = [];
    let numProductsSent = 0;
    Object.entries(productClone).forEach((product) => {
      if (!_.isEmpty(product[1]) && product[1].saved && product[1].saved.length > 0) {
        let numSent = 0;
        product[1].saved.forEach((x) => {
          if (x.sent) numSent += 1;
        });
        let obj = product[1];
        obj.name = product[0];
        if (numSent === product[1].saved.length) numProductsSent += 1;
        arr.push(obj);
      }
    });
    if (numProductsSent === arr.length) setHasSubmit(true);
    setResults(arr);
    return arr;
  };

  /*NOTE:
    This function submits all valid plans that haven't been previously submitted to the CRM. If it is run locally, it simply simulates
    a send by having a one second load time. In production, the function adds all the valid products that hasn't been sent into an array
    and then attaches that array to the payload that goes out to the /storeRates endpoint through the postRates function.
    */

  function checkIfEmp() {
    if (userRole.includes("employee")) {
      setDialog(true);
    } else {
      return submitAllToCRM();
    }
  }

  console.log("this is ihp agent checker");

  const isIHPAgent = () => userRole.includes("ihp_agent");

  console.log(isIHPAgent());

  console.log("this is userRole");

  console.log(userRole);

  console.log("this is products before submit crm function");
  console.log(products);

  const checkProducts = (products) => {
    const additionalKeysArray = [];

    Object.keys(products).forEach((productKey) => {
      const product = products[productKey];
      const keys = Object.keys(product);

      // Filter out 'providers' and 'censusData'
      const additionalKeys = keys.filter((key) => key !== "providers" && key !== "censusData");

      // If there are additional keys, add them to the array
      if (additionalKeys.length > 0) {
        additionalKeysArray.push({
          product: productKey,
          keys: additionalKeys,
        });
      }
    });

    return additionalKeysArray;
  };

  var activeProducts = checkProducts(products);

  console.log("this is active products");
  console.log(activeProducts);

  console.log("gap");
  console.log(products.gap);

  console.log("gapcensus");
  console.log(gapCensus);

  console.log("4census");
  console.log(fourTierCensus);

  console.log("census");
  console.log(census);

  console.log("adminFees");
  console.log(adminFees);

  console.log("sendOptienhance");
  console.log(sendOptienhance);

  const handleUpdateAdminFee = () => {
    if (selectedProvider) {
      const newAdminFee = parseFloat(inputValue) || 0;
      setAdminFees((prevAdminFees) => ({
        ...prevAdminFees,
        [selectedProvider]: newAdminFee,
      }));
      console.log(`Updated admin fee for ${selectedProvider}`);
    }

    setOpenAdminFeeDialog(false);
    setShouldOpenOptienhanceDialog(true);
  };

  // Remove the findMatchingItems function and related code, as it's no longer needed

  // Update the rendering of MenuItem options in the Select component
  {
    activeProducts.map((product) => (
      <MenuItem key={product.product} value={product.product}>
        {product.product}
      </MenuItem>
    ));
  }

  Object.entries(products).forEach(([key, productobj]) => {
    // Add censusData field to each product object
    if (
      key === "rx" ||
      key === "ihp" ||
      key === "ihp_agent" ||
      key === "ltdmed" ||
      key === "mec" ||
      key === "emec" ||
      key === "travelers"
    ) {
      products[key].censusData = fourTierCensus;
    }
  });

  console.log(products);

  const submitAllToCRM = () => {
    const discountedProducts = applyDiscounts() != null ? applyDiscounts() : products;

    console.log("this is discounted products");

    console.log(discountedProducts);

    let productClone = _.cloneDeep(discountedProducts);
    setLoading(true);
    let productArr = [];
    console.log("productClone: ", productClone);
    setDisabled(true);
    setDialog(false);

    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      setTimeout(() => {
        setHasSubmit(true);
        setSuccess(true);
        setMessage(
          "All plans have been submitted to us for processing. You should receive an email shortly with illustrative proposals. If you do not receive your quotes via email, please contact us."
        );
        setLoading(false);
        setDialog(false);

        Object.entries(productClone).forEach((product) => {
          if (product[1].saved) {
            product[1].saved.map((x) => {
              x.sent = true;
            });
          }
        });
        setProducts(productClone);
      }, 1000);
    } else {
      let arr = [];
      let result = curateDiscountedResults(productClone);
      result.forEach((productType) => {
        productClone[productType.name].sent = true;
        productType.saved.forEach((product) => {
          console.log("this is product");
          console.log(product);

          let censusData =
            product.name.toLowerCase() === "gap" ||
            product.name.toLowerCase() === "rx" ||
            product.name.toLowerCase() === "travelers"
              ? removeNullValues(gapCensus)
              : requiredLevel < 3
              ? removeNullValues(fourTierCensus)
              : census;
          let adminFee = calcAdminFee(product, adminFees);

          console.log("Census Data: ", censusData);
          console.log("Admin Fee: ", adminFee);

          console.log("this is result");

          console.log(result);

          let obj = {
            type: product.name,
            adminFee: (adminFee || 0).toString(),
            brokerCommission: product.brokerFee ? product.brokerFee.toString() : "0",
            carrier: product.provider,
            plan: product.plan,
            discount: product.discount ? product.discount.toString() : "0.0",
            data: {
              request: {
                censusData: censusData,
                inputData: product.rateData.inputWithDescs,
                discount: product.discount ? product.discount.toString() : "1.0",
              },
              response: product.rateData.raw,
            },
            addons: _.map(product.addons, (addon) => addon.id),
          };

          console.log("this is object");
          console.log(obj);

          console.log("this is Product Array");
          console.log(productArr);

          console.log("this is Product");
          console.log(product);

          console.log("this is arr");
          console.log(arr);

          if (!product.sent) {
            productArr.push(obj);
            arr.push(product);

            console.log("this is Product Array");
            console.log(productArr);

            console.log("this is Product");
            console.log(product);

            console.log("this is arr");
            console.log(arr);
          } else {
            console.log(
              "Looks like this product has already been sent and therefore won't be added to the payload.",
              product
            );

            console.log("this is Product Array");
            console.log(productArr);

            console.log("this is Product");
            console.log(product);

            console.log("this is arr");
            console.log(arr);
          }
        });
      });

      let payload = {
        opportunityId,
        products: productArr,
      };

      console.log("THIS IS PAYLOAD");
      console.log(payload);

      if (
        submitState.sendToSelfEmp === true ||
        _.filter(productArr, (product) => product.carrier.startsWith("markel")).length > 0
      ) {
        payload.sendToSubmitter = true;
      }

      console.log("this is submit State");
      console.log(submitState);

      if (submitState.sendtoHSA === true) {
        payload.includeAccountAdminQuote = true;
      }

      payload.products.forEach((product) => {
        product.includeGeneralTeleHealthQuote = false;
        product.includeDermTeleHealthQuote = false;
        product.includeRecurro = false;
      });

      console.log("this is userCRMid");
      console.log(userCRMid);

      var acceptableCRMID = [
        "1f3e1e8a-1f79-e711-811d-e0071b6a5131",
        "b7ac3325-fc4b-ed11-bba2-00224822f335",
        "b7ac3325-fc4b-ed11-bba2-00224822f335",
      ];

      console.log("this is selectedBroker checker");
      console.log(acceptableCRMID.includes(selectedBroker));

      // if (userCRMid) {
      if (acceptableCRMID.includes(selectedBroker)) {
        payload.products.forEach((product) => {
          if (submitState.sendtoOptienhance === true) {
            product.includeGeneralTeleHealthQuote = true;
            product.includeDermTeleHealthQuote = true;
            product.includeRecurro = true;
          } else {
            product.includeGeneralTeleHealthQuote = false;
            product.includeDermTeleHealthQuote = false;
            product.includeRecurro = false;
          }
        });
      } else {
        payload.products.forEach((product) => {
          if (submitState.sendtoOptienhance === true) {
            product.includeGeneralTeleHealthQuote = true;
            product.includeDermTeleHealthQuote = true;
            product.includeRecurro = false;
          } else {
            product.includeGeneralTeleHealthQuote = false;
            product.includeDermTeleHealthQuote = false;
            product.includeRecurro = false;
          }
        });
      }

      console.log("Payload: ", payload);

      console.log("this is restConfig");

      console.log(restConfig);

      console.log("this is productClone");

      console.log(productClone);

      function postRatesWithRetry(payload, restConfig, retries = 5) {
        return new Promise((resolve, reject) => {
          function attempt() {
            postRates(payload, restConfig)
              .then(resolve)
              .catch((err) => {
                if (err.response && err.response.status === 500 && retries > 0) {
                  console.log(`Attempt failed with 500 error, retrying... ${retries} retries left.`);
                  console.log("500 error");
                  retries--;
                  setTimeout(attempt, 1000); // Wait for 2 seconds before retrying
                } else {
                  reject(err);
                }
              });
          }
          attempt(); // Start the first attempt
        });
      }

      // Usage of postRatesWithRetry
      postRatesWithRetry(payload, restConfig)
        .then((res) => {
          // Same success handling as before
          Object.entries(productClone).forEach((product) => {
            console.log("logging every product");
            console.log(product);
            console.log("value");
            console.log(product[1]);
            console.log("saved");
            console.log(product[1].saved);
            if (product[1].saved) {
              product[1].saved.map((x) => {
                console.log(x);
                x.sent = true;
              });
            }
          });
          log("store_rates", payload);
          setProducts(productClone);
          setLoading(false);
          setSuccess(true);
          setMessage("All plans submitted successfully");
        })
        .catch((err) => {
          // Same error handling as before
          setLoading(false);
          setError(true);
          setDisabled(false);
          webError({
            text: "Something went wrong when trying to post rates to CRM.",
            payload,
            err: err.response ? err.response.data : "",
          });
          setMessage("Something went wrong. If the problem persists, please contact support");
        });
    }
  };

  return (
    <div className={classes.paper}>
      <Grid container style={{}} alignItems={"center"} alignContent={"center"}>
        {results && results.length === 0 && (
          <>
            <Grid item xs={12}>
              <Typography align={"center"}>
                No results found. Please finish configuring your products on the previous tab.
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Button
                fullWidth
                onClick={() => setValue(4)}
                variant={"contained"}
                color={"primary"}
                style={{ backgroundColor: "#0C2659" }}
              >
                Go Back to building my products
              </Button>
            </Grid>
          </>
        )}
        {results && results.length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography variant={"h4"} style={{ fontWeight: "bold" }}>
                Review And Submit Quotes
              </Typography>
              <Typography>
                Review your plans and submit them to receive an email with a proposal for each quote.
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
      <Grid container item xs={12} justify={"center"} alignItems={"center"} spacing={1}>
        {results && results.length > 0 && (
          <Grid item xs={10}>
            <Button
              color={"#0C2659"}
              disabled={disabled || hasSubmit}
              fullWidth
              variant={"contained"}
              onClick={() => checkIfEmp()}
            >
              click here To receive quotes for all of the products below
            </Button>
          </Grid>
        )}
        {/* 
        <Grid item container xs={12} spacing={1} justify={"center"}>
          {results &&
            results.length > 0 &&
            results.map((result) => {
              return <ResultProduct notify={submitState.sendtoHSA} key={JSON.stringify(result)} result={result} />;
            })}
        </Grid> */}

        <Grid item container xs={12} spacing={1} justify={"center"}>
          {results &&
            results.length > 0 &&
            results.map((result) => {
              return (
                <ResultProduct notify={submitState.sendtoOptienhance} key={JSON.stringify(result)} result={result} />
              );
            })}
        </Grid>

        {results && results.length > 0 && (
          <Grid item xs={10}>
            <Button
              color={"#0C2659"}
              disabled={disabled || hasSubmit}
              fullWidth
              variant={"contained"}
              onClick={() => checkIfEmp()}
              style={{ backgroundColor: "#0C2659", color: "#FFFFFF" }}
            >
              click here to receive quotes for all of the products above
            </Button>
          </Grid>
        )}
        {hasSubmit && results.length > 0 && (
          <Grid item xs={10}>
            <Typography gutterBottom align={"center"} color={"primary"}>
              Quotes have been generated and have been sent to your email.
            </Typography>
            <Button
              color="primary"
              style={{ backgroundColor: "#0C2659" }}
              disabled={loading}
              fullWidth
              variant={"contained"}
              onClick={reset}
            >
              Click here to start over
            </Button>
          </Grid>
        )}
        <div>
          <Dialog open={dialog}>
            <DialogActions className={classes.dialog}>
              <Grid container direction="column" justify="space-between" alignItems="center">
                <FormLabel component="legend">One More Thing</FormLabel>
                <br />
                <FormGroup>
                  {userRole.includes("employee") ? (
                    <FormControlLabel
                      control={<Checkbox checked={sendToSelfEmp} onChange={handleChange} name="sendToSelfEmp" />}
                      label="It looks like you're an employee. Check the box if you want to email the proposal to yourself."
                    />
                  ) : null}
                </FormGroup>
              </Grid>
            </DialogActions>
            <DialogActions>
              <Button
                onClick={(e) => {
                  setDialog(false);
                }}
                color="#0C2659"
                style={{ backgroundColor: "#0C2659", color: "#FFFFFF" }}
              >
                Back
              </Button>
              <Button
                onClick={(e) => {
                  submitAllToCRM();
                }}
                color="#0C2659"
                style={{ backgroundColor: "#0C2659", color: "#FFFFFF" }}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          {isIHPAgent() && HSADialog && (
            <Dialog open={HSADialog}>
              <DialogActions className={classes.dialog}>
                <Grid container direction="column" justify="space-between" alignItems="center">
                  <FormLabel component="legend">Before you continue</FormLabel>
                  <br />
                  <FormGroup>
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox checked={sendtoHSA} color="primary" onChange={handleChange} name="sendtoHSA" />
                      }
                      label="Would you like an HRA/HSA/FSA administration quote included with your proposal(s)?"
                    />
                  </FormGroup>
                </Grid>
              </DialogActions>
              <DialogActions>
                <Button
                  onClick={(e) => {
                    setHSADialog(false);
                    setRxDialog(true);
                  }}
                  color="primary"
                  style={{ backgroundColor: "#0C2659", color: "#FFFFFF" }}
                >
                  Continue
                </Button>
              </DialogActions>
            </Dialog>
          )}

          {openAdminFeeDialog && (
            <Dialog open={openAdminFeeDialog} onClose={() => setOpenAdminFeeDialog(false)}>
              <DialogActions className={classes.dialog}>
                <Grid container direction="column" justify="space-between" alignItems="center">
                  <FormLabel component="legend">Update Admin Fee</FormLabel>
                  <br />
                  <FormGroup>
                    <Select
                      label="Provider"
                      value={selectedProvider}
                      onChange={(e) => {
                        setSelectedProvider(e.target.value);
                        setInputValue((adminFees[e.target.value] || 10).toString());
                      }}
                      fullWidth
                    >
                      {activeProducts.map((product) => (
                        <MenuItem key={product.product} value={product.product}>
                          {product.product}
                        </MenuItem>
                      ))}
                    </Select>
                    <TextField
                      label="New Admin Fee"
                      value={inputValue}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setInputValue(newValue);
                      }}
                      type="number"
                      fullWidth
                      helperText="Minimum admin fee is 10"
                      error={parseFloat(inputValue) < 10}
                    />
                  </FormGroup>
                </Grid>
              </DialogActions>
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpenAdminFeeDialog(false);
                    setShouldOpenOptienhanceDialog(true);
                  }}
                  color="#0C2659"
                  style={{ backgroundColor: "#0C2659", color: "#FFFFFF" }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleUpdateAdminFee}
                  color="#0C2659"
                  style={{ backgroundColor: "#0C2659", color: "#FFFFFF" }}
                  disabled={parseFloat(inputValue) < 10}
                >
                  Update
                </Button>
              </DialogActions>
            </Dialog>
          )}

          {!openAdminFeeDialog && optienhanceDialog && (
            <Dialog open={optienhanceDialog}>
              <DialogActions className={classes.dialog}>
                <Grid container direction="column" justify="space-between" alignItems="center">
                  <FormLabel component="legend">Before you continue</FormLabel>
                  <br />
                  <FormGroup>
                    <br />
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sendtoOptienhance}
                            color="primary"
                            onChange={handleChange}
                            name="sendtoOptienhance"
                          />
                        }
                        label="Include OptiEnhance Telemedicine in your quote?"
                      />
                      <Tooltip
                        title={
                          "OptiEnhance Telemedicine can be included in your quote. Click to see Telemedicine product details"
                        }
                        placement={"top"}
                      >
                        <IconButton onClick={handleClickOptiEnhance}>
                          <InfoIcon style={{ color: "#0C2659" }} />
                        </IconButton>
                      </Tooltip>
                    </div>

                    {showOptiEnhance && (
                      <div>
                        <h2>OptiEnhance</h2>
                        <p>
                          OptiEnhance provides access to board-certified, credentialed physicians anytime, anywhere in
                          the United States. Physicians are always available to diagnose a large variety of acute
                          ailments, provide treatment plans and prescribe medication, when deemed necessary, and provide
                          follow-up consultations when needed. Many of our members receive help within 15 minutes.
                        </p>

                        <h3>Services</h3>
                        <ul>
                          <li>Virtual Urgent Care - Available 24/7/365</li>
                          <li>Over 70% of medical issues successfully treated</li>
                          <li>Dermatology - No consultation fee, included for free</li>
                          <li>
                            Behavioral Health – industry-leading/ first two sessions are free with master-level
                            clinicians available for immediate care Dermatology TeleHealth (Included) Staffed with
                            board-certified physicians and dermatologists, OptiEnhance provides quality, convenient care
                            that adequately treats a variety of minor skin conditions.
                          </li>
                        </ul>
                        <h3>TeleHealth Solutions</h3>
                        <p>
                          Receive help in as little as 15 minutes. Medical consultations available via telephone or
                          bi-directional video, 24/7/365. Affordable and responsive care for various skin conditions and
                          more.
                        </p>
                        <p>
                          Utilize our secure portal to upload images for an accurate diagnosis. Follow-up medical
                          consultations available when necessary.
                        </p>
                      </div>
                    )}
                  </FormGroup>
                </Grid>
              </DialogActions>
              <DialogActions>
                <Button
                  onClick={(e) => {
                    setOptienhanceDialog(false);
                    // setRxDialog(true);
                  }}
                  color="primary"
                  style={{ backgroundColor: "#0C2659", color: "#FFFFFF" }}
                >
                  Continue
                </Button>
              </DialogActions>
            </Dialog>
          )}

          {isIHPAgent() && rxDialog && (
            <Dialog open={rxDialog}>
              <DialogActions className={classes.dialog}>
                <Grid container direction="column" justify="space-between" alignItems="center">
                  <FormLabel component="legend">Before you continue</FormLabel>
                  <br />
                  <FormGroup>
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={general_teleHealth}
                          color="primary"
                          onChange={handleRxChange}
                          name="general_teleHealth"
                          disabled={true}
                        />
                      }
                      label="General TeleHealth"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={dermatology_teleHealth}
                          color="primary"
                          onChange={handleRxChange}
                          name="dermatology_teleHealth"
                          disabled={true}
                        />
                      }
                      label="Dermatology TeleHealth"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={medical_pricing_transparency}
                          color="primary"
                          onChange={handleRxChange}
                          name="medical_pricing_transparency"
                          disabled={true}
                        />
                      }
                      label="Medical Pricing Transparency"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={medical_bill_finance_guidance}
                          color="primary"
                          onChange={handleRxChange}
                          name="medical_bill_finance_guidance"
                          disabled={true}
                        />
                      }
                      label="Medical Bill Finance Guidance"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={lifestyle_rx}
                          color="primary"
                          onChange={handleRxChange}
                          name="lifestyle_rx"
                        />
                      }
                      label="Lifestyle Rx"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={mental_health_teleHealth}
                          color="primary"
                          onChange={handleRxChange}
                          name="mental_health_teleHealth"
                        />
                      }
                      label="Mental Health TeleHealth"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={teleHealth_wellness_coaching}
                          color="primary"
                          onChange={handleRxChange}
                          name="teleHealth_wellness_coaching"
                        />
                      }
                      label="TeleHealth Wellness Coaching"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={mri_imaging} color="primary" onChange={handleRxChange} name="mri_imaging" />
                      }
                      label="Best Price - MRI & Imaging"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={medical_bill_negotiation}
                          color="primary"
                          onChange={handleRxChange}
                          name="medical_bill_negotiation"
                        />
                      }
                      label="Medical Bill Negotiation"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={electronic_medical_records}
                          color="primary"
                          onChange={handleRxChange}
                          name="electronic_medical_records"
                        />
                      }
                      label="Electronic Medical Records"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={virtual_id_wallet}
                          color="primary"
                          onChange={handleRxChange}
                          name="virtual_id_wallet"
                        />
                      }
                      label="Virtual ID Wallet"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={insurance_plan_verification}
                          color="primary"
                          onChange={handleRxChange}
                          name="insurance_plan_verification"
                        />
                      }
                      label="Insurance Plan Verification"
                    />
                  </FormGroup>
                </Grid>
              </DialogActions>
              <DialogActions>
                <Button
                  onClick={(e) => {
                    setRxDialog(false);
                    submitRx();
                  }}
                  color="primary"
                  style={{ backgroundColor: "#0C2659", color: "#FFFFFF" }}
                >
                  Continue
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
      </Grid>
    </div>
  );
}

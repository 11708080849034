import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Grid from "@material-ui/core/Grid";
import {RaterContext} from "../RaterV3";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "react-select";
import {SICs} from "../Assets/SICs";
import makeStyles from "@material-ui/core/styles/makeStyles";
import states from "../../utils/States";

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%',
    },
}))


const dateOptions = [
    "05/01/2020",
    "06/01/2020",
    "07/01/2020",
    "08/01/2020",
    "09/01/2020",
    "10/01/2020",
    "11/01/2020",
    "12/01/2020",
    "01/01/2021",
    "02/01/2021",
    "03/01/2021",
]

const selectStyles = { menu: styles => ({ ...styles, zIndex: "1 !important"})};


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SettingsModal(props) {
    const { setZipCode, setSicCode, setGroupEffectiveDate, setState, setValue} = React.useContext(RaterContext);
    const classes = useStyles()

    const [zip, setZip] = React.useState('')
    const [sic, setSic] = React.useState('')
    const [ged, setGed] = React.useState('')
    const [thisState, setThisState] = React.useState('')

    const submit = () => {
        setGroupEffectiveDate(ged.value)
        setSicCode(sic.value)
        setZipCode(zip)
        setState(thisState.value)
        setValue(1)
        props.close()
    }


    return (
        <div>
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => props.close()}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">Essential Information</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Grid item container xs={12} spacing={1} justify={'center'}>
                        <Grid item xs={12}>
                            <Typography variant={'h6'}>We need some more information.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField value={zip} onChange={e => setZip(e.target.value)} label={"Zip Code"} variant={'outlined'} fullWidth/>
                        </Grid>
                        <Grid item xs={12}style={{zIndex: 5}}>
                            <FormControl className={classes.formControl} required>
                                <Select placeholder='State' value={thisState} onChange={(e) => setThisState(e)} maxMenuHeight={150} options={states} styles={selectStyles}/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} style={{zIndex: 4}}>
                            <FormControl className={classes.formControl} required>
                                <Select placeholder='Group Effective Date' value={ged} onChange={(e) => setGed(e)} maxMenuHeight={150} options={dateOptions.map(x => {
                                    return {label: x, value: x}
                                })} styles={selectStyles}/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} style={{zIndex: 1}}>
                            <FormControl className={classes.formControl} required>
                                <Select placeholder='SIC code' value={sic} onChange={(e) => setSic(e)} maxMenuHeight={150} options={SICs} styles={selectStyles}/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={8}>
                            <Button style={{backgroundColor: '#0C2659', color: '#FFFFFF'}} fullWidth onClick={submit} variant={'contained'} color={'#0C2659'} disabled={sic === '' || ged === '' || zip === '' || zip.length !== 5 || thisState === ''}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </div>
    );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import IconButton from "@material-ui/core/IconButton";
import InfoModal from "../../Modals/InfoModal";
import { RaterContext } from "../../RaterV3";
import { RatingEngineContext } from "react-rating-engine";
import clsx from "clsx";
import { prettifyProduct } from "../../../utils/prettifyFunctions";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 90,
    height: "100%",
    width: "100%",
  },
  hover: {
    "&:hover": {
      border: `1px solid ${theme.palette.primary.main}`,
      cursor: "pointer",
      opacity: 0.9,
      color: theme.palette.primary.main,
    },
  },
}));

export default function ProductSelect(props) {
  const classes = useStyles();
  const { productDescriptionMapping, loading } = React.useContext(RaterContext);
  const { walking } = React.useContext(RatingEngineContext);
  const [openInfo, setOpenInfo] = React.useState(false);
  const { sendAlert } = React.useContext(RaterContext);

  const nameToDescMapping = (name) => {
    let nameToUse = name;

    switch (name) {
      case "cfl":
        nameToUse = "caregiver family leave";
        break;

      default:
        break;
    }

    return nameToUse;
  };

  /* NOTE: This can't be carrier specific seeing that for products like Gap the carrier is selected later */

  const getNotAvailableMessage = (productName) => {
    let content =
      "Underwriting guidelines are not allowing this product to be rated online. Please contact OptiMed Health at 800-482-8770 or email sales@OptiMedHealth.com";

    switch (productName) {
      case "cfl":
        content =
          "Underwriting guidelines are not allowing this product to be rated online. Please send a message to cflquotes@dispec.com with your name and phone number, and a representative will contact you shortly.";
        break;

      default:
        break;
    }

    return content;
  };

  React.useEffect(() => {
    if (props.unavailable && props.name === "cfl") {
      sendAlert(props.name);
    }
  }, []);

  return (
    <Grid
      item
      container
      xs={12}
      md={props.fullWidth ? 12 : 4}
      justify={"center"}
      alignItems={"center"}
    >
      <Card
        raised
        className={clsx(classes.root, !walking && !loading && classes.hover)}
        onClick={() => props.configure({ ...props })}
        variant="outlined"
        style={{
          border: props.active
            ? `3px solid black`
            : props.progress === 1
            ? `2px solid black`
            : "",
          background: props.unavailable ? "#EFEFEF" : "",
        }}
        elevation={5}
      >
        <CardContent>
          <Grid
            container
            justify={"space-around"}
            alignContent={"center"}
            alignItems={"center"}
          >
            <Grid item xs={8}>
              <Typography variant="h6">
                {prettifyProduct(props.name)} &nbsp;
              </Typography>
              <Typography color={"primary"} variant={"caption"}>
                {props.saved && props.saved.length > 0
                  ? `Number of plans: ${props.saved.length}`
                  : ""}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {props.saved && props.saved.length > 0 && (
                <IconButton
                  color={"#0C2659"}
                  style={{ backgroundColor: "#0C2659" }}
                >
                  <DoneOutlineIcon style={{ color: "#0C2659" }} />
                </IconButton>
              )}
            </Grid>
            <Grid item xs={2}>
              <Typography>
                <IconButton color={"#0C2659"} onClick={() => setOpenInfo(true)}>
                  <InfoOutlinedIcon style={{ color: "#0C2659" }} />
                </IconButton>
              </Typography>
            </Grid>
            {props.unavailable && (
              <Grid item xs={12}>
                <Typography variant={"caption"} gutterBottom>
                  {getNotAvailableMessage(props.name)}
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      <InfoModal
        open={openInfo}
        close={() => setOpenInfo(false)}
        {...productDescriptionMapping[
          nameToDescMapping(prettifyProduct(props.name).toLowerCase())
        ]}
        name={prettifyProduct(props.name)}
      />
    </Grid>
  );
}

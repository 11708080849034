import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {RaterContext} from "../RaterV3";

const releases = _.reverse([
    {version: '1.0.0', date: 'June 1st, 2020',
        items: [
           'Initial Release of the OptiRater',
            'Gave users the ability to quote multiple products at a time.',
            'Allowed users to use previously created groups to quote more products',
            'Gave users the option to upload a census file with important information to quote relevant products.'
        ]},
    {version: '1.0.1', date: 'June 10th, 2020',
        items: [
            'Added Broker and Admin Fees to all products.',
            'List of saved products changed to a carousel for spacing and styling.',
            'Improved error handling across OptiRater',
            'General Bug Fixes',
        ]},
    {version: '1.0.2', date: 'June 26th, 2020',
        items: [
            'Level Funded Health Plans added with full Rates.',
            'Layout and styling changes for steps five and six.',
            'Improved styling for all saved plans',
            'Updated step 2 to match the requirements given by excel raters',
            'General Bug Fixes',
        ]},
    {version: '1.0.3', date: 'July 1st, 2020',
        items: [
            'Automatic Parsing of full census into tiered census added.',
            'Added group info verification with CRM to ensure validity of quoted groups',
            'Removed automatic loading in of previously saved products.',
            'Improved styling of all tooltips with larger and nicer text',
            'RFP Bug fixes'

        ]},
    {version: '1.0.4', date: 'July 15th, 2020',
        items: [
            'Updated network calls to be more secure and consistent',
            'Added IE11 Browser compatibility',
            'Reformatted Level Funded Health Plans Rates',
        ]},

    {version: '1.1.0', date: 'August 13th, 2020',
        items: [
            'Gave users the ability to select "No Preference" as a GAP carrier and quote multiple products at once',
            'Added in STD and LTD Product options.',
            'Fixed RFP Email Validation for brokers',
            'Improved census validation when using the Optimed Census format'

        ]},
    {version: '1.1.1', date: 'August 19th, 2020',
        items: [
            'Changed the styling of RFP Creation',
            'Added autocomplete for addresses in RFP using Google Maps',
            'Added error logging for any significant error that occurs with OptiRater for better customer support',
            'Minor bug fixes'

        ]},

    {version: '1.1.2', date: 'September 2nd, 2020',
        items: [
            'Added release notes to update user on changes to the OptiRater',
            'Updated product names to be more descriptive',
            'Changed general layout of logo and copyright'

        ]},
    {version: '1.1.3', date: 'October 1st, 2020',
        items: [
            'Added customer support chat for 24/7 assistance',
            'GAP ShelterPoint products now support multiple plans',
            'Minor styling and nomenclature related changes'

        ]},
    {version: '1.1.4', date: 'October 22nd, 2020',
        items: [
            'Added Broker List for employees',
            'Updated RFP validation and control',
            'Minor styling and nomenclature related changes'

        ]},
    {version: '1.1.5', date: 'November 19nd, 2020',
        items: [
            'Backend changed for optimizing performance',
            'Changed UI to return static proposal for all LM Plans',
            'Fixed bug that allowed incorrect Zip code/State combinations to get passed through.',
            'Internal Users - Added the ability to email proposals to themselves.',
            'Added function - When group ID is entered, automatically pull up saved census information for that group.',
            'Added Function - Added prompt for HSA quotes before proposal submission '

        ]},
    {version: '1.1.6', date: 'December 3rd, 2020',
        items: [
            'Minor changes to backend and UI interaction.',
            'Fixed issue with displaying incorrect percentages on rate results',
            'Fixed issue allowing the user to submit duplicate rates to CRM.'
        ]},
    {version: '011142021.175', date: 'January 7th, 2021',
        items: [
            'Minor grammatical changes',
            'Update version history',
            'Updated app version'
        ]},
    {version: '02112021.176', date: 'February 11th, 2021',
        items: [
            'Major Styling Updates.',
            'Fixed issue with negative numbers inside of census.',
            'Moved release notes from help popup to the top toolbar.'

        ]},
    {version: '02232021.177', date: 'February 23rd, 2021',
        items: [
            'Fixed an issue that cause errors when searching for an invalid address.'

        ]},
    {version: '02262021.226', date: 'March 4th, 2021',
        items: [
            'Fixed an issue that caused errors for internal Optimed employees',
            'General UI updates'
        ]},
    {version: 'v05152021.228', date: 'May 15th, 2021',
        items: [
            'Fixed an issue that allowed invalid members to be uploaded in censuses'
        ]},
    {version: 'v05312021.229', date: 'May 31th, 2021',
        items: [
            'Remove Vision card from Products screen',
            'Ability to change Broker (employee’s only)',
            'Census correction to prevent less than 10 years old',
            'Correct product availablity document and remove link to public product page'
        ]
    },
    {version: 'v0603021.230', date: 'June 3rd, 2021',
        items: [
            'Change MEC to require eligibility count only census'
        ]
    },
    {version: 'v0729021.231', date: 'July 29th, 2021',
        items: [
            'Remove ability to quote SD'
        ]
    },
    {version: 'v08252021.232', date: 'August 25th, 2021',
        items: [
            'Enable internal rating features'
        ]
    },
    {version: 'v09022021.233', date: 'September 2nd, 2021',
        items: [
            'Internal product name change'
        ]
    },
    {version: 'v10072021.234', date: 'October 7nd, 2021',
        items: [
            'Addition of CFL product'
        ]
    },
    {version: 'v12282021.235', date: 'December 28th, 2021',
        items: [
            'Remove GAP No Preference Option'
        ]
    },
    {version: 'v06252022.236', date: 'June 25th, 2022',
        items: [
            'Updated UI and Theme Design.'
        ]
    },
])

export default function ReleaseNotes() {
    const {releaseOpen, setReleaseOpen} = React.useContext(RaterContext)
    return(
        <Dialog
            open={releaseOpen}
            onClose={() => setReleaseOpen(false)}
            aria-labelledby="scroll-dialog-title"
        >
            <DialogTitle id="scroll-dialog-title">Release History</DialogTitle>
            <DialogContent>
                {releases.map(release => {
                    return (
                        <ExpansionPanel key={JSON.stringify(release)}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography >Version {release.version} {release.date}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <ul>
                                    {release.items.map(item => {
                                        return <li key={JSON.stringify(item)}>{item}</li>
                                    })}
                                </ul>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    )
                })}
            </DialogContent>
            <DialogActions style={{justifyContent: 'flex-start'}}>
                <Button onClick={() => setReleaseOpen(false)} style={{backgroundColor: '#0C2659', color: '#FFFFFF'}} color="primary" variant={'contained'}>
                    Back
                </Button>
            </DialogActions>
        </Dialog>
    )
}

import React from "react";
import {RaterContext} from "../../RaterV3";
import _ from "lodash";
import {Grid, Typography} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckIcon from "@material-ui/icons/Check";
import Collapse from "@material-ui/core/Collapse";
import ProductsCarousel from "../StepFive/ProductCarousel";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core/styles";
import {prettifyProduct} from "../../../utils/prettifyFunctions";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        margin: theme.spacing(2)
    },
    link : {
        '&:hover': {
            color: theme.palette.secondary.main,
            cursor: 'pointer'
        }
    }
}))

export const ResultProduct = props => {
    const {products, setProducts} = React.useContext(RaterContext)
    const [sent, setSent] = React.useState(false)
    const [expanded, setExpanded] = React.useState(true)

    //When all the products are submitted to CRM, we close up the results card to be more efficient.
    React.useEffect(() => {
        sent ? setExpanded(false):console.log("Not sent")
    }, [sent]);

    /*NOTE:
    useEffect function that runs and checks if every time the props passed down are changed that loops through the results
    and checks if the entire row of saved plans have already been sent. If so, the sent object is set to true which collapses
    the section and all savedPlanCards will reflect a sent status with the green banner.
     */
    React.useEffect(() => {
        checkIfSent()
    }, [props])


    const checkIfSent = () => {
        let numSent = 0
        let obj = _.cloneDeep(products);
        let arr = obj[props.result.name].saved
        arr.forEach(row => {
            row.sent ? numSent++:console.log('')
        })
        if (numSent === arr.length) {
            setSent(true)
        }
    }


    /*NOTE:
    Function the allows you to delete a product from the results page the same way you would from the previous page.
    To add the product over again, user has to go back to step 5.
     */
    const deleteSaved = (index) => {
        let obj = _.cloneDeep(products);
        let arr = obj[props.result.name].saved;
        arr.splice(index,1);
        obj[props.result.name].saved = arr;
        setProducts(obj)
    }


    const classes = useStyles();
    return (
        <div className={classes.paper}>
            <Grid item xs={12} container justify={'center'} spacing={1}>
                <Grid item xs={11} className={classes.title}>
                    <Typography onClick={() => setExpanded(!expanded)} color={'primary'} className={classes.link} variant={'h6'} align={'left'}>
                        {prettifyProduct(props.result.name)} PLANS
                        <IconButton onClick={() => setExpanded(!expanded)} color={'#0C2659'} >
                            {expanded ? <ExpandLessIcon style={{color: '#0C2659'}}/>:<ExpandMoreIcon style={{color: '#0C2659'}}/>}
                        </IconButton>
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    {props.result.sent && <CheckIcon color={'primary'}/>}
                </Grid>
                <Collapse in={expanded}>
                    <ProductsCarousel notify = {props.notify} productArray={props.result.saved} submit deleteSaved={deleteSaved}/>
                </Collapse>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
            </Grid>
        </div>
    )
}

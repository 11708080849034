import React, {useState} from 'react'
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CensusForm from "./CensusForm";
import CensusTable from "./CensusTable";
import CensusUpload from "./CensusUpload";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Button from "@material-ui/core/Button";
import {RaterContext} from "../../RaterV3";
import TieredCensus from "./TieredCensus";
import TextField from "@material-ui/core/TextField";
import Selection from "../StepThree/censusSelection.js"
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(1),
    },
    grid : {
        margin: theme.spacing(1)
    },
}))

export default function CensusCard() {
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const {total, saveCensus, census, groupName, requiredLevel, setLoading, getMyProducts, setValue, setFourTierCensus, hasOpportunity, eligibilityCount, setEligibilityCount, setGapCensus, setMessage, isCensusParsed} = React.useContext(RaterContext)
    const classes = useStyles()


    React.useEffect(() => {
         // if (requiredLevel === 1) {
        //     let censusObj = {
        //         'fiftyPlus': {
        //             'insured': +eligibilityCount,
        //             'insuredPlusSpouse': 0,
        //             'insuredPlusChildren': 0,
        //             'insuredPlusFamily': 0,
        //         },
        //         'fortyToFortyNine': {
        //             'insured': 0,
        //             'insuredPlusSpouse': 0,
        //             'insuredPlusChildren': 0,
        //             'insuredPlusFamily': 0,
        //         },
        //         'underForty': {
        //             'insured': 0,
        //             'insuredPlusSpouse': 0,
        //             'insuredPlusChildren': 0,
        //             'insuredPlusFamily': 0,
        //         }
        //     }
        //     setFourTierCensus(censusObj)
        //     setGapCensus(censusObj)
        // }

    }, [eligibilityCount])


    // console.log("total CHECKER HERE!!!!")
    // console.log(total)

    // console.log("CENSUS CHECKER HERE!!!!")
    // console.log(census)

   

    React.useEffect(() => {
        setEligibilityCount(total);
    }, [total]);



    const handleContinue = () => {

        switch (requiredLevel) {
            case 2: // tiered
                setEligibilityCount(total)
                break;
            case 3: // full
                setEligibilityCount(census.length)
                break;
            default:
                break;
        }
        
        setLoading(true)
        getMyProducts()
            .then(res => {
                setLoading(false)
                if (!res.isError) {
                    setValue(4)
                }
                else {
                    setError(true)
                    setMessage("Something went when trying to get your available products. Please try again shortly. If the problem persists, please contact support.")
                }
            })
            .catch(err => {
                setLoading(false)
                setError(true)
                setMessage(err.response && err.response.data ? err.response.data:"Something went wrong trying to get your available products. Please try again shortly. If the problem persists, please contact support.")
                console.log(err)
            })
    }

    return (
        <>
            <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert onClose={() => setSuccess(false)} severity="success">
                    Your census for {groupName} have been saved!
                </Alert>
            </Snackbar>
            <Snackbar open={error} autoHideDuration={6000} onClose={() => setSuccess(false)} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert onClose={() => setError(false)} severity="error">
                    Error! Something went wrong. If the problem persists, please contact support.
                </Alert>
            </Snackbar>
            <Grid item xs={12} container justify={'center'} spacing={1}>
                <Grid item xs={12}>
                <div className={classes.paper}>
                    <Grid container spacing={3} className={classes.grid} item xs={12} justify='center' alignItems={'flex-start'} alignContent={'flex-start'}>
                        <Grid item xs={12}>
                            <Typography variant={'h4'} style={{fontWeight: 'bold'}}>
                                Add your census
                            </Typography>
                        </Grid>
                        {hasOpportunity &&
                        <>
                        <Grid item xs={12}>
                            <Typography>
                                {isCensusParsed ? 'Your Census information has been imported below. Please review and confirm that it is correct. You can edit any of the tiers below, prior to selecting continue.'
                                :
                                'Please update your census information below.'}
                            </Typography>
                        </Grid>
                        {requiredLevel === 3 &&
                        <>
                            <>
                            <Grid item xs={12} sm={6} md={4} container className={classes.form} spacing={1}>
                            <CensusForm />
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                                <CensusUpload saveCensus={saveCensus}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={8}>
                                <CensusTable />
                            </Grid>
                            </>
                        </>}
                        {(requiredLevel === 2 || requiredLevel == 1) &&
                        <Grid item xs={12}>
                            <TieredCensus/>
                        </Grid>
                        }
                      {/*  {requiredLevel === 1 &&
                            <>
                                <Grid item xs={12}>
                                <Typography>
                                    The only census we require for your selected products is an eligibility count.
                                </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label='Eligibility Count' type={'number'} value={eligibilityCount} onChange={(e) => setEligibilityCount(e.target.value)} fullWidth variant={'outlined'}/>
                                </Grid>
                      </>} */}
                            </>}

                        {!hasOpportunity &&
                        <>
                        <Grid item xs={12}>
                                <Typography align={'center'} variant={'h6'}>You have not completed the required steps to enter in your census. Please complete steps 1, 2 and 3 before trying again.</Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Button fullWidth onClick={() => setValue(0)} variant={'contained'} style={{backgroundColor: '#0C2659', color:'#FFFFFF'}} color={'primary'}>Go back to the beginning</Button>
                            </Grid>
                        </>
                        }
                        <div><Selection/></div>
                        {/*NOTE: disabled has to be updated in order to account for all the different kinds of census.*/}
                        {hasOpportunity &&
                        <Grid item xs={10}>
                                <Button fullWidth onClick={handleContinue} color='primary' style={{backgroundColor: '#0C2659', color:'#FFFFFF'}} disabled={(requiredLevel === 3 && census.length < 2) || (requiredLevel === 2 && total < 2) || (requiredLevel === 1 && eligibilityCount < 2)} variant={'contained'}>Continue</Button>
                        </Grid>}
                    </Grid>
                </div>
                </Grid>
            </Grid>
        </>
    )
}

import React from "react";
import { Grid, Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import _ from "lodash";

/*NOTE:
This is the GAP Rate parser. If there are composite rates, those are parsed first and placed at the top.
The rest of the rates are conditionally checked for and laid out below.
 */
export default function Gap(props) {
  let compositeEqualsZero = props.data.composite
    ? props.data.composite.insuredOnly === 0
    : false;

  const getDiscount = (discount) => {
    if (discount != -1) {
      discount = (100 - discount) / 100;
    }

    if (props.state === "MI" && props.provider === "shelter") {
      discount = 1;
    }
    if (props.state === "AL" && props.provider === "nationwide") {
      if (discount > 10) discount = 10;
    }

    if (props.sent) discount = -1;

    return discount == -1 ? 1 : discount;
  };

  const discount = getDiscount(props.discounts[props.provider]);

  return (
    <>
      {props.data.composite && !compositeEqualsZero ? (
        <>
          <Grid item xs={12}>
            <Typography align={"center"} style={{ fontWeight: "bold" }}>
              Standard Rates
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={3}>
            <Typography align={"center"} variant={"body2"}>
              {"< 40"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography align={"center"} variant={"body2"}>
              40 - 49
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography align={"center"} variant={"body2"}>
              50 +
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              align={"right"}
              variant={"body2"}
              style={{ fontWeight: "bold" }}
            >
              Composite
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography>Insured Only</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              align={"center"}
              variant={"body2"}
              style={{ color: "#0C2659" }}
            >
              $
              {(
                props.data.underForty.insuredOnly * discount +
                props.fees
              ).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              align={"center"}
              variant={"body2"}
              style={{ color: "#0C2659" }}
            >
              $
              {(
                props.data.fortyToFortyNine.insuredOnly * discount +
                props.fees
              ).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography align={"center"} variant={"body2"} color={"#0C2659"}>
              $
              {(
                props.data.fiftyPlus.insuredOnly * discount +
                props.fees
              ).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              align={"right"}
              variant={"body2"}
              style={{ color: "#0C2659", fontWeight: "bold" }}
            >
              $
              {(
                props.data.composite.insuredOnly * discount +
                props.fees
              ).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {!_.isUndefined(props.data.underForty.insuredPlusSpouse) && (
            <>
              <Grid item xs={12}>
                <Typography>Insured Plus Spouse</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  align={"center"}
                  variant={"body2"}
                  style={{ color: "#0C2659" }}
                >
                  $
                  {(
                    props.data.underForty.insuredPlusSpouse * discount +
                    props.fees
                  ).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  align={"center"}
                  variant={"body2"}
                  style={{ color: "#0C2659" }}
                >
                  $
                  {(
                    props.data.fortyToFortyNine.insuredPlusSpouse * discount +
                    props.fees
                  ).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  align={"center"}
                  variant={"body2"}
                  style={{ color: "#0C2659" }}
                >
                  $
                  {(
                    props.data.fiftyPlus.insuredPlusSpouse * discount +
                    props.fees
                  ).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  align={"right"}
                  variant={"body2"}
                  style={{ color: "#0C2659", fontWeight: "bold" }}
                >
                  $
                  {(
                    props.data.composite.insuredPlusSpouse * discount +
                    props.fees
                  ).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
          {!_.isUndefined(props.data.underForty.insuredPlusChildren) && (
            <>
              <Grid item xs={12}>
                <Typography>Insured Plus Children</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  align={"center"}
                  variant={"body2"}
                  style={{ color: "#0C2659" }}
                >
                  $
                  {(
                    props.data.underForty.insuredPlusChildren * discount +
                    props.fees
                  ).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  align={"center"}
                  variant={"body2"}
                  style={{ color: "#0C2659" }}
                >
                  $
                  {(
                    props.data.fortyToFortyNine.insuredPlusChildren * discount +
                    props.fees
                  ).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  align={"center"}
                  variant={"body2"}
                  style={{ color: "#0C2659" }}
                >
                  $
                  {(
                    props.data.fiftyPlus.insuredPlusChildren * discount +
                    props.fees
                  ).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  align={"right"}
                  variant={"body2"}
                  style={{ color: "#0C2659", fontWeight: "bold" }}
                >
                  $
                  {(
                    props.data.composite.insuredPlusChildren * discount +
                    props.fees
                  ).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
          {props.data.underForty.insuredPlusOne && (
            <>
              <Grid item xs={12}>
                <Typography>Insured Plus One</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  align={"center"}
                  variant={"body2"}
                  style={{ color: "#0C2659" }}
                >
                  $
                  {(
                    props.data.underForty.insuredPlusOne * discount +
                    props.fees
                  ).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  align={"center"}
                  variant={"body2"}
                  style={{ color: "#0C2659" }}
                >
                  $
                  {(
                    props.data.fortyToFortyNine.insuredPlusOne * discount +
                    props.fees
                  ).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  align={"center"}
                  variant={"body2"}
                  style={{ color: "#0C2659" }}
                >
                  $
                  {(
                    props.data.fiftyPlus.insuredPlusOne * discount +
                    props.fees
                  ).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  align={"right"}
                  variant={"body2"}
                  style={{ fontWeight: "bold" }}
                >
                  $
                  {(
                    props.data.composite.insuredPlusOne * discount +
                    props.fees
                  ).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography>Insured Plus Family</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              align={"center"}
              variant={"body2"}
              style={{ color: "#0C2659" }}
            >
              $
              {(
                props.data.underForty.insuredPlusFamily * discount +
                props.fees
              ).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              align={"center"}
              variant={"body2"}
              style={{ color: "#0C2659" }}
            >
              $
              {(
                props.data.fortyToFortyNine.insuredPlusFamily * discount +
                props.fees
              ).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              align={"center"}
              variant={"body2"}
              style={{ color: "#0C2659" }}
            >
              $
              {(
                props.data.fiftyPlus.insuredPlusFamily * discount +
                props.fees
              ).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              align={"right"}
              variant={"body2"}
              style={{ color: "#0C2659", fontWeight: "bold" }}
            >
              $
              {(
                props.data.composite.insuredPlusFamily * discount +
                props.fees
              ).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      ) : (
        <>
          {props.data.fiftyPlus && (
            <>
              <Grid item xs={12}>
                <Typography align={"center"} style={{ fontWeight: "bold" }}>
                  Standard Rates
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3}></Grid>
              <Grid item md={4} lg={3}>
                <Typography align={"center"} variant={"body2"}>
                  {"< 40"}
                </Typography>
              </Grid>
              <Grid item md={4} lg={3}>
                <Typography align={"center"} variant={"body2"}>
                  40 - 49
                </Typography>
              </Grid>
              <Grid item md={4} lg={3}>
                <Typography align={"center"} variant={"body2"}>
                  50 +
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} md={12} sm={12} lg={3}>
                <Typography>Insured Only</Typography>
              </Grid>
              <Grid item md={4} lg={3}>
                <Typography
                  align={"center"}
                  variant={"body2"}
                  style={{ color: "#0C2659" }}
                >
                  $
                  {(
                    props.data.underForty.insuredOnly * discount +
                    props.fees
                  ).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item md={4} lg={3}>
                <Typography
                  align={"center"}
                  variant={"body2"}
                  style={{ color: "#0C2659" }}
                >
                  $
                  {(
                    props.data.fortyToFortyNine.insuredOnly * discount +
                    props.fees
                  ).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item md={4} lg={3}>
                <Typography
                  align={"center"}
                  variant={"body2"}
                  style={{ color: "#0C2659" }}
                >
                  $
                  {(
                    props.data.fiftyPlus.insuredOnly * discount +
                    props.fees
                  ).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {!_.isUndefined(props.data.underForty.insuredPlusSpouse) && (
                <>
                  <Grid item md={12} xs={12} sm={12} lg={3}>
                    <Typography>Insured Plus Spouse</Typography>
                  </Grid>
                  <Grid item md={4} lg={3}>
                    <Typography
                      align={"center"}
                      variant={"body2"}
                      style={{ color: "#0C2659" }}
                    >
                      $
                      {(
                        props.data.underForty.insuredPlusSpouse * discount +
                        props.fees
                      ).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item md={4} lg={3}>
                    <Typography
                      align={"center"}
                      variant={"body2"}
                      style={{ color: "#0C2659" }}
                    >
                      $
                      {(
                        props.data.fortyToFortyNine.insuredPlusSpouse *
                          discount +
                        props.fees
                      ).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item md={4} lg={3}>
                    <Typography
                      align={"center"}
                      variant={"body2"}
                      style={{ color: "#0C2659" }}
                    >
                      $
                      {(
                        props.data.fiftyPlus.insuredPlusSpouse * discount +
                        props.fees
                      ).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              )}
              {!_.isUndefined(props.data.underForty.insuredPlusChildren) && (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={3}>
                    <Typography>Insured Plus Children</Typography>
                  </Grid>
                  <Grid item md={4} lg={3}>
                    <Typography
                      align={"center"}
                      variant={"body2"}
                      style={{ color: "#0C2659" }}
                    >
                      $
                      {(
                        props.data.underForty.insuredPlusChildren * discount +
                        props.fees
                      ).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item md={4} lg={3}>
                    <Typography
                      align={"center"}
                      variant={"body2"}
                      style={{ color: "#0C2659" }}
                    >
                      $
                      {(
                        props.data.fortyToFortyNine.insuredPlusChildren *
                          discount +
                        props.fees
                      ).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item md={4} lg={3}>
                    <Typography
                      align={"center"}
                      variant={"body2"}
                      style={{ color: "#0C2659" }}
                    >
                      $
                      {(
                        props.data.fiftyPlus.insuredPlusChildren * discount +
                        props.fees
                      ).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              )}
              {props.data.underForty.insuredPlusOne && (
                <>
                  <Grid item md={12} xs={12} sm={12} lg={3}>
                    <Typography>Insured Plus One</Typography>
                  </Grid>
                  <Grid item md={4} lg={3}>
                    <Typography
                      align={"center"}
                      variant={"body2"}
                      style={{ color: "#0C2659" }}
                    >
                      $
                      {(
                        props.data.underForty.insuredPlusOne * discount +
                        props.fees
                      ).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item md={4} lg={3}>
                    <Typography
                      align={"center"}
                      variant={"body2"}
                      style={{ color: "#0C2659" }}
                    >
                      $
                      {(
                        props.data.fortyToFortyNine.insuredPlusOne * discount +
                        props.fees
                      ).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item md={4} lg={3}>
                    <Typography
                      align={"center"}
                      variant={"body2"}
                      style={{ color: "#0C2659" }}
                    >
                      $
                      {(
                        props.data.fiftyPlus.insuredPlusOne * discount +
                        props.fees
                      ).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={12} md={12} lg={3}>
                <Typography>Insured Plus Family</Typography>
              </Grid>
              <Grid item md={4} lg={3}>
                <Typography
                  align={"center"}
                  variant={"body2"}
                  style={{ color: "#0C2659" }}
                >
                  $
                  {(
                    props.data.underForty.insuredPlusFamily * discount +
                    props.fees
                  ).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item md={4} lg={3}>
                <Typography
                  align={"center"}
                  variant={"body2"}
                  style={{ color: "#0C2659" }}
                >
                  $
                  {(
                    props.data.fortyToFortyNine.insuredPlusFamily * discount +
                    props.fees
                  ).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item md={4} lg={3}>
                <Typography
                  align={"center"}
                  variant={"body2"}
                  style={{ color: "#0C2659" }}
                >
                  $
                  {(
                    props.data.fiftyPlus.insuredPlusFamily * discount +
                    props.fees
                  ).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
}

/*NOTE:
 * This file contains a number of functions that prettify different values across the app.
 * This is used since certain values passed through the APIs aren't as readable for a user
 * so these functions convert the data string into a readable string for the user.
 * */

export const prettifyProduct = (str) => {
  let string = str
    .toUpperCase()
    .replace("_", " ")
    .replace("LTDMED", "LIMITED MEDICAL")
    .replace("EXTRAS", "OPTIMED ADVANTAGE")
    .replace("LTD", "LONG TERM DISABILITY")
    .replace("STD", "SHORT TERM DISABILITY")
    .replace("MAJOR MEDICAL", "LEVEL FUNDED HEALTH")
    .replace("EMEC", "ENHANCED MINIMUM ESSENTIAL COVERAGE")
    .replace("MEC", "MINIMUM ESSENTIAL COVERAGE");
  return string;
};

export const prettifyProvider = (str) => {
  let string = str.replace("_", " ");
  string = string.replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase());
  string = string.replace("Fsl", "FSL").replace("Shelter", "ShelterPoint");
  return string;
};

export const prettifyPlan = (str) => {
  const PlanDesc = {
    ip_op_benefit: "Inpatient/Outpatient Benefit",
    combined_benefit: "Combined Benefit",
    ip_only_benefit: "Inpatient Only Benefit",
    op_only_benefit: "Outpatient Only Benefit",
    ip_op_per_occur_benefit: "Inpatient/Outpatient Per Occurence Benefit",
    1: "Inpatient/Outpatient Benefit",
  };
  return PlanDesc[str] ? PlanDesc[str] : str;
};

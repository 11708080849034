import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid, Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { JsonToTable } from "react-json-to-table";
import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import _ from "lodash";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import EditIcon from "@material-ui/icons/Edit";
import Slide from "react-reveal/Slide";
import Collapse from "@material-ui/core/Collapse";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { RaterContext } from "../../RaterV3";
import RatesModal from "../../Modals/RatesModal";
import { postRates } from "../../../utils/engine";
import { FirebaseContext } from "react-ugp-firebase";
import Standard from "../../RateParsers/Standard";
import MajorMedical from "../../RateParsers/MajorMedical";
import SmallMajorMedical from "../../RateParsers/SmallMajorMedical";
import Hospital from "../../RateParsers/Hospital";
import Life from "../../RateParsers/Life";
import LifeMember from "../../RateParsers/LifeMember";
import Gap from "../../RateParsers/Gap";
import STD from "../../RateParsers/STD";
import LTD from "../../RateParsers/LTD";
import MecPlus from "../../RateParsers/MecPlus";
import Emec from "../../RateParsers/Emec";
import CFLRates from "../../RateParsers/CFL";
import { prettifyProduct } from "../../../utils/prettifyFunctions";
import { removeNullValues } from "../../../utils/removeNullValues";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { checkIfEmp } from "../../../utils/Tools";
import IHP from "../../RateParsers/IHP";
import IHPAgent from "../../RateParsers/IHPAgent";

const prettifyPlan = (string) => {
  const PlanDesc = {
    ip_op_benefit: "Inpatient/Outpatient Benefit",
    combined_benefit: "Combined Benefit",
    ip_only_benefit: "Inpatient Only Benefit",
    op_only_benefit: "Outpatient Only Benefit",
    ip_op_per_occur_benefit: "Inpatient/Outpatient Per Occurence Benefit",
    1: "Inpatient/Outpatient Benefit",
  };
  if (PlanDesc[string]) {
    return PlanDesc[string].toUpperCase();
  } else return string.replace("_", " ").toUpperCase();
};

/*Function that turns the code version of the carrier's name to a more user friendly version*/
const prettifyProvider = (string) => {
  let str = string;
  const arr = ["nw_", "_", "LTDMED", "SHELTER"];
  if (arr.includes(str)) {
    str = str
      .replace("nw_", "Nationwide ")
      .toUpperCase()
      .replace("_", " ")
      .replace("LTDMED", "LIMITED MEDICAL")
      .replace("_", " ");
    str = str.replace("SHELTER", "SHELTERPOINT");
    return str;
  } else {
    return str.toUpperCase();
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 30,
  },
  card: {
    height: "100%",
    border: "1px solid white",
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  link: {
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
}));

export const calcAdminFee = (product, adminFees) => {
  const isShelterEssential =
    _.has(product, "provider") &&
    product.provider === "shelter" &&
    _.has(product, "level") &&
    product.level.toLowerCase() === "basic";

  return isShelterEssential
    ? 0
    : product.name.toLowerCase() === "extras"
    ? product.fields[2].value === "Employer Paid"
      ? adminFees["extras_employerpaid"]
      : adminFees["extras_voluntary"]
    : adminFees[product.name];
};

export default function SavedPlanCard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [showInputs, setShowInputs] = React.useState(false);
  const [sent, setSent] = React.useState(false);
  const [totalFees, setTotalFees] = React.useState(0);
  const [benefitsOpen, setBenefitsOpen] = React.useState(false);
  const { restConfig, user, isUserLoading } = React.useContext(FirebaseContext);
  const [inpatientBenefit, setInpatientBenefit] = React.useState(undefined);
  const [outpatientBenefit, setOutpatientBenefit] = React.useState(undefined);
  const [combinedBenefit, setCombinedBenefit] = React.useState(undefined);
  const [planName, setPlanName] = React.useState(undefined);
  const {
    log,
    webError,
    scrollToTop,
    adminFees,
    setAdminFees,
    loading,
    setLoading,
    setMessage,
    setError,
    setSuccess,
    census,
    gapCensus,
    opportunityId,
    requiredLevel,
    fourTierCensus,
    products,
    setProducts,
    total,
    totalSelectionFee,
    discounts,
    state,
    userCRMid,
    selectedBroker,
  } = React.useContext(RaterContext);

  const userRole = isUserLoading ? "" : user.roles;
  const [dialog, setDialog] = React.useState(false);
  const [submitState, setSubmitState] = React.useState({
    sendToSelfEmp: false,
    sendtoHSA: false,
    sendtoOptienhance: false,
  });
  const { sendToSelfEmp } = submitState;

  // console.log("In Saved Plans")

  // props['brokerFee'] = 350
  console.log("checking props");
  console.log(props);

  const applyDiscounts = () => {
    let multiplier;
    const key = "discount";
    const rates = ["composite", "fiftyPlus", "fortyToFortyNine", "underForty"];
    let productClone = _.cloneDeep(products);
    console.log("PRODUCT CLONE");
    console.log(productClone);

    Object.entries(productClone).forEach((products) => {
      console.log("FIRST");
      if (products[0] == "gap") {
        console.log("SECOND");
        if (products && products[1] && products[1].hasOwnProperty("saved")) {
          console.log("THIRD");
          Object.entries(products[1].saved).forEach((product) => {
            multiplier = getDiscount(discounts[product[1].provider]) || 1;
            console.log("DISCOUNT LOGS");
            console.log(products);
            if (product[1].provider === "shelter" && product[1].request.state === "MI") {
              multiplier = 1;
            }
            if (product[1].provider === "nationwide" && product[1].fields[1].value === "AL") {
              if (multiplier > 10) multiplier = 10;
            }
            product[1][key] = multiplier;
            if (multiplier != 1) console.log("Discount: " + multiplier);
            Object.entries(product[1].rateData.raw).forEach((rate) => {
              if (rates.includes(rate[0])) {
                Object.keys(rate[1]).forEach(function (key, index) {
                  rate[1][key] *= multiplier;
                });
              }
            });
          });
        }
      }
    });
    setProducts(productClone);
    return productClone;
  };

  const getDiscount = (discount) => {
    if (discount != -1) {
      discount = (100 - discount) / 100;
      return discount;
    }
    return 1;
  };

  const handleChange = (event) => {
    setSubmitState({
      ...submitState,
      [event.target.name]: event.target.checked,
    });
  };

  React.useEffect(() => {
    setSubmitState({ ...submitState, sendtoHSA: props.notify, sendtoOptienhance: props.notify });
  }, [props.notify]);

  /*NOTE:
    If sent is passed in as true as a prop, the internal state will also be flipped to true so that the card properly acts as a card that has already been sent (Can't resend with label saying it was emailed).
     */
  React.useEffect(() => {
    props.sent ? setSent(props.sent) : console.log();
  }, [props.sent]);

  React.useEffect(() => {
    if (props.provider === "chubb") {
      console.log("checking chubb");
      props.fields.forEach((field) => {
        console.log("checking fields");
        console.log(field);
        if (field.name === "sicCode") {
          const sicCodeValues = [
            "1611",
            "1799",
            "912",
            "972",
            "4952",
            "4959",
            "3812",
            "4212",
            "4231",
            "2411",
            "1011",
            "1311",
            "1241",
            "1389",
            "9229",
            "7941",
            "9211",
            "922",
          ];
          const numericSicCodeValues = sicCodeValues.map(Number);
          if (numericSicCodeValues.includes(field.value)) {
            setError(true);
            setMessage(" contact their OptiMed Representative for further assistance");
            //props.close();
            return;
          }

          // ...other sicCode checks, if any...
        }

        // ...other field checks...
      });
    }
  }, []);

  console.log("this products at results");
  console.log(products);

  Object.entries(products).forEach(([key, productobj]) => {
    // Add censusData field to each product object
    if (
      key === "rx" ||
      key === "ihp" ||
      key === "ihp_agent" ||
      key === "ltdmed" ||
      key === "mec" ||
      key === "emec" ||
      key === "travelers"
    ) {
      products[key].censusData = fourTierCensus;
    }
  });

  const isIHPAgent = () => userRole.includes("ihp_agent");

  /*NOTE:
    submitToCrm function has a dummy interaction if the environment is in development (The first if statement). Else, it creates a proper payload with the product passed down through props and submits it to the CRM.
     */
  const submitToCrm = () => {
    const discountedProducts = applyDiscounts() != null ? applyDiscounts() : products;

    console.log("this is discounted products at results");

    console.log(discountedProducts);

    // let productClone = _.cloneDeep(discountedProducts);

    setProducts(discountedProducts);

    console.log("this is prodcuts after discounts");

    console.log(products);
    //Adding up the admin and broker fees to a single value to be added to this specific plan.
    setLoading(true);
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      //Really sloppy method to deal with timing. Should work most of the time but is far from the best way to deal with async tasks.
      setTimeout(() => {
        let productClone = _.cloneDeep(products);
        productClone[props.name].saved[props.index].sent = true;
        setSuccess(true);
        setMessage(
          "Your plan has been submitted to us for processing. You should receive an email shortly with illustrative proposals. If you do not receive your quotes via email, please contact us."
        );
        setLoading(false);
        setSent(true);
        setProducts(productClone);
      }, 1000);
    } else {
      let productArr = [];
      let adminFee = calcAdminFee(props, adminFees);
      let obj = {
        type: props.name,
        adminFee: (adminFee || 0).toString(),
        brokerCommission: props.brokerFee ? props.brokerFee.toString() : "0",
        carrier: props.provider,
        plan: props.plan,
        data: {
          request: {
            censusData:
              props.name.toLowerCase() === "gap" ||
              props.name.toLowerCase() === "rx" ||
              props.name.toLowerCase() === "travelers"
                ? removeNullValues(gapCensus)
                : requiredLevel < 3
                ? removeNullValues(fourTierCensus)
                : census,
            inputData: props.rateData.inputWithDescs,
          },
          response: props.rateData.raw,
        },
        addons: _.map(props.addons, (addon) => addon.id),
      };

      console.log("------------------results start");

      console.log("this is adminfee");
      console.log(adminFee);

      console.log("this is objects at results.....");
      console.log(obj);

      console.log("this is props");

      console.log(props);

      //console.log(obj)
      productArr.push(obj);

      console.log("this is Product Array");
      console.log(productArr);

      let payload = {
        opportunityId,
        products: productArr,
      };

      console.log("THIS IS PAYLOAD");
      console.log(payload);

      console.log("this is submit State");
      console.log(submitState);

      console.log("this is Products");
      console.log(products);

      // console.log("this is arr")
      // console.log(arr)

      if (
        submitState.sendToSelfEmp === true ||
        _.filter(productArr, (product) => product.carrier.startsWith("markel")).length > 0
      ) {
        payload.sendToSubmitter = true;
      }

      // if (submitState.sendtoHSA === true) {
      //   payload.includeAccountAdminQuote = true;
      // }

      // if (submitState.sendtoOptienhance === true) {
      //   payload.includeGeneralTeleHealthQuote = true;
      // }

      // if (submitState.sendtoOptienhance === true) {
      //   payload.includeDermTeleHealthQuote = true;
      // }
      payload.products.forEach((product) => {
        product.includeGeneralTeleHealthQuote = false;
        product.includeDermTeleHealthQuote = false;
        product.includeRecurro = false;
      });

      console.log("this is userCRMid");
      console.log(selectedBroker);

      var acceptableCRMID = [
        "1f3e1e8a-1f79-e711-811d-e0071b6a5131",
        "b7ac3325-fc4b-ed11-bba2-00224822f335",
        "b7ac3325-fc4b-ed11-bba2-00224822f335",
      ];

      console.log("this is selectedBroker checker");
      console.log(acceptableCRMID.includes(selectedBroker));

      // if (userCRMid) {
      if (acceptableCRMID.includes(selectedBroker)) {
        payload.products.forEach((product) => {
          if (submitState.sendtoOptienhance === true) {
            product.includeGeneralTeleHealthQuote = true;
            product.includeDermTeleHealthQuote = true;
            product.includeRecurro = true;
          } else {
            product.includeGeneralTeleHealthQuote = false;
            product.includeDermTeleHealthQuote = false;
            product.includeRecurro = false;
          }
        });
      } else {
        payload.products.forEach((product) => {
          if (submitState.sendtoOptienhance === true) {
            product.includeGeneralTeleHealthQuote = true;
            product.includeDermTeleHealthQuote = true;
            product.includeRecurro = false;
          } else {
            product.includeGeneralTeleHealthQuote = false;
            product.includeDermTeleHealthQuote = false;
            product.includeRecurro = false;
          }
        });
      }

      console.log("Payload: ", payload);

      console.log("this is restConfig");

      console.log(restConfig);

      console.log("this is productClone");

      console.log(_.cloneDeep(products));

      console.log("------------------results end");

      function postRatesWithRetry(payload, restConfig, retries = 5) {
        return new Promise((resolve, reject) => {
          function attempt() {
            postRates(payload, restConfig)
              .then(resolve)
              .catch((err) => {
                if (err.response && err.response.status === 500 && retries > 0) {
                  console.log(`Attempt failed with 500 error, retrying... ${retries} retries left.`);
                  console.log("500 error");
                  retries--;
                  setTimeout(attempt, 1000); // Wait for 2 seconds before retrying
                } else {
                  reject(err);
                }
              });
          }
          attempt(); // Start the first attempt
        });
      }

      // Usage of postRatesWithRetry
      postRatesWithRetry(payload, restConfig)
        .then((res) => {
          // Handle success as before
          let productClone = _.cloneDeep(products);
          productClone[props.name].saved[props.index].sent = true;
          setProducts(productClone);
          setLoading(false);
          setSent(true);
          setSuccess(true);
          setDialog(false);
          setMessage(
            "Quote has been submitted successfully. You should receive an email shortly with your proposal attached."
          );
        })
        .catch((err) => {
          // Handle error as before
          setLoading(false);
          console.log(err);
          setError(true);
          setDialog(false);
          setMessage("Something went wrong. If the problem persists, please contact support");
          webError({
            text: "Something went wrong when trying to post rates to CRM.",
            payload,
            err: err.response ? err.response.data : "",
          });
        });
    }
  };

  /*NOTE:
    useEffect that analyzes the fields in order to properly create titles for the card to better tell the user what the savedPlanCard represents.
     */
  React.useEffect(() => {
    props.fields.forEach((field) => {
      if (field.name === "inpatientBenefit") setInpatientBenefit(field.value);
      else if (field.name === "outpatientBenefit") setOutpatientBenefit(field.value);
      else if (field.name === "outpatientBenefitOfInpatientBenefitPercent")
        field.type === "Percent" ? setOutpatientBenefit(field.value * 100 + "%") : setOutpatientBenefit(field.value);
      else if (field.name === "combinedBenefit") setCombinedBenefit(field.value);
      else if (field.name === "planName" && props.provider.toLowerCase() === "ast") setPlanName(field.value.desc);
    });
  }, [props.fields]);

  /*NOTE:
    Sets the product inside of ./Products to the current product so that the configuration switches to these inputs and the user can edit the selection. The savedPlanCard is then deleted and must be saved again.
     */
  const handleEdit = (e) => {
    e.preventDefault();
    let fields = Object.entries(props.rateData.inputWithDescs).map((x) => {
      return { name: x[0], value: x[1] };
    });
    let obj = {};
    obj.provider = props.provider;
    obj.plan = props.plan;
    obj.fields = fields;
    obj.rateData = props.rateData;
    obj.rateData.continue = true;
    obj.name = props.name;
    obj.addons = props.addons;
    props.editFunction(obj);
    scrollToTop();
    props.deleteSaved(props.index);
  };

  /*NOTE:
    This useEffect is responsible for handling all fees that are added to a specific product. The corresponding broker fee is passed down through props and the corresponding admin fee is held
    in a global context variable, adminFees. Both of these are added together and passed to the corresponding rate parser to be properly added to the rates.
     */
  React.useEffect(() => {
    let fees = 0;
    if (adminFees && !adminFees.ihp) {
      adminFees.ihp = 0;
      setAdminFees(adminFees);
    }
    if (adminFees) {
      console.log(adminFees);
      let adminFee = calcAdminFee(props, adminFees);
      fees = fees + adminFee;
    }
    if (props.brokerFee) {
      fees = fees + +props.brokerFee;
    }

    if (totalSelectionFee) {
      fees = fees + totalSelectionFee;
    }
    setTotalFees(fees);
  }, [totalSelectionFee, adminFees, props]);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const feesAndAddons = (totalFees || 0) + _.reduce(props.addons, (sum, next) => sum + next.cost, 0.0);

  return (
    <Slide right duration={500}>
      <Card
        className={classes.card}
        raised
        style={{
          border: props.sent ? `2px solid ${theme.palette.primary}` : "",
        }}
      >
        <CardContent className={classes.content}>
          <Grid container justify={"center"} spacing={1}>
            <Grid item xs={12}>
              <Typography variant={"h5"} align={"center"} style={{ fontWeight: "bold" }}>
                {prettifyProvider(props.provider)}
                {sent && <CheckIcon color={"inherit"} style={{ color: theme.palette.success.light }} />}
              </Typography>
              <Typography align={"center"} variant={"h6"} style={{ fontWeight: "bold" }}>
                {props.provider === "breckpoint_mec" || props.provider === "breckpoint_emec"
                  ? props.fields[4].value
                  : props.level}
                {props.provider === "everest" ? props.fields[1].value.desc : ""}
              </Typography>
              <Typography align={"center"}>
                {inpatientBenefit !== undefined &&
                  outpatientBenefit !== undefined &&
                  `(${inpatientBenefit}, ${outpatientBenefit})`}
                {combinedBenefit && `(${combinedBenefit})`}
                {inpatientBenefit !== undefined && outpatientBenefit === undefined && `(${inpatientBenefit}, 0)`}
                {planName !== undefined && `${planName}`}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {sent && (
                <div
                  style={{
                    backgroundColor: theme.palette.success.light,
                    borderRadius: 20,
                    padding: theme.spacing(1),
                  }}
                >
                  <Typography color={"inherit"} style={{ color: "white" }} align={"center"} variant={"body2"}>
                    {sent ? "Quote Has Been Emailed" : ""}
                  </Typography>
                </div>
              )}
            </Grid>
            <ParsedRates
              data={props.rateData.cleaned}
              censusTotal={total}
              state={state}
              discounts={discounts}
              sent={sent}
              totalSelectionFee={totalSelectionFee}
              name={props.name}
              provider={props.provider}
              fees={feesAndAddons}
            />
            <Grid item xs={12}>
              <Collapse in={showInputs}>
                <Grid container spacing={1} alignItems={"center"}>
                  <Grid item xs={12}>
                    <Typography variant={"h6"}>Plan Options</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  {props.fields.map((field) => {
                    if (field.name !== "groupEffectiveDate" && field.name !== "zipCode" && field.name !== "sicCode") {
                      return (
                        <React.Fragment key={JSON.stringify(field)}>
                          <Grid item xs={6}>
                            <Typography variant={"caption"}>{field.desc}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography color={"#0C2659"} align={"right"} variant={"body2"}>
                              {!_.isUndefined(field.value)
                                ? typeof field.value === "object"
                                  ? field.value.desc
                                  : _.upperFirst(field.value.toString())
                                : "Yes"}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        </React.Fragment>
                      );
                    } else return <React.Fragment key={JSON.stringify(field)}></React.Fragment>;
                  })}

                  {!_.isUndefined(props.brokerFee) && props.brokerFee > 0 && (
                    <Grid item xs={12} container spacing={1}>
                      <Grid item xs={6}>
                        <Typography>Broker fee</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography color={"#0C2659"} align={"right"} variant={"body2"}>
                          ${(+props.brokerFee).toFixed(2)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  )}

                  {props.addons.length > 0 && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant={"h6"}>Additional Options</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>

                      {props.addons.map((addOn) => {
                        return (
                          <React.Fragment key={addOn.id}>
                            <Grid item xs={6}>
                              <Typography variant={"caption"}>{addOn.title}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography color={"#0C2659"} align={"right"} variant={"body2"}>
                                {formatter.format(addOn.cost)}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          </React.Fragment>
                        );
                      })}
                    </>
                  )}

                  <Grid item xs={12}>
                    <Typography variant={"h6"}>Plan</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={"caption"}>
                      {props.name === "gap" ? prettifyPlan(props.plan) : prettifyProduct(props.name) + " Plan Benefit"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  {props.benefits && props.benefits.response && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant={"h6"}>Benefits</Typography>
                        <Typography className={classes.link} variant={"body2"} onClick={() => setBenefitsOpen(true)}>
                          {props.benefits.response.desc ? props.benefits.response.desc : "View all benefits"}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Collapse>
              {props.rateData.warnings && props.rateData.warnings.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant={"caption"} color={"#0C2659"}>
                    Warning: {props.rateData.warnings[0]}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions style={{ justifyContent: "flex-end" }}>
          {props.submit && (
            <Button
              fullWidth
              variant={"contained"}
              color={"#0C2659"}
              onClick={(e) => checkIfEmp(userRole, setDialog, submitToCrm)}
              disabled={loading || sent}
            >
              {sent ? "Quote Sent" : "Get Quote"}
            </Button>
          )}
          {props.actions && (
            <>
              <Tooltip title={"Delete"} placement={"bottom"}>
                <IconButton
                  color={"#0C2659"}
                  onClick={() => {
                    props.deleteSaved(props.index);
                    log("deleted_product", products[props.index]);
                  }}
                >
                  <DeleteIcon style={{ color: "#0C2659" }} />
                </IconButton>
              </Tooltip>
            </>
          )}
          {props.editable && (
            <Tooltip title={"Edit Product"}>
              <IconButton color={"#0C2659"} onClick={handleEdit}>
                <EditIcon style={{ color: "#0C2659" }} />
              </IconButton>
            </Tooltip>
          )}
          <IconButton color={"#0C2659"} onClick={() => setShowInputs(!showInputs)}>
            {showInputs ? (
              <Tooltip title={"Show less"}>
                <ExpandLessIcon style={{ color: "#0C2659" }} />
              </Tooltip>
            ) : (
              <Tooltip title={"View plan details"}>
                <ExpandMoreIcon style={{ color: "#0C2659" }} />
              </Tooltip>
            )}
          </IconButton>
        </CardActions>
        <RatesModal
          open={benefitsOpen && props.benefits !== ""}
          close={() => setBenefitsOpen(false)}
          json={props.benefits.response}
          name={"Benefit Details"}
        />
      </Card>
      <div>
        <Dialog open={dialog} onClose={(event) => setDialog(false)}>
          <DialogActions className={classes.dialog}>
            <Grid container direction="column" justify="space-between" alignItems="center">
              <br />
              <FormGroup>
                {userRole.includes("employee") ? (
                  <FormControlLabel
                    control={<Checkbox checked={sendToSelfEmp} onChange={handleChange} name="sendToSelfEmp" />}
                    label="It looks like you're an employee. Check the box if you want to email the proposal to yourself."
                  />
                ) : null}
              </FormGroup>
            </Grid>
          </DialogActions>
          <DialogActions>
            <Button
              onClick={(e) => setDialog(false)}
              style={{ backgroundColor: "#0C2659", color: "#FFFFFF" }}
              color="primary"
            >
              Back
            </Button>
            <Button
              onClick={(e) => submitToCrm()}
              style={{ backgroundColor: "#0C2659", color: "#FFFFFF" }}
              color="primary"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Slide>
  );
}

export const ParsedRates = (props) => {
  React.useEffect(() => {
    getParsedJSX();
  });

  const getParsedJSX = () => {
    if (props.data.involuntaryRates || props.data.voluntaryRates) {
      return <CFLRates {...props} />;
    }
    if (props.name === "ihp") {
      console.log("IN IHP");
      return <IHP {...props} />;
    } else if (props.name === "ihp_agent") {
      console.log("IN IHP AGENT");
      return <IHPAgent {...props} />;
    } else if (props.name === "emec") {
      console.log("IN EMEC");
      return <Emec {...props} />;
    } else if (props.data.rates && !props.data.aggregateAccommodation) {
      return <Standard {...props} />;
    } else if (props.data.rates && props.data.aggregateAccommodation) {
      return <MecPlus {...props} />;
    } else if (props.data["18-54"]) {
      return <Hospital {...props} />;
    } else if (props.data.fiftyPlus || props.data.composite) {
      return <Gap {...props} />;
    } else if (props.data.member) {
      return <Life {...props} />;
    } else if (props.data.memberRates) {
      return <LifeMember {...props} />;
    } else if (props.data.aggPremium && props.data.aggPremium.insuredOnly) {
      return <MajorMedical {...props} />;
    } else if (props.data.aggPremium && props.data.aggPremium.length > 0) {
      return <SmallMajorMedical {...props} />;
    } else if (props.data.ratesPerTenDollars) {
      return <STD {...props} />;
    } else if (props.data.ratesPerHundredDollars) {
      return <LTD {...props} />;
    } else return <JsonToTable json={props.data} />;
  };

  return (
    <Grid item xs={12} container justify={"space-between"} spacing={1} alignItems={"center"} alignContent={"center"}>
      <Grid item xs={12}>
        <Typography variant={"h6"} style={{ fontWeight: "bold" }}>
          Rates
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {getParsedJSX()}
    </Grid>
  );
};

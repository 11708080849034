import React from "react";
import { Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import SavedPlanCard from "./SavedPlanCard";

export default function ProductsCarousel(props) {
  const [startIndex, setStartIndex] = React.useState(0);
  let range = 3;

  console.log("THIS IS PROPS IN PRODUCT CAROUSEL");
  console.log(props);

  return (
    <Grid item xs={12} container justify={"center"} spacing={2}>
      {props.productArray.length > 3 && (
        <Grid item container xs={12} justify={"center"} spacing={2}>
          <Grid item xs={2}>
            <Button
              onClick={() => setStartIndex(startIndex - 1)}
              disabled={startIndex === 0}
              fullWidth
              variant={"contained"}
              style={{ backgroundColor: "#0C2659" }}
              color={"primary"}
            >
              <ArrowBackIcon />
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              onClick={() => setStartIndex(startIndex + 1)}
              disabled={startIndex + range >= props.productArray.length}
              fullWidth
              variant={"contained"}
              style={{ backgroundColor: "#0C2659" }}
              color={"primary"}
            >
              <ArrowForwardIcon />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography color={"primary"} align={"center"}>
              Showing plans {startIndex + 1} -{" "}
              {startIndex + range < props.productArray.length ? startIndex + range : props.productArray.length} of{" "}
              {props.productArray.length}
            </Typography>
          </Grid>
        </Grid>
      )}
      {props.productArray.map((savedProduct, index) => {
        console.log("this is saved product");
        console.log(savedProduct);
        //console.log("this is props in carousle")

        if (savedProduct.name === "ihp_agent") {
          var updatedBrokerFee = savedProduct.rateData.cleaned.brokerCommission2.insuredOnly;
          // if (savedProduct.provider === "MEC 1" || savedProduct.provider === "MEC 2" || savedProduct.provider === "MEC 3" || savedProduct.provider === "MEC 4"){
          savedProduct["brokerFee"] = updatedBrokerFee;
          // }
        }
        // console.log(props)
        // savedProduct['brokerFee'] = 200
        // console.log("after change")
        // console.log(savedProduct)
        if (index >= startIndex && index < startIndex + range) {
          return (
            <Grid key={savedProduct.id} item xs={12} sm={12} md={4}>
              <SavedPlanCard
                notify={props.notify}
                {...savedProduct}
                index={index}
                deleteSaved={props.deleteSaved}
                actions
                submit={props.submit}
                editable={props.editable}
                editFunction={props.editFunction}
              />
            </Grid>
          );
        }
      })}
    </Grid>
  );
}

import React from "react";
import {RaterContext} from "../../RaterV3";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import InfoModal from "../../Modals/InfoModal";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
    card: {
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        borderRadius: 10,
        minHeight: 150,
        border: '2px solid white',
        '&:hover': {
            cursor: 'pointer',
            border: `2px solid ${theme.palette.primary.main}`
        }
    },
}))



const prettify = str => {
    return str.replace('Ltdmed', 'Limited Medical').replace('major_medical', 'Level Funded Health Plans').toUpperCase()
}

export const ProductCard = props => {
    const classes = useStyles()
    const [openInfo, setOpenInfo] = React.useState(false)
    const {productDescriptionMapping} = React.useContext(RaterContext)

    return (

        <Paper className={classes.card} onClick={() => props.toggle(props.index)} style={{border: props.selected ? '2px solid darkblue':'2px solid white'}} elevation={props.selected ? 5:1}>
            <Grid container justify={'center'} alignItems={'center'} spacing={1}>
                <Grid item xs={12} container justify={'center'} alignContent={'center'} alignItems={'center'} style={{height:85}}>
                    <Typography align={'center'} variant={'h6'} style={{fontWeight:'bold'}} gutterBottom>
                        {prettify(props.name)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Typography align={'center'} variant={'body2'}>
                        Required:
                        {props.required === 1 ? ' 4-Tier Census': props.required === 2 ? " 4-Tier Census":" Full Census"}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <IconButton color={'#0C2659'}  onClick={() => setOpenInfo(true)}>
                    <InfoIcon style={{color: '#0C2659'}}/>
                </IconButton>
            </Grid>
            <InfoModal open={openInfo} close={() => setOpenInfo(false)} {...productDescriptionMapping[props.name.toLowerCase()]}  name={prettify(props.name)}/>
        </Paper>)
}

import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { RaterContext } from "../../RaterV3";
import PDF from "../../Assets/state-availability-2024.pdf";
import { ProductCard } from "./ProductCard";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  link: {
    color: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
}));

export default function SelectProducts() {
  const { log, setValue, allProducts, setAllProducts, requiredLevel, setRequiredLevel, hasOpportunity, handleSendHSA } =
    React.useContext(RaterContext);
  const [numSelected, setNumSelected] = React.useState(0);
  const classes = useStyles();

  useEffect(() => {
    handleSendHSA(true);
  }, []);

  /*Opens up the availability PDF in a new tab.*/
  const openAvailability = () => {
    log("view_availability");
    window.open(PDF, "_blank");
  };

  const openOptimedProducts = () => {
    log("view_optimed_product_details");
    window.open("https://www.optimedhealth.com/products-services/", "_blank");
  };
  /*NOTE:
    A simple useEffect that keeps track of the global need level and what will be required so that the user
     knows what they will need to supply in the future.
     */
  React.useEffect(() => {
    checkNeedLevel();
  }, [allProducts]);

  const checkNeedLevel = () => {
    let counter = 0;
    let needLevel = 0;
    allProducts.forEach((product) => {
      if (product.selected) {
        if (product.required > needLevel) needLevel = product.required;
        counter = counter + 1;
      }
    });
    setNumSelected(counter);
    setRequiredLevel(needLevel);
  };

  const toggleSelect = (index) => {
    let arr = [...allProducts];
    arr[index].selected = !arr[index].selected;
    setAllProducts(arr);
  };

  /*NOTE:
    Depending on the route the user took to get to this point, hitting continue will either take you to step 2 or step 3.
     Depends on the presence of a global opportunity or not.
     */
  const handleForward = () => {
    log("selected_products", {
      selected: allProducts.filter((x) => x.selected).map((x) => x.name),
    });
    hasOpportunity ? setValue(3) : setValue(2);
  };

  return (
    <div className={classes.paper}>
      <Grid container justify={"center"}>
        <Grid item xs={12} container justify={"space-between"}>
          <Grid item xs={6}>
            <Typography variant={"h4"} style={{ fontWeight: "bold" }}>
              Pick your products
            </Typography>
            <Typography>Select all the products you want to quote.</Typography>
          </Grid>
          <Grid item xs={6} container>
            <Grid item xs={12}>
              <Typography onClick={openAvailability} className={classes.link} align={"right"}>
                View product availability
              </Typography>
            </Grid>
            {/* <Grid item xs={12}>
                            <Typography onClick={openOptimedProducts} className={classes.link} align={'right'}>
                                View benefit plan product page
                            </Typography>
                        </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography color={"primary"}>Number of products selected: {numSelected}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography color={"primary"}>
            You will need:{" "}
            {requiredLevel === 1
              ? "Eligibility Count"
              : requiredLevel === 2
              ? "4-Tier Census"
              : requiredLevel === 3
              ? "Full Census"
              : "N/A"}
          </Typography>
        </Grid>
        {allProducts.map((product, index) => {
          return (
            <Grid item xs={12} sm={4} md={3} key={product.name}>
              <ProductCard {...product} toggle={toggleSelect} index={index} />
            </Grid>
          );
        })}
        <Grid item xs={12}></Grid>
        <Grid item xs={10}>
          <Button
            disabled={allProducts.filter((x) => x.selected).length === 0}
            onClick={handleForward}
            fullWidth
            variant={"contained"}
            color={"#FFFFFF"}
            style={{ backgroundColor: "#0C2659", color: "#FFFFFF" }}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

import {Grid, Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import React from "react";

export default function LifeMember(props) {
    return (
        <>
            {props.data.memberRates && props.data.memberRates['50-54'] &&
            <>

                <Grid item xs={6}>
                    <Typography>Ages</Typography>
                </Grid>
                <Grid item xs={6}>

                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={6}>
                    <Typography>
                        0-34
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography align={'right'} variant={'body2'} color={'#0C2659'}>
                        ${(props.data.memberRates['0-34'] + props.fees).toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={6}>
                    <Typography>
                        35-39
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography align={'right'} variant={'body2'} color={'#0C2659'}>
                        ${(props.data.memberRates['35-39'] + props.fees).toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={6}>
                    <Typography>
                        40-44
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography align={'right'} variant={'body2'} color={'#0C2659'}>
                        ${(props.data.memberRates['40-44'] + props.fees).toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={6}>
                    <Typography>
                        45-49
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography align={'right'} variant={'body2'} color={'primary'}>
                        ${(props.data.memberRates['45-49'] + props.fees).toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={6}>
                    <Typography>
                        50-54
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography align={'right'} variant={'body2'} color={'primary'}>
                        ${(props.data.memberRates['50-54'] + props.fees).toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={6}>
                    <Typography>
                        55-59
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography align={'right'} variant={'body2'} color={'#0C2659'}>
                        ${(props.data.memberRates['55-59'] + props.fees).toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={6}>
                    <Typography>
                        60-64
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography align={'right'} variant={'body2'} color={'#0C2659'}>
                        ${(props.data.memberRates['60-64'] + props.fees).toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={6}>
                    <Typography>
                        65-69
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography align={'right'} variant={'body2'} color={'#0C2659'}>
                        ${(props.data.memberRates['65-69'] + props.fees).toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={6}>
                    <Typography>
                        70-74
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography align={'right'} variant={'body2'} color={'#0C2659'}>
                        ${(props.data.memberRates['70-74'] + props.fees).toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>

                <Grid item xs={6}>
                    <Typography>
                        75-79
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography align={'right'} variant={'body2'} color={'#0C2659'}>
                        ${(props.data.memberRates['75-79'] + props.fees).toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={6}>
                    <Typography>
                        80 +
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography align={'right'} variant={'body2'} color={'#0C2659'}>
                        ${(props.data.memberRates['80+'] + props.fees).toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                {props.data.dependent &&
                    <>
                    <Grid item xs={6}>
                        <Typography>
                            Dependent Rates
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography align={'right'} variant={'body2'} color={'#0C2659'}>
                        ${(props.data.dependent.rate).toFixed(2)}
                        </Typography>
                    </Grid>
                    </>
                }
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
            </>
            }
        </>
    )
}

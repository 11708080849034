import { Grid, Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import React from "react";

export default function MecPlus(props) {
  function isNone(stopLossOrAgg) {
    if (stopLossOrAgg === 0) {
      return (
        props.data.stopLossPremium.insuredOnly +
          props.data.stopLossPremium.insuredPlusChildren +
          props.data.stopLossPremium.insuredPlusFamily +
          props.data.stopLossPremium.insuredPlusSpouse ===
        0
      );
    } else if (stopLossOrAgg === 1) {
      return (
        props.data.aggregateAccommodation.insuredOnly +
          props.data.aggregateAccommodation.insuredPlusChildren +
          props.data.aggregateAccommodation.insuredPlusFamily +
          props.data.aggregateAccommodation.insuredPlusSpouse ===
        0
      );
    }
  }

  return (
    <>
      <Grid item xs={3}>
        <Typography variant={"body2"}>Rates</Typography>
      </Grid>

      {!isNone(0) ? (
        <Grid item xs={3}>
          <Typography align={"center"} variant={"body2"}>
            Stop Loss
          </Typography>
        </Grid>
      ) : null}
      {!isNone(1) ? (
        <Grid item xs={3}>
          <Typography align={"center"} variant={"body2"}>
            Aggregate Accomm.
          </Typography>
        </Grid>
      ) : null}
      <Grid item xs={3}>
        <Typography align={"right"} variant={"body2"}>
          Total
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {props.data.rates.insuredOnly && (
        <>
          <Grid item xs={12}>
            <Typography>Insured Only</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              style={{ color: "#0C2659" }}
              align={"left"}
              variant={"body2"}
            >
              $
              {(
                props.data.rates.insuredOnly +
                props.data.extras.insuredOnly +
                props.fees
              ).toFixed(2)}
            </Typography>
          </Grid>
          {!isNone(0) ? (
            <Grid item xs={3}>
              <Typography
                style={{ color: "#0C2659" }}
                align={"center"}
                variant={"body2"}
              >
                ${props.data.stopLossPremium.insuredOnly.toFixed(2)}
              </Typography>
            </Grid>
          ) : null}
          {!isNone(1) ? (
            <Grid item xs={3}>
              <Typography
                style={{ color: "#0C2659" }}
                align={"center"}
                variant={"body2"}
              >
                ${props.data.aggregateAccommodation.insuredOnly.toFixed(2)}
              </Typography>
            </Grid>
          ) : null}
          <Grid item xs={3}>
            <Typography
              style={{ color: "#0C2659" }}
              align={"right"}
              variant={"body2"}
            >
              $
              {(
                props.data.rates.insuredOnly +
                props.data.stopLossPremium.insuredOnly +
                props.data.aggregateAccommodation.insuredOnly +
                props.data.extras.insuredOnly +
                props.fees
              ).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}
      {props.data.rates.insuredPlusSpouse && (
        <>
          <Grid item xs={12}>
            <Typography>Insured Plus Spouse</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography
              style={{ color: "#0C2659" }}
              align={"left"}
              variant={"body2"}
            >
              $
              {(
                props.data.rates.insuredPlusSpouse +
                props.fees +
                props.data.extras.insuredPlusSpouse
              ).toFixed(2)}
            </Typography>
          </Grid>
          {!isNone(0) ? (
            <Grid item xs={3}>
              <Typography
                style={{ color: "#0C2659" }}
                align={"center"}
                variant={"body2"}
              >
                ${props.data.stopLossPremium.insuredPlusSpouse.toFixed(2)}
              </Typography>
            </Grid>
          ) : null}

          {!isNone(1) ? (
            <Grid item xs={3}>
              <Typography
                style={{ color: "#0C2659" }}
                align={"center"}
                variant={"body2"}
              >
                $
                {props.data.aggregateAccommodation.insuredPlusSpouse.toFixed(2)}
              </Typography>
            </Grid>
          ) : null}

          <Grid item xs={3}>
            <Typography
              style={{ color: "#0C2659" }}
              align={"right"}
              variant={"body2"}
            >
              $
              {(
                props.data.rates.insuredPlusSpouse +
                props.data.aggregateAccommodation.insuredPlusSpouse +
                props.data.stopLossPremium.insuredPlusSpouse +
                props.data.extras.insuredPlusSpouse +
                props.fees
              ).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}
      {props.data.rates.insuredPlusChildren && (
        <>
          <Grid item xs={12}>
            <Typography>Insured Plus Children</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              style={{ color: "#0C2659" }}
              align={"left"}
              variant={"body2"}
            >
              $
              {(
                props.data.rates.insuredPlusChildren +
                props.fees +
                props.data.extras.insuredPlusChildren
              ).toFixed(2)}
            </Typography>
          </Grid>

          {!isNone(0) ? (
            <Grid item xs={3}>
              <Typography
                style={{ color: "#0C2659" }}
                align={"center"}
                variant={"body2"}
              >
                ${props.data.stopLossPremium.insuredPlusChildren.toFixed(2)}
              </Typography>
            </Grid>
          ) : null}
          {!isNone(1) ? (
            <Grid item xs={3}>
              <Typography
                style={{ color: "#0C2659" }}
                align={"center"}
                variant={"body2"}
              >
                $
                {props.data.aggregateAccommodation.insuredPlusChildren.toFixed(
                  2
                )}
              </Typography>
            </Grid>
          ) : null}

          <Grid item xs={3}>
            <Typography
              style={{ color: "#0C2659" }}
              align={"right"}
              variant={"body2"}
            >
              $
              {(
                props.data.rates.insuredPlusChildren +
                props.data.aggregateAccommodation.insuredPlusChildren +
                props.data.stopLossPremium.insuredPlusChildren +
                props.data.extras.insuredPlusChildren +
                props.fees
              ).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}
      {props.data.rates.insuredPlusFamily && (
        <>
          <Grid item xs={12}>
            <Typography>Insured Plus Family</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              style={{ color: "#0C2659" }}
              align={"left"}
              variant={"body2"}
            >
              $
              {(
                props.data.rates.insuredPlusFamily +
                props.fees +
                props.data.extras.insuredPlusFamily
              ).toFixed(2)}
            </Typography>
          </Grid>

          {!isNone(0) ? (
            <Grid item xs={3}>
              <Typography
                style={{ color: "#0C2659" }}
                align={"center"}
                variant={"body2"}
              >
                ${props.data.stopLossPremium.insuredPlusFamily.toFixed(2)}
              </Typography>
            </Grid>
          ) : null}

          {!isNone(1) ? (
            <Grid item xs={3}>
              <Typography
                style={{ color: "#0C2659" }}
                align={"center"}
                variant={"body2"}
              >
                $
                {props.data.aggregateAccommodation.insuredPlusFamily.toFixed(2)}
              </Typography>
            </Grid>
          ) : null}
          <Grid item xs={3}>
            <Typography
              style={{ color: "#0C2659" }}
              align={"right"}
              variant={"body2"}
            >
              $
              {(
                props.data.rates.insuredPlusFamily +
                props.data.aggregateAccommodation.insuredPlusFamily +
                props.data.stopLossPremium.insuredPlusFamily +
                props.data.extras.insuredPlusFamily +
                props.fees
              ).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}
    </>
  );
}

import {Step, StepLabel, Stepper} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {RaterContext} from "../RaterV3";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import HideOnScroll from "../../utils/HideOnScroll";
import {FirebaseContext} from "react-ugp-firebase";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        alignContent: 'center',
        alignItems: 'center',
        "& .MuiStepIcon-active": { color: "#0C2659" },
        "& .MuiStepIcon-completed": { color: "#0C2659" },
        "& .MuiStepIcon-root.MuiStepIcon-active": { color: "#0C2659" },
        
    },
    stepper: {
        position: 'fixed',
        width: '100%',
        paddingTop: theme.spacing(6),
        top: 15,
        zIndex: 100,
        iconColor: '#0C2659',
        color: '#0C2659'
    },
    panel: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(18),
    },
    click: {
        '&:hover': {
            cursor: 'pointer',
        }
    },
}));
export default function StepperComponent() {

    const {setBrokerModal, setMessage, setError, value, setValue, steps, hasOpportunity} = React.useContext(RaterContext)
    const classes = useStyles()

    const { user, isUserLoading, } = React.useContext(FirebaseContext);

    const userRole = isUserLoading ? [] : user.roles

    return (
        <div className={classes.stepper} >
            <Container maxWidth={'lg'}>
                <HideOnScroll>
                    <Stepper activeStep={value}>
                        {steps.map((step, index) => {
                            return (
                                <Step key={step} onClick={() => {
                                    if (index !== 2) {
                                        setValue(index)
                                    }
                                    else if (hasOpportunity) {

                                        if (userRole.includes('employee')){

                                            // console.log("is Employee")

                                            setBrokerModal(true)

                                        } else  if (!userRole.includes('employee')){

                                            // console.log("Not Employee")

                                            setError(true)
                                            setMessage("You already have a group to quote and cannot change the group's info. If you need to quote a new group either finish quoting your current group or refresh and start over.")
                                        }

                                    }
                                    else {
                                        setValue(index)
                                    }
                                }} disabled className={classes.click}>
                                    <StepLabel className={classes.click}>{step}</StepLabel>
                                </Step>
                            )
                        })}
                    </Stepper>
                </HideOnScroll>


            </Container>
        </div>)
}

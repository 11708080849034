import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import InfoIcon from '@material-ui/icons/InfoRounded';
import IconButton from "@material-ui/core/IconButton";
import Checkbox from '@material-ui/core/Checkbox';
import ToolTip from "@material-ui/core/Tooltip";
import _ from 'lodash';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const allAddons = {
    "gap" : [
        {
            id: 'test1',
            title: 'Test 1',
            description: 'Some short desciption of Test 1 option',
            infoLink: 'http://www.google.com',
            cost: 0.00,
            included: true
        },
        {
            id: 'test2',
            title: 'Test 2',
            description: 'Some short desciption of Test 2 option',
            infoLink: 'http://www.google.com',
            cost: 0.00,
            included: true
        },
        {
            id: 'test3',
            title: 'Test 3',
            description: 'Some short desciption of Test 3 option',
            infoLink: 'http://www.google.com',
            cost: 20.00,
            included: false
        },
        {
            id: 'test4',
            title: 'Test 4',
            description: 'Some short desciption of Test 4 option',
            cost: 30.00,
            included: false
        },
        {
            id: 'test5',
            title: 'Test 5',
            description: 'Some short desciption of Test 5 option',
            cost: 40.00,
            included: false
        },
        {
            id: 'test6',
            title: 'Test 6',
            description: 'Some short desciption of Test 6 option',
            infoLink: 'http://www.google.com',
            cost: 50.00,
            included: false
        }
    ]
}

export default function AddonsModal({ productType, open, onSave, pastSelections }) {

    const addons = allAddons[productType] || [];

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    const minSelected = _.filter(addons, addon => addon.included);

    const [selected, setSelected] = useState(minSelected);

    const total = selected.length === 0 ? 0 : _.reduce(selected, (sum, next) => sum + next.cost, 0.0);

    const onChange = (id) => (event) => {

        const checked = event.target.checked;

        if (checked) {

            setSelected(_.union(selected, _.filter(addons, s => s.id === id)))

        } else {

            setSelected(_.filter(selected, s => s.id !== id))
        }
    }

    const showSave = (selected.length - minSelected.length) > 0;

    const isChecked = (id) => _.filter(selected, s => s.id === id).length > 0;

    const onInteralSave = () => {

        onSave(selected);
        setSelected(minSelected);
    }

    const onClear = () => setSelected(minSelected)

    useEffect(() => {
        
        if ((pastSelections || []).length > 0) {

            setSelected(_.cloneDeep(pastSelections))
        }

    }, [pastSelections])

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth='sm'
                open={open}
                TransitionComponent={Transition}
                keepMounted={true}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                      // close();
                    }
                }}
            >
                <DialogTitle style={{ paddingBottom: 0 }}>
                    <div style={{ display:'flex', alignContent: 'flex-start' }}>
                        <div style={{ display:'flex', justifyContent: 'center' }}>
                            <div style={{ height: 24, alignSelf: 'center' }}>
                                <AddIcon style={{ color: 'darkgray' }}/>
                            </div>
                        </div>
                        <div style={{ marginLeft: 5 }}><Typography variant={'h5'} color={'primary'} style={{ fontWeight: 'bold' }}>Additional Plan Options</Typography></div>
                    </div>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }}/>
                    <Typography variant={'body2'}>Select any of the available options by checking the box. The amount shown is <b>montly cost per member</b> and will be added to the final rates. If more information is available, click on info icon next to option title.</Typography>
                </DialogTitle>
                <DialogContent style={{ paddingBottom: 0 }}>
                    <div style={{ display:'flex', justifyContent: 'center', marginBottom: 5 }}>
                        <div><Typography color={'primary'} variant={'caption'}><i>Scroll to see all options</i></Typography></div>  
                    </div>
                    <Card variant="outlined" square style={{ padding: 10, maxHeight: 250, overflowY: 'scroll' }}>
                        <Grid container spacing={1}>
                            {_.map(addons, (option, index) => {
                                return (
                                    <Grid item container xs={12} key={option.id}>
                                        <Grid item xs={1}><Checkbox onChange={onChange(option.id)} disabled={option.included} checked={isChecked(option.id)} color={'primary'}/></Grid>
                                        <Grid item container xs={11}>
                                            <Grid item container direction='column' xs={10} spacing={0}>
                                                <Grid item>
                                                    <div style={{ display:'flex', alignContent: 'flex-start' }}>
                                                        <div style={{ alignSelf: 'center' }}>
                                                            <Typography color={'primary'} variant={'subtitle1'} style={{ fontWeight: 'bold' }}>
                                                                {`${option.title}${option.included ? ' (Included)' : ''}`}
                                                            </Typography>
                                                        </div>
                                                        {!_.isUndefined(option.infoLink) &&
                                                            <ToolTip title="Click here for more information">
                                                                <IconButton style={{ padding: 0, marginLeft: 5 }} onClick={() => window.open(option.infoLink, "_blank")}>
                                                                    <InfoIcon color={'#0C2659'}  style={{color: '#0C2659'}} fontSize="small"/>
                                                                </IconButton>
                                                            </ToolTip>
                                                        }
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant={'caption'}>{option.description}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={2} style={{ textAlign: 'right' }}>
                                                <div style={{ height: '100%', display:'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                    <div style={{ height: 28, alignSelf: 'center' }}><Typography variant={'subtitle1'}>{formatter.format(option.cost)}</Typography></div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        {index < addons.length - 1 && 
                                            <Grid item xs={12} style={{ marginTop: 7 }}><Divider/></Grid>
                                        }
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Card>
                    <div style={{ display:'flex', justifyContent: 'flex-end', marginTop: 5, marginBottom: 5, marginRight: 26 }}>
                       <div><Typography variant={'subtitle1'} style={{ marginRight: 0, fontWeight: 'bold' }}>Total Added Per Member</Typography></div>  
                       <div style={{ width: 100, textAlign: 'right' }}><Typography variant={'subtitle1'}>{formatter.format(total)}</Typography></div>   
                    </div>
                </DialogContent>
                <DialogActions>
                <Grid container spacing={0}>
                    <Grid item xs={6}>
                        <Button variant="contained" onClick={onClear} style={{ backgroundColor: '#0C2659', color: '#FFFFFF', width: 100 }}>
                            Clear
                        </Button>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <Button variant="contained" onClick={onInteralSave} style={{backgroundColor: '#0C2659', color: '#FFFFFF' , width: 100 }} color={'primary'}>
                            {showSave ? 'Save' : 'Skip'}
                        </Button>
                    </Grid>
                </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
}

import _ from 'lodash'

let inpatientOptions = []
for (let i=1000;i<10001;i++) {
    if (i%50 === 0) {
        inpatientOptions.push(i)
    }
}
for (let i=10000;i<100000;i++) {
    if (i%500 === 0) {
        inpatientOptions.push(i)
    }
}
inpatientOptions.push(500,750)
inpatientOptions = _.sortBy(inpatientOptions, x => x)


export default inpatientOptions

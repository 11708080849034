import axios from 'axios';
import { FirebaseDB } from 'react-ugp-firebase'


const PRODUCTS_URL =
	process.env.NODE_ENV === 'development' ?
		'http://localhost:3001/rating-engine/v1/products' :
		'/rating/products/';

const BENEFITS_URL =
	process.env.NODE_ENV === 'development' ?
		'http://localhost:3001/rating-engine/v1/benefits' :
		'/rating/benefits/';


const OPP_URL =
	process.env.NODE_ENV === 'development' ?
		'http://localhost:13001/opportunity-manager/v1/crm/' :
		'/rating/crm/';

const STORE_URL =
	process.env.NODE_ENV === 'development' ?
		`${OPP_URL}storeRates` :
		'/rating/crm/storeRates';

const STARTER_URL =
	process.env.NODE_ENV === 'development' ?
		`http://localhost:3001/rating-engine/v1/starter/gap` :
		'/rating/starter/gap';


const getFirebaseOpp = id => {
	return FirebaseDB.collection('opportunities').doc(id).get()
}



export class PlansApi {
	getOpportunity(oppId, config) {
		return new Promise((resolve, reject) => {
			getFirebaseOpp(oppId)
				.then(last => {
						const url = `${OPP_URL}${oppId}/group`

							axios.get(url, config)
								.then(res => {
									// console.log(res, last)
									resolve( {
										...res,
										...last.data()
									})
								})
								.catch(err => {
									reject(err)
								})

				})
		})
	}
}
export const postRates = (data, config) => {
	const url = `${STORE_URL}`;
	return axios.post(url, data, config);
}

export const getProducts = (data, headers) =>  {
	return axios({
		method:'post',
		url: PRODUCTS_URL,
		data: data,
		headers: headers
	})
}


export const getSupportBenefits = (data, headers, provider, plan) => {
		let url = BENEFITS_URL + `/${provider}?plan=${plan}`
		return axios({
			method:'post',
			url: url,
			data: data,
			headers: headers
		})
}


export const getGapPlans = (data, headers) => {
	return axios({
		method: 'post',
		url: STARTER_URL,
		data: data,
		headers: headers
	})
}

import _ from 'lodash'

//This function is really neat and takes all null values within an object (any amount of layers deep) and converts it to a 0
export const removeNullValues = obj => {
    return _(obj)
        .pickBy(_.isObject) // get only objects
        .mapValues(removeNullValues) // call only for values as objects
        .assign(_.omitBy(obj, _.isObject)) // save back result that is not object
        .mapValues(x => x === null ? 0:x) // remove null and undefined from object
        .value(); // get value
}

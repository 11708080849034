import React, { useState } from 'react'
import _ from 'lodash'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import {numberTest} from "../../utils/numberTest";

const inputStyle = {height: 40 }

const LongFreeInput = (props) => {

    const [hasFocus, setHasFocus] = useState(false)
    const [internalValue, setInternalValue] = useState(undefined)
    const [isError, setError] = useState(false)

    const resetFocus = input => {

        const setFocus = (i) => {

            if (i && hasFocus) {

                setTimeout(() => {
                    input.focus()
                }, 150);
            }
        }

        setFocus(input)
    }



    const onChangeHandler = (evt) => {

        const enteredValue = evt.target.value

        setHasFocus(true)
        setInternalValue(enteredValue)

        var re = new RegExp("^[0-9]+$")

        if (re.test(enteredValue) && enteredValue !== '') {

            setError(false)
            props.onChange(enteredValue)

        } else {

            props.onInvalid()
            setError(true)
        }
    }

    const onBlurHandler = (evt) => {

        if (!props.isWalking) {

            setHasFocus(false)
        }
    }

    return (
        <OutlinedInput
            {...(_.omit(props, ['onChange', 'value', 'onInvalid', 'isLast', 'isWalking', 'isError']))}
            style={inputStyle}
            type="number"
            onChange={onChangeHandler}
            inputRef={resetFocus}
            onBlur={onBlurHandler}
            onKeyDown={numberTest}
            error={isError}
            fullWidth
            value={props.isError ? _.isUndefined(internalValue) ? '' : internalValue : _.isUndefined(props.value) ? '' : props.value}
        />
    )
}

export default LongFreeInput

import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Container} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {FirebaseContext} from "react-ugp-firebase";
import {RaterContext} from "../RaterV3";

const useStyles = makeStyles(theme => ({
    content: {
        color: theme.palette.secondary.main

    }
}));


export default function LoadingDialog() {
    const classes = useStyles()
    const {isUserLoading} = React.useContext(FirebaseContext)
    const {loading} = React.useContext(RaterContext)
    return (
        <Container className={classes.root} component="section">
            <Dialog
                open={isUserLoading || loading}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'lg'}
            >
                {/* Seems to be a pixel on the bottom of circular progress - thats why marginBottom is 2 pixels less */}
                <DialogContent className={classes.content} style={{ paddingTop: '8px', marginTop: '8px', marginBottom: '6px' }}>
                    <CircularProgress thickness={4}/>
                </DialogContent>
            </Dialog>
        </Container>
    );
}

import React, {useState} from 'react'
import _ from 'lodash'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from "@material-ui/core/InputAdornment";

const formatPercent = (toFormat) => {
    return parseFloat(Math.round(toFormat * 100)).toFixed(0)
}


const numberTest = (evt) => {
    var charCode = !evt.charCode ? evt.which : evt.charCode
    if (charCode===107 || charCode===109 ||charCode===69) {
        evt.preventDefault()
        return false
    }
}
const inputStyle = { height: 40 }

const PercentFreeInput = (props) => {
    const [hasFocus, setHasFocus] = useState(false)
    const [internalValue, setInternalValue] = useState(undefined)
    const [isError, setError] = useState(false)


    const resetFocus = input => {

        const setFocus = (i) => {

            if (i && hasFocus) {

                setTimeout(() => {
                    input.focus()
                }, 150);
            }
        }

        setFocus(input)
    }

    const onChangeHandler = (evt) => {
        setHasFocus(true)
        const enteredValue = evt.target.value
        setInternalValue(enteredValue)
        var re = new RegExp("^[0-9]?$|^[1-9][0-9]?$|^100$")
        if (re.test(enteredValue) && enteredValue !== '') {
            setError(false)
            props.onChange(enteredValue/100)
        } else {
            setError(true)
        }
    }
    const onBlurHandler = (evt) => {
        if (!props.isWalking) {
            setHasFocus(false)
        }
    }


    return (
        <>
                <OutlinedInput
                    fullWidth
                    {...(_.omit(props, ['onChange', 'value', 'onInvalid', 'isLast', 'isWalking', 'isError']))}
                    style={inputStyle}
                    type="number"
                    onChange={onChangeHandler}
                    inputRef={resetFocus}
                    onBlur={onBlurHandler}
                    onKeyDown={numberTest}
                    error={isError}
                    endAdornment={
                            <InputAdornment position={'end'}>
                                %
                            </InputAdornment>
                    }
                    value={_.isUndefined(internalValue) ? props.value === '' ? '' : formatPercent(props.value) : internalValue}
                />
        </>
    )
}
export default PercentFreeInput

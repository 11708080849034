import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {RaterContext} from "../RaterV3";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";




const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function GroupModal() {
    const {groupName, groupEffectiveDate, sicCode, zipCode, state, groupInfoOpen, setGroupInfoOpen} = React.useContext(RaterContext)
    return (
        <div>
            <Dialog
                open={groupInfoOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setGroupInfoOpen(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <IconButton onClick={() => setGroupInfoOpen(false)} color={'#0C2659'} style={{position: 'absolute', right: 10, top: 10}}>
                    <CloseIcon style={{backgroundColor: '#0C2659', color: 'white'}}/>
                </IconButton>
                <DialogTitle id="alert-dialog-slide-title">Group Information</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography>
                                Name
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography align={'right'} variant={'body2'}>
                                {groupName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>
                                Group Effective Date
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography align={'right'} variant={'body2'}>
                                {groupEffectiveDate}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>
                                State
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography align={'right'} variant={'body2'}>
                                {state.label ? state.label: state}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>
                                SIC Code
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography align={'right'} variant={'body2'}>
                                {sicCode}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>
                                Zip Code
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography align={'right'} variant={'body2'}>
                                {zipCode}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </div>
    );
}

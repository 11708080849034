import React, {useState} from 'react'
import _ from 'lodash'
import ReactSelect from 'react-select/async'
import FormControl from "@material-ui/core/FormControl";

const CustomSelect = (props) => {
    const [menuOpen, setMenuOpen] = useState(false)

    const selectStyles = {
        control: styles => {
            return {
                height: '40px',
                ...styles
            }
        },
        option: styles => {
            return {
                ...styles,
            }
        },
        input: styles => {
            return {
                height: '30px',
                ...styles
            }
        },
        placeholder: styles => ({ ...styles}),
        singleValue: (styles) => ({ ...styles}),
    }

    return (
        <ReactSelect
            openMenuOnClick={false}
            onInputChange={(value, action) => setMenuOpen(value.trim().length > 2)}
            menuIsOpen={menuOpen}
            maxMenuHeight={140}
            styles={selectStyles}
            placeholder='Type first 3 letters ...'
            {...{
                ...props, ...{
                    value: _.find(props.options, obj => obj.value === props.value) || '',
                }
            }}
        />
    )
}

export const OptionsSelect = (props) => {

    const cleanedProps = _.omit(props, 'onValueChanged')

    return (

        <FormControl variant="outlined" style={{ height: '40px', width: '100%' }} >
            <CustomSelect {...cleanedProps} onChange={obj => props.onValueChanged(obj.value)} />
        </FormControl>
    )
}

export const search = (collection, searchKey, setOptions) => (input) => {

    return new Promise(resolve => {

        if (_.trim(input).length < 3) {

            resolve([])

        } else {

            const textEnteredByUser = _.upperCase(input)
            const toText = `${textEnteredByUser.substring(0, textEnteredByUser.length - 1)}${String.fromCharCode(_.last(textEnteredByUser).charCodeAt(0) + 1)}`

            collection
                .where(searchKey, '>=', textEnteredByUser)
                .where(searchKey, '<', toText)
                .get()
                .then(res => {
                    const options = res.docs.map(doc => { return { value: doc.id, label: `${doc.data()[searchKey]}` } })
                    setOptions(options)
                    resolve(options)
                })
                .catch(err => {

                    console.error(err)
                    resolve([])
                })
        }
    })
}

import React from "react";
import { CSVReader } from "react-papaparse";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { CSVLink } from "react-csv";
import { RaterContext } from "../../RaterV3";
import _ from "lodash";
import dateformat from "dateformat";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
  },
  grid: {
    marginTop: theme.spacing(2),
  },
}));

export default function CensusUpload() {
  const { setCensus, validateCensus, setError, setMessage, setLoading, setSuccess } = React.useContext(RaterContext);
  const classes = useStyles();

  const data = [
    ["name", "gender", "coverage", "dob"],
    ["John Smith", "M", "EE", "03/02/1957"],
    ["Becky Reed", "F", "ES", "10/20/1988"],
    ["Gilbert Ronahue", "M", "EF", "05/17/1990"],
    ["Rhonda Marty", "F", "EE", "07/01/1983"],
    ["Gina Flex", "F", "ES", "08/20/1977"],
    ["Bill Gilfoyle", "M", "EF", "01/14/1987"],
  ];
  const buttonRef = React.createRef();

  console.log("Running Census");

  const handleReadCSV = (data) => {
    setLoading(true);
    console.log("checking data in handleReadCSV");
    console.log(data);

    // Filter out rows with errors only if the data is blank
    const validData = data.filter(
      (row) => row.errors.length === 0 || Object.values(row.data).some((value) => value !== "")
    );

    // Extract column names from the first row's meta.fields
    const columns = validData[0].meta.fields;

    console.log("this is columns");
    console.log(columns);

    let employees = validData.map((row) => row.data);

    const invalidDates = [];
    const dobRanges = employees
      .map((employeeData, index) => {
        // Try parsing with both YY and YYYY formats
        let dob = moment(employeeData.dob, ["M/D/YY", "MM/DD/YYYY", "M/D/YYYY"], true);

        if (!dob.isValid()) {
          invalidDates.push({ index: index + 1, date: employeeData.dob });
          return null;
        }

        // If the parsed year is in the future, subtract 100 years
        if (dob.year() > moment().year()) {
          dob = dob.subtract(100, "years");
        }

        const now = moment();
        return now.diff(dob, "days");
      })
      .filter((age) => age !== null);

    if (invalidDates.length > 0) {
      setError(true);
      setLoading(false);
      const invalidDatesMessage = invalidDates.map((item) => `Row ${item.index}: ${item.date}`).join(", ");
      setMessage(
        `Invalid date(s) found in the census: ${invalidDatesMessage}. Please correct these dates and try again.`
      );
      return;
    }

    console.log("this is dobRanges");
    console.log(dobRanges);

    //3652 === 10 years (Including leap years) from now() function.
    const dobCheck = (arr) => arr.filter((x) => x < 3652).length;

    console.log("this is dobCheck");
    console.log(dobCheck(dobRanges));

    if (dobCheck(dobRanges) === 0) {
      // Function that makes all the keys lower case so that the file columns are not case sensitive.
      employees = employees.map((x) => {
        return _.mapKeys(x, (value, key) => key.toLowerCase());
      });

      employees = employees.map((employee) => ({
        name: employee.name,
        dob: dateformat(employee.dob, "mm/dd/yyyy", true),
        coverage: employee.coverage,
        gender: employee.gender ? employee.gender.charAt(0) : undefined,
      }));

      // Filter function to make sure that every employee object has a defined value for all 4 required fields.
      employees = _.filter(employees, (x) => x.name && x.dob && x.coverage && x.gender);
      validateCensus(employees, columns)
        .then((res) => {
          setSuccess(true);
          setMessage("Census uploaded successfully. Verify your census is completed and continue.");
          setLoading(false);
          setCensus(employees);
        })
        .catch((err) => {
          console.log("error in catch CENSUS");
          console.log(err);
          setLoading(false);
          setError(true);
          setMessage(
            typeof err === "string"
              ? err
              : "Invalid census format. You must either fill out census manually or upload a file with the proper format."
          );
        });
    } else {
      setError(true);
      setLoading(false);
      setMessage("Your census contains an ineligible DOB for a member. Eligible members must be 10 years or older.");
    }
    // Return both the processed employees and the columns
    return { employees, columns };
  };

  const handleOnError = (err) => {
    console.log(err);
  };
  const openDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  return (
    <>
      <Grid container item xs={12} spacing={2} justify="center" className={classes.grid}>
        <Grid item xs={12}>
          <CSVLink filename={"ExampleCensus.csv"} className={classes.link} data={data}>
            <Button
              fullWidth
              variant={"outlined"}
              color={"primary"}
              style={{ backgroundColor: "#0C2659", color: "#FFFFFF" }}
            >
              Download optimed census template
            </Button>
          </CSVLink>
        </Grid>
        <Grid item xs={12}>
          <CSVReader
            ref={buttonRef}
            onFileLoad={handleReadCSV}
            onError={handleOnError}
            noClick
            noDrag
            config={{ header: true }}
          >
            {({ file }) => (
              <Button
                fullWidth
                variant={"outlined"}
                color="primary"
                style={{ backgroundColor: "#0C2659", color: "#FFFFFF" }}
                onClick={openDialog}
              >
                Upload New Optimed Census Template
              </Button>
            )}
          </CSVReader>
        </Grid>
      </Grid>
    </>
  );
}

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import { RaterContext } from "../RaterV3";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import PDF from "../Assets/state-availability-2024.pdf";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function HelpModal(props) {
  const { helpOpen, setHelpOpen, setReleaseOpen } = React.useContext(RaterContext);
  const classes = useStyles();

  const openAvailability = () => {
    window.open(PDF, "_blank");
  };

  return (
    <div>
      <Dialog
        open={helpOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setHelpOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <IconButton
          onClick={() => setHelpOpen(false)}
          color={"#0C2659"}
          style={{ position: "absolute", right: 10, top: 10 }}
        >
          <CloseIcon style={{ backgroundColor: "#0C2659", color: "white" }} />
        </IconButton>
        <DialogTitle id="alert-dialog-slide-title">Help & Support</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container justify={"center"} spacing={3}>
            <Grid item xs={12}>
              <Typography variant={"h5"}>
                For any questions or support, please contact us at <a href={"tel:8004828770"}>800-482-8770</a> or{" "}
                <a href="mailto: sales@optimedhealth.com">sales@optimedhealth.com</a>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography onClick={openAvailability} className={classes.link}>
                Click here to view availability of our products.
              </Typography>
              <Typography onClick={() => setReleaseOpen(true)} className={classes.link}>
                Click here to view our release notes for all versions of the OptiRater
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import {Grid, Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import React from "react";

export default function STD(props) {
    let rates = props.data.ratesPerTenDollars ? props.data.ratesPerTenDollars : ''
    return (
        <>
        <Grid item xs={6}>
            <Typography>
                Age Range
            </Typography>
        </Grid>
            <Grid item xs={6}>
                <Typography align={'right'}>
                    Rate Per Ten Dollars
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={6}>
                <Typography>
                    {'< 30'}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color={'primary'} align={'right'}>
                    ${rates.lessThen30 ? rates.lessThen30.toFixed(2): ''}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={6}>
                <Typography>
                    {'30-34'}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color={'primary'} align={'right'}>
                    ${rates['30-34'].toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={6}>
                <Typography>
                    {'35-39'}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color={'primary'} align={'right'}>
                    ${rates['35-39'].toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={6}>
                <Typography>
                    {'40-44'}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color={'primary'} align={'right'}>
                    ${rates['40-44'].toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={6}>
                <Typography>
                    {'45-49'}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color={'primary'} align={'right'}>
                    ${rates['45-49'].toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={6}>
                <Typography>
                    {'50-54'}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color={'primary'} align={'right'}>
                    ${rates['50-54'].toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={6}>
                <Typography>
                    {'55-59'}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color={'primary'} align={'right'}>
                    ${rates['55-59'].toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={6}>
                <Typography>
                    {'60-64'}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color={'primary'} align={'right'}>
                    ${rates['60-64'].toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={6}>
                <Typography>
                    {'65-69'}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color={'primary'} align={'right'}>
                    ${rates['65-69'].toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={6}>
                <Typography>
                    {'70 +'}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color={'primary'} align={'right'}>
                    ${rates['70+'].toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
        </>
    )

}

import React from 'react'
import _ from "lodash";
import {getGapPlans} from "../../../utils/engine";
import uuidv4 from "uuid/v4";
import {RaterContext} from "../../RaterV3";
import {FirebaseContext} from "react-ugp-firebase";
import InpatientOptions from "../../../utils/InpatientOptions";
import DeductibleOptions from "../../../utils/DeductibleOptions";
import Button from '@material-ui/core/Button'
import {RatingEngineContext} from "react-rating-engine";
import Collapse from "@material-ui/core/Collapse";
import Field from "./Field";
import AddonsModal from "../../Modals/AddonsModal";


export default function NoPreferenceControl(props) {
    const {log, setLoading, gapCensus, groupEffectiveDate, sicCode, state, products, setProducts, setError, setMessage, webError, scrollToBottom} = React.useContext(RaterContext)
    const {walking} = React.useContext(RatingEngineContext)
    const {restConfig} = React.useContext(FirebaseContext)

    /* ENABLE ADDONS */
    const enableAddons = false;

    const [showAddons, setShowAddons] = React.useState(false)

    //This ref is utilized to keep track of noPreferenceFields and their values so that no timing issues occur that mess up the order of operation.
    const ref = React.useRef()


    React.useEffect(() => {
        log("no_preference_gap")
    },[])
    /*NOTE:
    * The following 4 arrays are used to dictate the path that users follow after selecting 'No Preference as their carrier option in GAP.
    * It is done this way because depending on their selection of the first field, the other fields change as well and this was the easiest
    * way I could think of doing it.
    * */
    const fullArray = [
        {name: 'benefitType', desc:"Benefit Type", options: {type: 'array', options:['Inpatient Amount Only', 'Inpatient and Outpatient Amount', 'Combined Benefit']}, value: undefined, isCurrent: true, isEnabled: true, onValueChange: (x) => handleBenefitChange(x)},
        {name: 'inpatientBenefit', desc: "Inpatient Benefit Amount", options: {type: 'array',options: InpatientOptions}, value: undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleInpatientChange(x)},
        {name: 'outpatientBenefit', desc: "Outpatient Benefit Amount", options: {type: 'array',options: []}, value: undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleOutpatientChange(x)},
        {name: 'combinedBenefit', desc: "Combined Benefit Amount", options: {type: 'array', options: InpatientOptions}, value: undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleCombinedChange(x)},
        {name: 'deductible', desc: "Deductible", options:{type: 'array', options: DeductibleOptions}, value: undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleDeductibleChange(x)},
        {name: 'employerContribution', type: "Percent", desc: "Employer Contribution %", options:{type: 'array', options: _.range(101).map(x => x / 100)}, value: undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleEmployerChange(x)},
        {name: 'includePhysicianServices', desc: "Cover Physician Office Exam", options: {type: 'array', options: [true, false]}, value: undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleIncludePhysician(x)},
        {name: 'includeMNSA', desc: "Include Mental Health and Substance Abuse Coverage", options: {type: 'array', options: [true, false]}, value: undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleIncludeMNSA(x)},
        {name: 'includeRx', desc: "Include RX Benefit", options: {type: 'array', options: [true, false]}, value: undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleIncludeRX(x)},
    ]

    const inpatientOnlyArray = [
        {name: 'benefitType', desc:"Benefit Type", options: {type: 'array', options:['Inpatient Amount Only', 'Inpatient and Outpatient Amount', 'Combined Benefit']}, value: undefined, isCurrent: true, isEnabled: true, onValueChange: (x) => handleBenefitChange(x)},
        {name: 'inpatientBenefit', desc: "Inpatient Benefit Amount", options: {type: 'array',options: InpatientOptions}, value: undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleInpatientChange(x)},
        {name: 'deductible', desc: "Deductible", options:{type: 'array', options: DeductibleOptions}, value:undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleDeductibleChange(x)},
        {name: 'employerContribution', type: "Percent", desc: "Employer Contribution %", options:{type: 'array', options: _.range(101).map(x => x / 100)}, value: undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleEmployerChange(x)},
        {name: 'includePhysicianServices', desc: "Cover Physician Office Exam", options: {type: 'array', options: [true, false]}, value: undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleIncludePhysician(x)},
        {name: 'includeMNSA', desc: "Include Mental Health and Substance Abuse Coverage", options: {type: 'array', options: [true, false]}, value: undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleIncludeMNSA(x)},
        {name: 'includeRx', desc: "Include RX Benefit", options: {type: 'array', options: [true, false]}, value: undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleIncludeRX(x)},
    ]
    const inpatientOutpatientArray = [
        {name: 'benefitType', desc:"Benefit Type", options: {type: 'array', options:['Inpatient Amount Only', 'Inpatient and Outpatient Amount', 'Combined Benefit']}, value: undefined, isCurrent: true, isEnabled: true, onValueChange: (x) => handleBenefitChange(x)},
        {name: 'inpatientBenefit', desc: "Inpatient Benefit Amount", options: {type: 'array',options: InpatientOptions}, value: undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleInpatientChange(x)},
        {name: 'outpatientBenefit', desc: "Outpatient Benefit Amount", options: {type: 'array',options: []}, value: undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleOutpatientChange(x)},
        {name: 'deductible', desc: "Deductible", options:{type: 'array', options: DeductibleOptions}, value:undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleDeductibleChange(x)},
        {name: 'employerContribution', type: "Percent", desc: "Employer Contribution %", options:{type: 'array', options: _.range(101).map(x => x / 100)}, value: undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleEmployerChange(x)},
        {name: 'includePhysicianServices', desc: "Cover Physician Office Exam", options: {type: 'array', options: [true, false]}, value: undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleIncludePhysician(x)},
        {name: 'includeMNSA', desc: "Include Mental Health and Substance Abuse Coverage", options: {type: 'array', options: [true, false]}, value: undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleIncludeMNSA(x)},
        {name: 'includeRx', desc: "Include RX Benefit", options: {type: 'array', options: [true, false]}, value: undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleIncludeRX(x)},
    ]
    const combinedOnlyArray = [
        {name: 'benefitType', desc:"Benefit Type", options: {type: 'array', options:['Inpatient Amount Only', 'Inpatient and Outpatient Amount', 'Combined Benefit']}, value: undefined, isCurrent: true, isEnabled: true, onValueChange: (x) => handleBenefitChange(x)},
        {name: 'combinedBenefit', desc: "Combined Benefit Amount", options: {type: 'array', options: InpatientOptions}, value: undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleCombinedChange(x)},
        {name: 'deductible', desc: "Deductible", options:{type: 'array', options: DeductibleOptions}, value:undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleDeductibleChange(x)},
        {name: 'employerContribution', type: "Percent", desc: "Employer Contribution %", options:{type: 'array', options: _.range(101).map(x => x / 100)}, value: undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleEmployerChange(x)},
        {name: 'includePhysicianServices', desc: "Cover Physician Office Exam", options: {type: 'array', options: [true, false]}, value: undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleIncludePhysician(x)},
        {name: 'includeMNSA', desc: "Include Mental Health and Substance Abuse Coverage", options: {type: 'array', options: [true, false]}, value: undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleIncludeMNSA(x)},
        {name: 'includeRx', desc: "Include RX Benefit", options: {type: 'array', options: [true, false]}, value: undefined, isCurrent: false, isEnabled: false, onValueChange: x => handleIncludeRX(x)},
    ]


    const [noPreferenceFields, setNoPreference] = React.useState(fullArray)


    //Makes the ref point to noPreferenceFields so they can be used and edited in real time.
    React.useEffect(() => {
        ref.current = noPreferenceFields
    }, [noPreferenceFields])

    const handleSaveNoPreference = (addons) => {
        setShowAddons(false);
        setLoading(true)
        let benefit = {}
        if (noPreferenceFields[0].value === 'Inpatient Amount Only') {
            benefit = {
                "inpatient" : noPreferenceFields[1].value,
                "outpatient": 0
            }
        }
        else if (noPreferenceFields[0].value === 'Inpatient And Outpatient Amount'){
            benefit = {
                "inpatient" : noPreferenceFields[1].value,
                "outpatient": noPreferenceFields[2].value,
            }
        }
        else {
            benefit = {
                "combined": noPreferenceFields[1].value
            }
        }
        let data = {
            "censusData": gapCensus,
            "groupEffectiveDate": groupEffectiveDate,
            "sicCode": sicCode,
            "state": state,
            "benefit": benefit,
            "employerContribution": noPreferenceFields[_.findIndex(noPreferenceFields, x => x.name === 'employerContribution')].value * 100,
            "deductible": noPreferenceFields[_.findIndex(noPreferenceFields, x => x.name === 'deductible')].value,
            "includeRx": noPreferenceFields[_.findIndex(noPreferenceFields, x => x.name === 'includeRx')].value,
            "includePhysicianServices": noPreferenceFields[_.findIndex(noPreferenceFields, x => x.name === 'includePhysicianServices')].value,
            "includeMNSA": noPreferenceFields[_.findIndex(noPreferenceFields, x => x.name === 'includeMNSA')].value
        }

        let obj = _.cloneDeep(products);
        getGapPlans(data, restConfig.headers)
            .then(res => {
                if (res.data && !res.data.isError && res.data.responses && res.data.responses.length > 0) {
                    let arr = obj[props.name].saved ? obj[props.name].saved : [];
                    let responseCounter = 0
                    res.data.responses.forEach(response => {
                        if (response.hasResponse) {
                            responseCounter++
                            let newPlan = {};
                            newPlan.id = uuidv4();
                            newPlan.name = props.name;
                            newPlan.provider = response.carrier;
                            newPlan.plan = response.plan;
                            newPlan.request = response.request
                            newPlan.noPreference = true
                            newPlan.addons = addons
                            newPlan.rateData = {
                                cleaned:
                                    {fiftyPlus: response.response.fiftyPlus, underForty: response.response.underForty, fortyToFortyNine: response.response.fortyToFortyNine},
                                inputWithDescs: response.request,
                                raw: response.response,
                                continue: true,
                                warnings: response.response.warnings
                            };
                            newPlan.fields = props.mapFields(noPreferenceFields);
                            newPlan.benefits = {}

                            //Check to see if the product being saved has already been saved or not. If so, we show an error message and don't allow them to save the product.
                            let isDuplicate = false
                            arr.forEach(object => {
                                let obj1 = {
                                    name: object.name,
                                    provider: object.provider,
                                    rateData: object.rateData,
                                    brokerFee: object.brokerFee,
                                    fields: object.fields,
                                    addons: _.cloneDeep(object.addons)
                                }
                                let obj2 = {
                                    name: newPlan.name,
                                    provider: newPlan.provider,
                                    rateData: newPlan.rateData,
                                    brokerFee: newPlan.brokerFee,
                                    fields: newPlan.fields,
                                    addons: _.cloneDeep(newPlan.addons)
                                }
                                if (_.isEqual(obj1, obj2)) {
                                    isDuplicate = true
                                }
                            })

                            if (!isDuplicate && response.hasResponse) {
                                arr.unshift(newPlan);
                                obj[props.name].saved = arr;
                                obj[props.name].selected = 0;
                                obj[props.name].progress = 1;
                                props.setProgress(1);
                                setTimeout(() => scrollToBottom(), 500)
                            }
                        }
                    })
                    if (responseCounter > 0) {
                        return obj
                    }
                    else {
                        props.setNoPlansAvailable(true)
                        return false
                    }
                }
            })
            .then(obj => {
                if (obj) {
                    setProducts(obj)
                }
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                setError(true)
                setMessage("Something went wrong when trying to retrieve products")
                console.log(err)
                webError({
                    text: 'Received an error when trying to get products from the /starter endpoint for no carrier preference plans',
                    err
                })
            })
    }



    /*NOTE
       The following functions are handle functions that all deal with no preference fields for the no preference in carrier selection for gap.
       A few functions look very similar to one another so this could be refactored to be more efficient in the future. However, there is some
       complexity with what fields are needed and what options certain fields have based on selections the user has already made so there is no
       way to have a single function that can handle all change, however one function for every field is most likely unnecessary.
        */
    const handleBenefitChange = (x) => {
        let arr
        if (x === 'Inpatient Amount Only') {
            arr = inpatientOnlyArray
        }
        else if (x === 'Inpatient and Outpatient Amount') {
            arr = inpatientOutpatientArray
        }
        else {
            arr = combinedOnlyArray
        }
        arr[0].value = x
        arr[0].isCurrent = false
        arr[1].isEnabled = true
        arr[1].isCurrent = true
        setNoPreference(arr)
    }
    const handleInpatientChange = (x) => {
        let arr = _.cloneDeep(ref.current)
        arr[1].value = x
        let cap = x * .70
        let outpatientOptions = []
        for (let i=0;i<cap;i++) {
            if (i === 250 || i === 500 || i === 1000 || (i > 1000 && i % 50 === 0)) {
                outpatientOptions.push(i)
            }
        }
        arr[1].isCurrent = false
        arr[2].isEnabled = true
        arr[2].isCurrent = true
        if (arr[2].name === 'outpatientBenefit') {
            arr[2].options = {type: 'array', options: outpatientOptions}
        }
        else if (arr[2].name === 'deductible') {
            let options = arr[3].options.options
            arr[3].options = {type:'array', options:_.filter(options, option => option <= (x/2))}
        }
        if (arr[3].name === 'deductible') {
            let options = arr[3].options.options
            arr[3].options = {type:'array', options:_.filter(options, option => option <= (x/2))}
        }
        setNoPreference(arr)
    }
    const handleOutpatientChange = x => {
        let arr = _.cloneDeep(ref.current)
        arr[2].value = x
        arr[3].isEnabled = true
        arr[3].isCurrent = true
        arr[2].isCurrent = false
        setNoPreference(arr)
    }
    const handleCombinedChange = x => {
        let arr = _.cloneDeep(ref.current)
        let index = _.findIndex(arr, x => x.name === 'combinedBenefit')
        arr[index].value = x
        arr[index].isCurrent = false
        if (arr[index+1].name === 'deductible') {
            let options = arr[3].options.options
            arr[3].options = {type:'array', options:_.filter(options, option => option <= (x/2))}
        }
        arr[index+1].isEnabled = true
        arr[index+1].isCurrent = true
        setNoPreference(arr)
    }
    const handleEmployerChange = x => {
        let arr = _.cloneDeep(ref.current)
        let index = _.findIndex(arr, x => x.name === 'employerContribution')
        arr[index].value = x
        arr[index].isCurrent = false
        arr[index+1].isEnabled = true
        arr[index+1].isCurrent = true
        setNoPreference(arr)
    }
    const handleDeductibleChange = x => {
        let arr = _.cloneDeep(ref.current)
        let index = _.findIndex(arr, x => x.name === 'deductible')
        arr[index].value = x
        arr[index].isCurrent = false
        arr[index+1].isEnabled = true
        arr[index+1].isCurrent = true
        setNoPreference(arr)
    }
    const handleIncludeRX = x => {
        let arr = _.cloneDeep(ref.current)
        let index = _.findIndex(arr, x => x.name === 'includeRx')
        arr[index].value = x
        arr[index].isCurrent = false
        setNoPreference(arr)
        props.setNoPreferenceDisabled(false)
    }
    const handleIncludePhysician = x => {
        let arr = _.cloneDeep(ref.current)
        let index = _.findIndex(arr, x => x.name === 'includePhysicianServices')
        arr[index].value = x
        arr[index].isCurrent = false
        arr[index+1].isEnabled = true
        arr[index+1].isCurrent = true
        setNoPreference(arr)

    }
    const handleIncludeMNSA = x => {
        let arr = _.cloneDeep(ref.current)
        let index = _.findIndex(arr, x => x.name === 'includeMNSA')
        arr[index].value = x
        arr[index].isCurrent = false
        arr[index+1].isEnabled = true
        arr[index+1].isCurrent = true
        setNoPreference(arr)
    }


    return (
            <>

                {noPreferenceFields.map(field => {
                    return (
                        <Field key={JSON.stringify(field)} field={field} walking={walking}/>
                    )
                })}
                <Collapse in={!props.noPreferenceDisabled}>
                    <Button style={{backgroundColor: '#0C2659',color:'#FFFFFF'}} color={'primary'} fullWidth variant={'contained'} onClick={ () => setShowAddons(enableAddons && true) }>Get all available plans</Button>
                </Collapse>
                <AddonsModal open={showAddons} onSave={handleSaveNoPreference} />
            </>

    )
}
